import React from "react";
import { Button } from "@material-ui/core";
import FBIcon from "../assets/Facebook.svg";
import InstaIcon from "../assets/instagram.svg";
import TeleIcon from "../assets/Telegram.svg";
import YTIcon from "../assets/youtube.svg";
import TwitterIcon from "../assets/Twitter.svg";
import DiscIcon from "../assets/Discord.svg";
import LinkedinIcon from "../assets/Linkdin.svg";

function SocialGroup({message}) {
  return (
    <div className="button-group">
      <Button>
        <a
          href="https://www.facebook.com/VITrendz-110405110659537/"
          target="_blank" rel="noreferrer"
        >
          <img src={FBIcon} alt="" />
        </a>
      </Button>
      <Button>
        <a href="https://www.instagram.com/vitrendz/?hl=en" target="_blank" rel="noreferrer">
          <img src={InstaIcon} alt="" />
        </a>
      </Button>
      <Button>
        <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRXzLtndANurS0p9MC-9NCARPip0KGSBQOUiFsFgxmfTVNkAPZmI0iTs5LMPIBk0LMRXO_qI_IgwqKG/pubhtml"  target="_blank" rel="noreferrer">
          <img src={TeleIcon} alt="" />
        </a>
      </Button>
      <Button>
        <a href="https://www.youtube.com/channel/UCmAwtdi2YTCA_ifMlx0yIDA"  target="_blank" rel="noreferrer">
          <img src={YTIcon} alt="" />
        </a>
      </Button>
      <Button>
        <a href="https://twitter.com/TrendzVi?s=09"  target="_blank" rel="noreferrer">
          <img src={TwitterIcon} alt="" />
        </a>
      </Button>
      <Button>
        <a href="https://discord.gg/qXK5BrCy"  target="_blank" rel="noreferrer">
          <img src={DiscIcon} alt="" />
        </a>
      </Button>
      <Button>
        <a href="https://www.linkedin.com/company/vitrendz/"  target="_blank" rel="noreferrer">
          <img src={LinkedinIcon} alt="" />
        </a>
      </Button>
    </div>
  );
}

export default SocialGroup;
