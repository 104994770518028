import React from "react";
import styles from "./About.module.css";
import { Button } from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import Aman from "../../assets/aman.jpg";

function About() {
  return (
    <div className={styles.component}>
      <div>
        <h1 className={styles.neonText}>About App</h1>
        <p>
          Since, there was no course allocation pdf this time, we
          had to scrape the data using {" "}
          <a
            href="https://www.linkedin.com/in/vishruthdevan/"
            rel="noreferrer"
            target="_blank"
          >
            Vishruth Devan's
          </a>
          {" "}script and could fetch only data of <strong> CSE Core, CSE Blockchain, CSE Data
          Science, IT, EEE, Chem </strong>branches only. Other branches are requested to use the Customized Course Addition Feature. 
        </p>
        <p>
          VITrends FFCS Planner is the most unique FFCS planner that a
          VITian has ever seen with novelties like automatic timetable generation, customized course addition and rest of the generic FFCS
          functionalities. VITrendz FFCS Planner was designed by the motive of
          assisting freshers who wouldnt have more/any contact with senior year
          students to guide them to select the best faculty. The app also
          provides a "close to actual FFCS" functionality by not permitting them
          to add clashed courses or courses that have already been added.
        </p>

        {/* <a href="#" target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>
                    <Button
                        variant="contained"
                        startIcon={<PlayCircleFilledIcon />}
                        style={{marginTop:"15px"}}
                    >
                        View Tutorial
                    </Button>
                </a> */}

        {/* <Flippy
          flipOnHover={true}
          flipDirection="horizontal"
        //   style={{ width: "200px", height: "200px" }}
          className={styles.flipCard}
        >
          <FrontSide
            className={styles.cardFront}
          >
            <img src={Aman} alt="aman" />
          </FrontSide>
          <BackSide className={styles.cardBack}>ROCKS</BackSide>
        </Flippy> */}
      </div>
    </div>
  );
}

export default About;
