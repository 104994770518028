import React, { useState, useEffect, useRef } from "react";
import styles from "./cell.module.css";

import { useStateValue } from "../../context/StateProvider";

function Cell({ slot, currentSlot, selected }) {
  const [
    { monday, tuesday, wednesday, thursday, friday, temp, deletedRow },
    dispatch,
  ] = useStateValue();

  const ref = useRef(slot);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [newArray1, setNewArray1] = useState(monday);
  const [newArray2, setNewArray2] = useState(tuesday);
  const [newArray3, setNewArray3] = useState(wednesday);
  const [newArray4, setNewArray4] = useState(thursday);
  const [newArray5, setNewArray5] = useState(friday);

  // const mondayTemp = [{ slot: "A1/TA1", splitSlots: ["A1", "TA1"], selected: false }, { slot: "F1/L2", splitSlots: ["F1", "L2"], selected: false }, { slot: "D1/L3", splitSlots: ["D1", "L3"], selected: false }, { slot: "TB1/L4", splitSlots: ["TB1", "L4"], selected: false }, { slot: "TG1/L5", splitSlots: ["TG1", "L8"], selected: false }, { slot: "L6", splitSlots: ["L6"], selected: false }, { slot: "A2/L31", splitSlots: ["A2", "L31"], selected: false }, { slot: "F2/L32", splitSlots: ["F2", "L32"], selected: false }, { slot: "D2/L33", splitSlots: ["D2", "L33"], selected: false }, { slot: "TG2/L35", splitSlots: ["TG2", "L35"], selected: false }, { slot: "V3/L36", splitSlots: ["V3", "L36"], selected: false }]

  // const tuesdayTemp = [{ slot: "B1/L7", splitSlots: ["B1", "L7"], selected: false }, { slot: "G1/L8", splitSlots: ["G1", "L8"], selected: false }, { slot: "E1/L9", splitSlots: ["E1", "L9"], selected: false }, { slot: "TC1/L10", splitSlots: ["TC1", "L10"], selected: false }, { slot: "TAA1/L11", splitSlots: ["TAA1", "L11"], selected: false }, { slot: "L12", splitSlots: ["L12"], selected: false }, { slot: "B2/L37", splitSlots: ["B2", "L37"], selected: false }, { slot: "G2/L38", splitSlots: ["G2", "L38"], selected: false }, { slot: "E2/L39", splitSlots: ["E2", "L39"], selected: false }, { slot: "TC2/L40", splitSlots: ["TC2", "L40"], selected: false }, { slot: "TAA2/L41", splitSlots: ["TAA2", "L41"], selected: false }, { slot: "V4/L42", splitSlots: ["V4", "L42"], selected: false }]

  // const wednesdayTemp = [{ slot: "C1/L13", splitSlots: ["C1", "L13"], selected: false }, { slot: "A1/L14", splitSlots: ["A1", "L14"], selected: false }, { slot: "F1/L15", splitSlots: ["F1", "L15"], selected: false }, { slot: "V1/L16", splitSlots: ["V1", "L16"], selected: false }, { slot: "V2/L17", splitSlots: ["V2", "L17"], selected: false }, { slot: "L18", splitSlots: ["L18"], selected: false }, { slot: "C2/L43", splitSlots: ["C2", "L43"], selected: false }, { slot: "A2/L44", splitSlots: ["A2", "L44"], selected: false }, { slot: "F2/L45", splitSlots: ["F2", "L45"], selected: false }, { slot: "TD2/L46", splitSlots: ["TD2", "L46"], selected: false }, { slot: "TBB2/L47", splitSlots: ["TBB2", "L47"], selected: false }, { slot: "V5/L48", splitSlots: ["V5", "L48"], selected: false }]

  // const thursdayTemp = [{ slot: "D1/L19", splitSlots: ["D1", "L19"], selected: false }, { slot: "B1/L20", splitSlots: ["B1", "L20"], selected: false }, { slot: "G1/L21", splitSlots: ["G1", "L21"], selected: false }, { slot: "TE1/L22", splitSlots: ["TE1", "L22"], selected: false }, { slot: "TCC1/L23", splitSlots: ["TCC1", "L23"], selected: false }, { slot: "L24", splitSlots: ["L24"], selected: false }, { slot: "D2/L49", splitSlots: ["D2", "L49"], selected: false }, { slot: "B2/L50", splitSlots: ["B2", "L50"], selected: false }, { slot: "G2/L51", splitSlots: ["G2", "L51"], selected: false }, { slot: "TE2/L52", splitSlots: ["TE2", "L52"], selected: false }, { slot: "TCC2/L53", splitSlots: ["TCC2", "L53"], selected: false }, { slot: "V6/L54", splitSlots: ["V6", "L54"], selected: false }]

  // const fridayTemp = [{ slot: "E1/L25", splitSlots: ["E1", "L25"], selected: false }, { slot: "C1/L26", splitSlots: ["C1", "L26"], selected: false }, { slot: "TA1/L27", splitSlots: ["TA1", "L27"], selected: false }, { slot: "TF1/L28", splitSlots: ["TF1", "L28"], selected: false }, { slot: "TD1/L29", splitSlots: ["TD1", "L29"], selected: false }, { slot: "L30", splitSlots: ["L30"], selected: false }, { slot: "E2/L55", splitSlots: ["E2", "L55"], selected: false }, { slot: "C2/L56", splitSlots: ["C2", "L56"], selected: false }, { slot: "TA2/L57", splitSlots: ["TA2", "L57"], selected: false }, { slot: "TF2/L58", splitSlots: ["TF2", "L58"], selected: false }, { slot: "TDD2/L59", splitSlots: ["TDD2", "L59"], selected: false }, { slot: "V7/L60", splitSlots: ["V7", "L60"], selected: false }]

  // const resetGlobal = () => {
  //   dispatch({
  //     type: 'RESET_ALL',
  //     reset1: mondayTemp,
  //     reset2: tuesdayTemp,
  //     reset3: wednesdayTemp,
  //     reset4: thursdayTemp,
  //     reset5: fridayTemp,
  //   })
  // }

  useEffect(() => {
    setNewArray1(monday);
    setNewArray2(tuesday);
    setNewArray3(wednesday);
    setNewArray4(thursday);
    setNewArray5(friday);
  }, [monday, tuesday, wednesday, thursday, friday]);

  useEffect(() => {
    if (deletedRow !== null) {
      let splitValues = separateSlots(deletedRow.slot);
      updatedColors(splitValues, true);
    }
    // console.log(deletedRow)
  }, [deletedRow]);

  // let monRow = [{slot:"A1/TA1", selected:false},{slot:"F1/L2", selected:false},{slot:"D1/L3", selected:false},{slot:"TB1/L4", selected:false},{slot:"TG1/L5", selected:false},{slot:"L6", selected:false},{slot:"A2/L31", selected:false},{slot:"F2/L32", selected:false},{slot:"D2/L33", selected:false},{slot:"TG2/L35", selected:false},{slot:"V3/L36", selected:false}];
  useEffect(() => {
    // console.log(currentSlot);
    if (currentSlot?.length < selectedSlots?.length) {
      setSelectedSlots(temp);
    } else {
      currentSlot.map((course) =>
        setSelectedSlots([...selectedSlots, course.row.slot])
      );
    }
  }, [currentSlot]);

  useEffect(() => {
    isOccupied();
  }, [selectedSlots]);

  // const choose = () => {
  //   isChosen ? setIsChosen(false) : setIsChosen(true);
  //   if (!isChosen) {
  //     window.alert(`All ${slot} highlighted`)
  //   }

  // };

  //A2+TA2+TAA2+V2

  const separateSlots = (element) => {
    const arraySlots = element?.split("+");
    // console.log(arraySlots)
    return arraySlots;
  };

  // useEffect(()=>{
  //   let splitValues = separateSlots("A1+TA1+TAA1+V3")
  //   console.log(splitValues)
  // },[])

  const isOccupied = () => {
    if (selectedSlots.length) {
      selectedSlots?.map((element) => {
        let splitValues = separateSlots(element);
        updatedColors(splitValues, false);
      });
    }
  };

  const updatedColors = (splitValues, deleteThis) => {
    newArray1?.map((a) => {
      let i = 0;
      for (i = 0; i < splitValues?.length; i++) {
        if (a.splitSlots?.includes(splitValues[i])) {
          a.selected = true;
          updateGlobal1();
        }

        if (a.splitSlots?.includes(splitValues[i]) && deleteThis) {
          a.selected = false;
          updateGlobal1();
          break;
        }
      }
    });

    newArray2?.map((a) => {
      let i = 0;
      for (i = 0; i < splitValues?.length; i++) {
        if (a.splitSlots?.includes(splitValues[i])) {
          a.selected = true;
          updateGlobal2();
        }
        if (a.splitSlots?.includes(splitValues[i]) && deleteThis) {
          a.selected = false;
          updateGlobal2();
          break;
        }
      }
    });

    newArray3?.map((a) => {
      let i = 0;
      for (i = 0; i < splitValues?.length; i++) {
        if (a.splitSlots?.includes(splitValues[i])) {
          a.selected = true;
          updateGlobal3();
        }

        if (a.splitSlots?.includes(splitValues[i]) && deleteThis) {
          a.selected = false;
          updateGlobal3();
          break;
        }
      }
    });

    newArray4?.map((a) => {
      let i = 0;
      for (i = 0; i < splitValues?.length; i++) {
        if (a.splitSlots?.includes(splitValues[i])) {
          a.selected = true;
          updateGlobal4();
        }

        if (a.splitSlots?.includes(splitValues[i]) && deleteThis) {
          a.selected = false;
          updateGlobal4();
          break;
        }
      }
    });

    newArray5?.map((a) => {
      let i = 0;
      for (i = 0; i < splitValues?.length; i++) {
        if (a.splitSlots?.includes(splitValues[i])) {
          a.selected = true;
          updateGlobal5();
        }

        if (a.splitSlots?.includes(splitValues[i]) && deleteThis) {
          a.selected = false;
          updateGlobal5();
          break;
        }
      }
    });
  };

  const updateGlobal1 = () => {
    dispatch({
      type: "UPDATE_ADD1",
      newArray1: newArray1,
    });
  };

  const updateGlobal2 = () => {
    dispatch({
      type: "UPDATE_ADD2",
      newArray2: newArray2,
    });
  };

  const updateGlobal3 = () => {
    dispatch({
      type: "UPDATE_ADD3",
      newArray3: newArray3,
    });
  };

  const updateGlobal4 = () => {
    dispatch({
      type: "UPDATE_ADD4",
      newArray4: newArray4,
    });
  };

  const updateGlobal5 = () => {
    dispatch({
      type: "UPDATE_ADD5",
      newArray5: newArray5,
    });
  };

  // useEffect(() => {
  //   console.log("setIsChosen is ", isChosen);
  // }, [isChosen]);
  //     setIsChosen(false);
  //     console.log("setIsChosen is ",isChosen)
  // }
  return (
    <td
      ref={ref}
      // onClick={choose}
      className={selected ? styles.colorChange : styles.ttContent}
    >
      <p>{slot}</p>
    </td>
  );
}

export default Cell;
