// data of eee, blockchain, chem, cse core, cse bds

const data = [
  {
    crcode: "EEE1003",
    cname: "EEE1003 - Electrical Workshop",
    slot: "L11+L12",
    ename: "UMA SATHYAKAM P",
    venue: "TT35A",
    ctype: "LO",
  },
  {
    crcode: "EEE1004",
    cname: "EEE1004 - Engineering Electromagnetics",
    slot: "L9+L10",
    ename: "ANUSUYA BHATACHARYYA",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "EEE1004",
    cname: "EEE1004 - Engineering Electromagnetics",
    slot: "E2+TE2",
    ename: "ANUSUYA BHATACHARYYA",
    venue: "TT413",
    ctype: "ETH",
  },
  {
    crcode: "EEE1005",
    cname: "EEE1005 - Signals and Systems",
    slot: "B1+TB1",
    ename: "AMUTHA PRABHA N",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "EEE2001",
    cname: "EEE2001 - Network Theory",
    slot: "C1+TC1",
    ename: "PALANISAMY K",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "EEE2003",
    cname: "EEE2003 - Electromechanical Energy Conversion",
    slot: "L31+L32",
    ename: "MAGESHVARAN R",
    venue: "TT035",
    ctype: "ELA",
  },
  {
    crcode: "EEE2003",
    cname: "EEE2003 - Electromechanical Energy Conversion",
    slot: "L39+L40",
    ename: "MAGESHVARAN R",
    venue: "TT035",
    ctype: "ELA",
  },
  {
    crcode: "EEE2003",
    cname: "EEE2003 - Electromechanical Energy Conversion",
    slot: "C1+TC1",
    ename: "MAGESHVARAN R",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    ename: "JAGANATHA PANDIAN B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    ename: "JAGANATHA PANDIAN B",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE2005",
    cname: "EEE2005 - Digital Signal Processing",
    slot: "L23+L24",
    ename: "RAJINI G.K",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE2005",
    cname: "EEE2005 - Digital Signal Processing",
    slot: "L3+L4",
    ename: "RAJINI G.K",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE2005",
    cname: "EEE2005 - Digital Signal Processing",
    slot: "L43+L44",
    ename: "MAHALAKSHMI P",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE2005",
    cname: "EEE2005 - Digital Signal Processing",
    slot: "L57+L58",
    ename: "MAHALAKSHMI P",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE2005",
    cname: "EEE2005 - Digital Signal Processing",
    slot: "F1",
    ename: "MAHALAKSHMI P",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE2005",
    cname: "EEE2005 - Digital Signal Processing",
    slot: "F2",
    ename: "RAJINI G.K",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE3001",
    cname: "EEE3001 - Control Systems",
    slot: "L15+L16",
    ename: "VINODH KUMAR E",
    venue: "TT045",
    ctype: "ELA",
  },
  {
    crcode: "EEE3001",
    cname: "EEE3001 - Control Systems",
    slot: "L31+L32",
    ename: "DHANAMJAYULU C",
    venue: "TT045",
    ctype: "ELA",
  },
  {
    crcode: "EEE3001",
    cname: "EEE3001 - Control Systems",
    slot: "L45+L46",
    ename: "DHANAMJAYULU C",
    venue: "TT045",
    ctype: "ELA",
  },
  {
    crcode: "EEE3001",
    cname: "EEE3001 - Control Systems",
    slot: "L5+L6",
    ename: "VINODH KUMAR E",
    venue: "TT045",
    ctype: "ELA",
  },
  {
    crcode: "EEE3001",
    cname: "EEE3001 - Control Systems",
    slot: "G1+TG1",
    ename: "DHANAMJAYULU C",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE3001",
    cname: "EEE3001 - Control Systems",
    slot: "G2+TG2",
    ename: "VINODH KUMAR E",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE3002",
    cname: "EEE3002 - Analog and Digital Circuits",
    slot: "L31+L32",
    ename: "SUDHAKAR N",
    venue: "TT129",
    ctype: "ELA",
  },
  {
    crcode: "EEE3002",
    cname: "EEE3002 - Analog and Digital Circuits",
    slot: "L43+L44",
    ename: "SUDHAKAR N",
    venue: "TT129",
    ctype: "ELA",
  },
  {
    crcode: "EEE3002",
    cname: "EEE3002 - Analog and Digital Circuits",
    slot: "A1+TA1",
    ename: "SUDHAKAR N",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE3003",
    cname: "EEE3003 - Power System Engineering",
    slot: "L15+L16",
    ename: "MEIKANDASIVAM S",
    venue: "TT433",
    ctype: "ELA",
  },
  {
    crcode: "EEE3003",
    cname: "EEE3003 - Power System Engineering",
    slot: "L9+L10",
    ename: "MEIKANDASIVAM S",
    venue: "TT433",
    ctype: "ELA",
  },
  {
    crcode: "EEE3003",
    cname: "EEE3003 - Power System Engineering",
    slot: "C2+TC2",
    ename: "MEIKANDASIVAM S",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L21+L22",
    ename: "YEDDULA PEDDA OBULESU",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L33+L34",
    ename: "INDRAGANDHI V",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L37+L38",
    ename: "INDRAGANDHI V",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L45+L46",
    ename: "RAJA SINGH R",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L55+L56",
    ename: "RAJA SINGH R",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L9+L10",
    ename: "YEDDULA PEDDA OBULESU",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "B1+TB1",
    ename: "INDRAGANDHI V",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "B1+TB1",
    ename: "RAJA SINGH R",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "B2+TB2",
    ename: "YEDDULA PEDDA OBULESU",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE4001",
    cname: "EEE4001 - Microprocessor and Microcontroller",
    slot: "L11+L12",
    ename: "RAMA PRABHA D",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE4001",
    cname: "EEE4001 - Microprocessor and Microcontroller",
    slot: "L21+L22",
    ename: "RAMA PRABHA D",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE4001",
    cname: "EEE4001 - Microprocessor and Microcontroller",
    slot: "L31+L32",
    ename: "SELVAKUMAR K",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE4001",
    cname: "EEE4001 - Microprocessor and Microcontroller",
    slot: "L37+L38",
    ename: "SELVAKUMAR K",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE4001",
    cname: "EEE4001 - Microprocessor and Microcontroller",
    slot: "L39+L40",
    ename: "MARIMUTHU R",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE4001",
    cname: "EEE4001 - Microprocessor and Microcontroller",
    slot: "L55+L56",
    ename: "MARIMUTHU R",
    venue: "TT515",
    ctype: "ELA",
  },
  {
    crcode: "EEE4001",
    cname: "EEE4001 - Microprocessor and Microcontroller",
    slot: "A1",
    ename: "SELVAKUMAR K",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE4001",
    cname: "EEE4001 - Microprocessor and Microcontroller",
    slot: "A1",
    ename: "MARIMUTHU R",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE4001",
    cname: "EEE4001 - Microprocessor and Microcontroller",
    slot: "A2",
    ename: "RAMA PRABHA D",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L15+L16",
    ename: "VIJAYA PRIYA R",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L21+L22",
    ename: "SITHARTHAN R",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L3+L4",
    ename: "VIJAYA PRIYA R",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L31+L32",
    ename: "VIJAYA PRIYA R",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L33+L34",
    ename: "SITHARTHAN R",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L43+L44",
    ename: "KARTHIKEYAN A",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L45+L46",
    ename: "VIJAYA PRIYA R",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L49+L50",
    ename: "SITHARTHAN R",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L5+L6",
    ename: "SITHARTHAN R",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L55+L56",
    ename: "KARTHIKEYAN A",
    venue: "TT520",
    ctype: "ELA",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L15+L16",
    ename: "VIJAYA PRIYA R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L21+L22",
    ename: "SITHARTHAN R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L3+L4",
    ename: "VIJAYA PRIYA R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L31+L32",
    ename: "VIJAYA PRIYA R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L33+L34",
    ename: "SITHARTHAN R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L43+L44",
    ename: "KARTHIKEYAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L45+L46",
    ename: "VIJAYA PRIYA R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L49+L50",
    ename: "SITHARTHAN R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L5+L6",
    ename: "SITHARTHAN R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "L55+L56",
    ename: "KARTHIKEYAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "E1",
    ename: "KARTHIKEYAN A",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "E1",
    ename: "VIJAYA PRIYA R",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "E1",
    ename: "SITHARTHAN R",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "E2",
    ename: "VIJAYA PRIYA R",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "ECE3501",
    cname: "ECE3501 - IoT Fundamentals",
    slot: "E2",
    ename: "SITHARTHAN R",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE1007",
    cname: "EEE1007 - Neural Network and Fuzzy Control",
    slot: "F1",
    ename: "SATHISHKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1007",
    cname: "EEE1007 - Neural Network and Fuzzy Control",
    slot: "F2",
    ename: "MONICA SUBASHINI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1007",
    cname: "EEE1007 - Neural Network and Fuzzy Control",
    slot: "F1",
    ename: "SATHISHKUMAR K",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE1007",
    cname: "EEE1007 - Neural Network and Fuzzy Control",
    slot: "F2",
    ename: "MONICA SUBASHINI M",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE1008",
    cname: "EEE1008 - Bio-Medical Instrumentation",
    slot: "A2+TA2",
    ename: "VIVEKANANDAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1008",
    cname: "EEE1008 - Bio-Medical Instrumentation",
    slot: "A2+TA2",
    ename: "VIVEKANANDAN S",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE1018",
    cname: "EEE1018 - Nanotechnology Fundamentals and its Applications",
    slot: "F1+TF1",
    ename: "PARTHA SHARATHI MALLICK",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE2006",
    cname: "EEE2006 - Communication Engineering",
    slot: "L31+L32",
    ename: "BALAJI S",
    venue: "TT128",
    ctype: "ELA",
  },
  {
    crcode: "EEE2006",
    cname: "EEE2006 - Communication Engineering",
    slot: "L45+L46",
    ename: "BALAJI S",
    venue: "TT128",
    ctype: "ELA",
  },
  {
    crcode: "EEE2006",
    cname: "EEE2006 - Communication Engineering",
    slot: "F1+TF1",
    ename: "BALAJI S",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE3006",
    cname: "EEE3006 - Special Electrical Machines",
    slot: "B2+TB2",
    ename: "RAJA SINGH R",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "EEE4002",
    cname: "EEE4002 - Power System Protection and Switchgear",
    slot: "L37+L38",
    ename: "PRABHAKAR KARTHIKEYAN S",
    venue: "TT037",
    ctype: "ELA",
  },
  {
    crcode: "EEE4002",
    cname: "EEE4002 - Power System Protection and Switchgear",
    slot: "L45+L46",
    ename: "PRABHAKAR KARTHIKEYAN S",
    venue: "TT037",
    ctype: "ELA",
  },
  {
    crcode: "EEE4002",
    cname: "EEE4002 - Power System Protection and Switchgear",
    slot: "L49+L50",
    ename: "PRABHAKAR KARTHIKEYAN S",
    venue: "TT037",
    ctype: "ELA",
  },
  {
    crcode: "EEE4002",
    cname: "EEE4002 - Power System Protection and Switchgear",
    slot: "C1+TC1",
    ename: "PRABHAKAR KARTHIKEYAN S",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    ename: "GOKULAKRISHNAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    ename: "GOKULAKRISHNAN G",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE4009",
    cname: "EEE4009 - FACTS and HVDC",
    slot: "B2+TB2",
    ename: "RAVI K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4009",
    cname: "EEE4009 - FACTS and HVDC",
    slot: "B2+TB2",
    ename: "RAVI K",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE4012",
    cname: "EEE4012 - Renewable Energy Sources",
    slot: "F2+TF2",
    ename: "SANKARDOSS V",
    venue: "TT434",
    ctype: "TH",
  },
  {
    crcode: "EEE4013",
    cname: "EEE4013 - Smart Grid",
    slot: "B1+TB1",
    ename: "VIJAYA PRIYA P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4013",
    cname: "EEE4013 - Smart Grid",
    slot: "B1+TB1",
    ename: "VIJAYA PRIYA P",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE4016",
    cname: "EEE4016 - Electric Vehicles",
    slot: "B2",
    ename: "CHITRA A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4016",
    cname: "EEE4016 - Electric Vehicles",
    slot: "B2",
    ename: "CHITRA A",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE4018",
    cname: "EEE4018 - Advanced Control Theory",
    slot: "G2+TG2",
    ename: "GEETHANJALI P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4018",
    cname: "EEE4018 - Advanced Control Theory",
    slot: "G2+TG2",
    ename: "GEETHANJALI P",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE4027",
    cname: "EEE4027 - Robotics and Control",
    slot: "C1",
    ename: "RASHMI RANJAN DAS",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4027",
    cname: "EEE4027 - Robotics and Control",
    slot: "C1",
    ename: "RASHMI RANJAN DAS",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE4028",
    cname: "EEE4028 - VLSI Design",
    slot: "L21+L22",
    ename: "BALAMURUGAN S",
    venue: "TT128",
    ctype: "ELA",
  },
  {
    crcode: "EEE4028",
    cname: "EEE4028 - VLSI Design",
    slot: "L9+L10",
    ename: "BALAMURUGAN S",
    venue: "TT128",
    ctype: "ELA",
  },
  {
    crcode: "EEE4028",
    cname: "EEE4028 - VLSI Design",
    slot: "A2+TA2",
    ename: "BALAMURUGAN S",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "PRABHAKARAN D",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "CHERALATHAN K.K.",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "F2+TF2",
    ename: "PRABHAKARAN D",
    venue: "TT208",
    ctype: "ETH",
  },
  {
    crcode: "CSE1002",
    cname: "CSE1002 - Problem Solving and Object Oriented Programming",
    slot: "L35+L36+L39+L40+L59+L60",
    ename: "BALAJI S",
    venue: "TT520",
    ctype: "LO",
  },
  {
    crcode: "EEE1901",
    cname: "EEE1901 - Technical Answers for Real World Problems (TARP)",
    slot: "TF1",
    ename: "VINODH KUMAR E",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1901",
    cname: "EEE1901 - Technical Answers for Real World Problems (TARP)",
    slot: "TF1",
    ename: "VINODH KUMAR E",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L13+L14+L21+L22",
    ename: "AJANTA SIRCAR",
    venue: "SJT721",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L31+L32+L47+L48",
    ename: "RAJU R.L.N",
    venue: "TT116",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L7+L8+L25+L26",
    ename: "SOUMEN MUKHERJEE",
    venue: "TT306",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L9+L10+L23+L24",
    ename: "VARSHA K",
    venue: "SJT701",
    ctype: "LO",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "C2",
    ename: "GAURAV SUSHANT",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "F1",
    ename: "SHILPI GUPTA",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "B1",
    ename: "COUMARAN G",
    venue: "TT207",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "CALAIVANANE R",
    venue: "PRP414",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "ANTOINETTE DANIEL",
    venue: "SMV319",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "G1",
    ename: "MALATHY O",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "HUM1021",
    cname: "HUM1021 - Ethics and Values",
    slot: "F1",
    ename: "TONY P JOSE",
    venue: "SJT107",
    ctype: "TH",
  },
  {
    crcode: "JAP1001",
    cname: "JAP1001 - Japanese for Beginners",
    slot: "G1",
    ename: "KHANJAN",
    venue: "SJT606",
    ctype: "TH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L3+L4",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L31+L32",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L33+L34",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "TT621",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E2+TE2",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA1",
    ename: "BHASKAR SEN GUPTA",
    venue: "SJT222",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "ANUSHA P T",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "BALAKRISHNAN S",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "LEEMA ROSE VIANNIE",
    venue: "SJT305",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC1",
    ename: "TARUN",
    venue: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC2",
    ename: "SATHYA SWAROOP N.R",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "ABHISHEK KUMAR SINGH",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "DHRITIMAN GUPTA",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TF1",
    ename: "RAVI SHANKER BABU",
    venue: "SJT201",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "SUDIPTA SAHA",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "USHA RANI M",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT530A",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT524",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV101",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV102",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV320",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "A2+TA2",
    ename: "SURESH P.K",
    venue: "SMV217",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "B2+TB2",
    ename: "RAHUL VASHISHTH",
    venue: "MB225",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "E1+TE1",
    ename: "SURESH P.K",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1008",
    cname: "CBS1008 - Operations Research",
    slot: "L25+L26",
    ename: "SIVARAJ R",
    venue: "SMVG16",
    ctype: "ELA",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "SMV104",
    ctype: "ETH",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "CHE1009",
    cname: "CHE1009 - Biochemical Engineering",
    slot: "E1+TE1",
    ename: "ARUNA SINGH",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1011",
    cname: "CHE1011 - Optimization of Chemical Processes",
    slot: "F1+TF1",
    ename: "DHARMENDRA KUMAR BAL",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE1013",
    cname: "CHE1013 - Natural Gas Engineering",
    slot: "G1+TG1",
    ename: "ASLAM ABDULLAH M",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "CHE1018",
    cname: "CHE1018 - Membrane Separations Technology",
    slot: "G1+TG1",
    ename: "THOMAS THEODORE",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1019",
    cname: "CHE1019 - Polymer Technology",
    slot: "D1+TD1",
    ename: "AABID HUSSAIN SHAIK",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE2003",
    cname: "CHE2003 - Chemical Product Design",
    slot: "G2+TG2",
    ename: "SHANKAR RAMAN DHANUSHKODI",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "A2",
    ename: "KHANA BAIDYA",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B1",
    ename: "KHANA BAIDYA",
    venue: "TT208",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B2",
    ename: "KHANA BAIDYA",
    venue: "TT206",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "D1",
    ename: "KHANA BAIDYA",
    venue: "TT205",
    ctype: "TH",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "CTSG05",
    ctype: "ELA",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "F1+TF1",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "L53+L54",
    ename: "MALATHY J",
    venue: "CTSG04",
    ctype: "ELA",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "A1+TA1",
    ename: "MALATHY J",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "B2+TB2",
    ename: "GANAPATHY G.P",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C1+TC1",
    ename: "ARUNAVA RAY",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C2+TC2",
    ename: "VAANI N",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1013",
    cname: "CLE1013 - Environmental Impact Assessment",
    slot: "A2+TA2",
    ename: "SRIMURUGANANDAM B",
    venue: "CDMM404",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "C2+TC2",
    ename: "SASANKA BHUSHAN PULIPATI",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "D1+TD1",
    ename: "JAYAPRAKASH J",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "TB1",
    ename: "SUGANYA OM",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE2003",
    cname: "CLE2003 - Structural Analysis",
    slot: "C1+TC1+V2",
    ename: "SANTHI A.S",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "B1",
    ename: "DILLIP KUMAR BARIK",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2008",
    cname: "CLE2008 - Construction Planning and Management",
    slot: "C1+TC1",
    ename: "JOHN SUSHIL PACKIARAJ",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "CDMM105",
    ctype: "ETH",
  },
  {
    crcode: "CLE2022",
    cname: "CLE2022 - Economics and Business Finance for Civil Engineers",
    slot: "B2+TB2",
    ename: "PRASANNA VENKATESAN R",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "L59+L60",
    ename: "SURENDAR M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "B1",
    ename: "SURENDAR M",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE3001",
    cname: "CLE3001 - Quantity Surveying and Estimating",
    slot: "G2",
    ename: "SOFI A",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "L39+L40",
    ename: "HAREESH M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "E1+TE1+V5",
    ename: "HAREESH M",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "L29+L30",
    ename: "THIRUMALINI S",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "D2+TD2",
    ename: "THIRUMALINI S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "L47+L48",
    ename: "SANKARDOSS V",
    venue: "TT433",
    ctype: "ELA",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "E1",
    ename: "SANKARDOSS V",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE1014",
    cname: "EEE1014 - Fiber Optic Sensors",
    slot: "F2+TF2",
    ename: "SIVABALAN S",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L11+L12",
    ename: "KOWSALYA M",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L15+L16",
    ename: "RAVI K",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L21+L22",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L25+L26",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L31+L32",
    ename: "GOKULAKRISHNAN G",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L33+L34",
    ename: "VIJAYAKUMAR D",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L39+L40",
    ename: "SARAVANAKUMAR R",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L43+L44",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L55+L56",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L9+L10",
    ename: "CHITRA A",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE3999",
    cname: "EEE3999 - Technical Answers for Real World Problems (TARP)",
    slot: "TE2",
    ename: "RAJINI G.K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE3999",
    cname: "EEE3999 - Technical Answers for Real World Problems (TARP)",
    slot: "TE2",
    ename: "RAJINI G.K",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE4021",
    cname: "EEE4021 - Sensors and Signal Conditioning",
    slot: "L49+L50",
    ename: "VENKATA LAKSHMI NARAYANA K",
    venue: "TT514",
    ctype: "ELA",
  },
  {
    crcode: "EEE4021",
    cname: "EEE4021 - Sensors and Signal Conditioning",
    slot: "L55+L56",
    ename: "VENKATA LAKSHMI NARAYANA K",
    venue: "TT514",
    ctype: "ELA",
  },
  {
    crcode: "EEE4021",
    cname: "EEE4021 - Sensors and Signal Conditioning",
    slot: "B1+TB1",
    ename: "VENKATA LAKSHMI NARAYANA K",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE4031",
    cname: "EEE4031 - Electrical and Electronic Instrumentation",
    slot: "L31+L32",
    ename: "BAGYAVEERESWARAN V",
    venue: "TT514",
    ctype: "ELA",
  },
  {
    crcode: "EEE4031",
    cname: "EEE4031 - Electrical and Electronic Instrumentation",
    slot: "L45+L46",
    ename: "BAGYAVEERESWARAN V",
    venue: "TT514",
    ctype: "ELA",
  },
  {
    crcode: "EEE4031",
    cname: "EEE4031 - Electrical and Electronic Instrumentation",
    slot: "G1+TG1",
    ename: "BAGYAVEERESWARAN V",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE4032",
    cname: "EEE4032 - Process Automation and Control",
    slot: "L15+L16",
    ename: "MANIMOZHI M",
    venue: "TT521",
    ctype: "ELA",
  },
  {
    crcode: "EEE4032",
    cname: "EEE4032 - Process Automation and Control",
    slot: "L27+L28",
    ename: "MANIMOZHI M",
    venue: "TT521",
    ctype: "ELA",
  },
  {
    crcode: "EEE4032",
    cname: "EEE4032 - Process Automation and Control",
    slot: "B2+TB2",
    ename: "MANIMOZHI M",
    venue: "TT434",
    ctype: "ETH",
  },
  {
    crcode: "EEE4033",
    cname: "EEE4033 - Industrial Instrumentation",
    slot: "C2+TC2",
    ename: "BAGYAVEERESWARAN V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4033",
    cname: "EEE4033 - Industrial Instrumentation",
    slot: "C2+TC2",
    ename: "BAGYAVEERESWARAN V",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "HIN1003",
    cname: "HIN1003 - Prathamik Hindi",
    slot: "C1+TC1",
    ename: "JAYALAKSHMI K",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "L15+L16",
    ename: "JAYALAKSHMI K",
    venue: "TT107",
    ctype: "ELA",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "B1",
    ename: "JAYALAKSHMI K",
    venue: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "B2+TB2",
    ename: "RASHMI M",
    venue: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "F1+TF1",
    ename: "BALAMURUGAN J",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    ename: "BHUVANESWARI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    ename: "BHUVANESWARI M",
    venue: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    ename: "BANGALORE MORARJI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    ename: "BANGALORE MORARJI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    ename: "BANGALORE MORARJI",
    venue: "SMVG21",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    ename: "BANGALORE MORARJI",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    ename: "SUDHEER C.V.",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    ename: "SUDHEER C.V.",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TB2",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TG1",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1033",
    cname: "HUM1033 - Micro Economics",
    slot: "D1+TD1",
    ename: "NILAVATHY K",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1038",
    cname: "HUM1038 - International Economics",
    slot: "E2+TE2",
    ename: "ALLI P",
    venue: "TT423",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "B1+TB1",
    ename: "RASHMI M",
    venue: "SJT323",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "C2+TC2",
    ename: "PRABAKAR S",
    venue: "SMV101",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "D2+TD2",
    ename: "BALAMURUGAN J",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "F1+TF1",
    ename: "PRABAKAR S",
    venue: "MB226",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "G2+TG2",
    ename: "KUBENDRAN A",
    venue: "TT305",
    ctype: "TH",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    ename: "KUBENDRAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    ename: "KUBENDRAN A",
    venue: "TT305",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L55+L56",
    ename: "NAVIN KUMAR",
    venue: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L57+L58",
    ename: "BHUVANESWARI M",
    venue: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "E1",
    ename: "BHUVANESWARI M",
    venue: "TT413",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "F2",
    ename: "NAVIN KUMAR",
    venue: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITE4099",
    cname: "ITE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITL1002",
    cname: "ITL1002 - Italiano Di Base",
    slot: "G1",
    ename: "JACOPO MOSESSO",
    venue: "SJT604",
    ctype: "TH",
  },
  {
    crcode: "MEE1001",
    cname: "MEE1001 - Engineering Drawing",
    slot: "L31+L32+L37+L38",
    ename: "CHINMAYA SAHU",
    venue: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE1004",
    cname: "MEE1004 - Fluid Mechanics",
    slot: "L5+L6",
    ename: "ABINASH MOHANTA",
    venue: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1008",
    cname: "MEE1008 - MEMS",
    slot: "B2+TB2",
    ename: "RAGHURAMAN D R S",
    venue: "MB312",
    ctype: "TH",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    ename: "SRIDHAR V G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    ename: "SRIDHAR V G",
    venue: "MB312",
    ctype: "ETH",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L23+L24",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L5+L6",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "D2+TD2+V6",
    ename: "GAYATHRI V",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE1027",
    cname: "MEE1027 - Instrumentation and Control Engineering",
    slot: "L37+L38",
    ename: "SOVAN SUNDAR DASGUPTA",
    venue: "GDN137",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "L39+L40",
    ename: "VIJESH V JOSHI",
    venue: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "G1+TG1",
    ename: "VIJESH V JOSHI",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "L57+L58",
    ename: "GUNDABATTINI EDISON",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "GUNDABATTINI EDISON",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L3+L4",
    ename: "RAMMOHAN A",
    venue: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L9+L10",
    ename: "RAMMOHAN A",
    venue: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "E2+TE2",
    ename: "RAMMOHAN A",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    ename: "SENTHIL KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    ename: "SENTHIL KUMAR S",
    venue: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    ename: "ASOKAN M.A",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    ename: "GAURAV GUPTA",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L15+L16",
    ename: "ASOKAN M.A",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L27+L28",
    ename: "GAURAV GUPTA",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    ename: "SABOOR S",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    ename: "SENTHIL KUMAR M",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L37+L38",
    ename: "SABOOR S",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L51+L52",
    ename: "SENTHIL KUMAR M",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L19+L20",
    ename: "NAVEEN J",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L27+L28",
    ename: "MOHANKUMAR K V",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L3+L4",
    ename: "NAVEEN J",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L35+L36",
    ename: "SRIDHARAN K",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L39+L40",
    ename: "SRIDHARAN K",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L45+L46",
    ename: "MANIKANDAN M",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L49+L50",
    ename: "MANIKANDAN M",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L7+L8",
    ename: "MOHANKUMAR K V",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    ename: "SRIDHARAN K",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    ename: "MANIKANDAN M",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    ename: "NAVEEN J",
    venue: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    ename: "MOHANKUMAR K V",
    venue: "MB225",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L11+L12",
    ename: "TAPANO KUMAR HOTTA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L15+L16",
    ename: "GOVINDHA RASU N",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L3+L4",
    ename: "TAPANO KUMAR HOTTA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L35+L36",
    ename: "ARUNA KUMAR BEHURA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L43+L44",
    ename: "ARUNA KUMAR BEHURA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L45+L46",
    ename: "NISHANT TIWARI",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L53+L54",
    ename: "NISHANT TIWARI",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L9+L10",
    ename: "GOVINDHA RASU N",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    ename: "GOVINDHA RASU N",
    venue: "GDNG08A",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    ename: "TAPANO KUMAR HOTTA",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "ARUNA KUMAR BEHURA",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "NISHANT TIWARI",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L11+L12",
    ename: "JAMBESWAR SAHU",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L41+L42",
    ename: "VENKATESHWARLU B",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L53+L54",
    ename: "ANOJ GIRI",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    ename: "VENKATESHWARLU B",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    ename: "ANOJ GIRI",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G2",
    ename: "JAMBESWAR SAHU",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L11+L12+L21+L22",
    ename: "RAJKUMAR E",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L31+L32+L39+L40",
    ename: "RAJKUMAR E",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B1",
    ename: "RAJKUMAR E",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B2",
    ename: "RAJKUMAR E",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    ename: "GUNJI BALA MURALI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    ename: "GUNJI BALA MURALI",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2009",
    cname: "MEE2009 - Tribology",
    slot: "E1+TE1+V7",
    ename: "ARULVEL S",
    venue: "GDN129",
    ctype: "TH",
  },
  {
    crcode: "MEE2010",
    cname: "MEE2010 - Design of Composite Materials",
    slot: "D2+TD2+V6",
    ename: "SK ARIFUL RAHAMAN",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    ename: "RIJESH M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    ename: "RIJESH M",
    venue: "MB226",
    ctype: "ETH",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    ename: "CHINMAYA PRASAD MOHANTY",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    ename: "CHINMAYA PRASAD MOHANTY",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L15+L16",
    ename: "RAJAMURUGAN G",
    venue: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L3+L4",
    ename: "RAJAMURUGAN G",
    venue: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "F2+TF2",
    ename: "RAJAMURUGAN G",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    ename: "RAJA K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    ename: "RAJA K",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2020",
    cname: "MEE2020 - Metal Forming Theory and Practice",
    slot: "G2+TG2",
    ename: "DEVASRI FULORIA",
    venue: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE2022",
    cname: "MEE2022 - Power Plant Engineering",
    slot: "B1+TB1",
    ename: "ASOKAN M.A",
    venue: "MB211",
    ctype: "TH",
  },
  {
    crcode: "MEE2023",
    cname: "MEE2023 - Gas Dynamics and Jet Propulsion",
    slot: "E1+TE1+V7",
    ename: "HUMRUTHA G",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    ename: "YAGNA S MUKKAMALA",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    ename: "YAGNA S MUKKAMALA",
    venue: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2031",
    cname: "MEE2031 - Theory of Metal Cutting and Forming",
    slot: "F1+TF1",
    ename: "PANDIVELAN C",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2032",
    cname: "MEE2032 - Kinematics and Dynamics of Machinery",
    slot: "D2+TD2+V6",
    ename: "NIRANJANA BEHERA",
    venue: "MB225",
    ctype: "TH",
  },
  {
    crcode: "MEE2038",
    cname: "MEE2038 - Thermal and Heat Transfer",
    slot: "A1+TA1+V1",
    ename: "DEEPAKKUMAR R",
    venue: "MB224",
    ctype: "TH",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    ename: "BASKAR P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    ename: "BASKAR P",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2050",
    cname: "MEE2050 - Vehicle Dynamics",
    slot: "E1+TE1+V7",
    ename: "SAKTHIVEL P",
    venue: "MB309",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    ename: "NARENDIRANATH BABU T",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    ename: "AHANKARI SANDEEP SURESHRAO",
    venue: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "B2+TB2+V4",
    ename: "VINOTH JEBARAJ A",
    venue: "MB214",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "D2+TD2+V6",
    ename: "GOKUL KUMAR K",
    venue: "MB229",
    ctype: "TH",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "L33+L34",
    ename: "PADMANABHAN K",
    venue: "MB110",
    ctype: "ELA",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "E1+TE1+V7",
    ename: "PADMANABHAN K",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3004",
    cname: "MEE3004 - Internal Combustion Engines",
    slot: "B1+TB1",
    ename: "NANTHA GOPAL K",
    venue: "GDNG08",
    ctype: "TH",
  },
  {
    crcode: "MEE3005",
    cname: "MEE3005 - Refrigeration and Airconditioning",
    slot: "D2+TD2+TDD2+V6",
    ename: "RAMESH KUMAR C",
    venue: "MB226",
    ctype: "TH",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L19+L20",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L9+L10",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "B2",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "L41+L42",
    ename: "DEEPA A",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "D1",
    ename: "DEEPA A",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L39+L40",
    ename: "NATARAJ G",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L41+L42",
    ename: "NATARAJ G",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "G1+TG1",
    ename: "NATARAJ G",
    venue: "MB211",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    ename: "RENOLD ELSEN S",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    ename: "SRINIVASAN NARAYANAN",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    ename: "VENKATESAN K",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    ename: "OYYARAVELU R",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    ename: "DEVENDIRAN S",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    ename: "CHIRANJEEVI C",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    ename: "RAJA SEKHAR Y",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    ename: "BIKASH ROUTH",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    ename: "RENOLD ELSEN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    ename: "SRINIVASAN NARAYANAN",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    ename: "VENKATESAN K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    ename: "OYYARAVELU R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    ename: "DEVENDIRAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    ename: "CHIRANJEEVI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    ename: "RAJA SEKHAR Y",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    ename: "BIKASH ROUTH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "OYYARAVELU R",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "DEVENDIRAN S",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "CHIRANJEEVI C",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "RAJA SEKHAR Y",
    venue: "MB213",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "VENKATESAN K",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "BIKASH ROUTH",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "RENOLD ELSEN S",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "SRINIVASAN NARAYANAN",
    venue: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    ename: "ANANDAVEL K",
    venue: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    ename: "ANANDAVEL K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "G2",
    ename: "ANANDAVEL K",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L19+L20",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L3+L4",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "D2+TD2+V6",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB227",
    ctype: "ETH",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "SET5003",
    cname: "SET5003 - Science, Engineering and Technology Project - III",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ENG1000",
    cname: "ENG1000 - Foundation English - I",
    slot: "L11+L12+L27+L28",
    ename: "SRINIVASAN R",
    venue: "TT432",
    ctype: "LO",
  },
  {
    crcode: "ENG2000",
    cname: "ENG2000 - Foundation English - II",
    slot: "L33+L34+L47+L48",
    ename: "SANKAR G",
    venue: "SJT721",
    ctype: "LO",
  },

  {
    crcode: "BKT1001",
    cname: "BKT1001 - Blockchain and Distributed Ledger Technology",
    slot: "L33+L34",
    ename: "CHANDRU VIGNESH S",
    venue: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "BKT1001",
    cname: "BKT1001 - Blockchain and Distributed Ledger Technology",
    slot: "A1+TA1",
    ename: "CHANDRU VIGNESH S",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "BKT2001",
    cname: "BKT2001 - Cryptography  and Information Security",
    slot: "L9+L10",
    ename: "RISHIN HALDAR",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "BKT2001",
    cname: "BKT2001 - Cryptography  and Information Security",
    slot: "B2+TB2",
    ename: "RISHIN HALDAR",
    venue: "SJT323",
    ctype: "ETH",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "L11+L12",
    ename: "NIHA K",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "L39+L40",
    ename: "SWARNALATHA P",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "A1+TA1",
    ename: "SWARNALATHA P",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "A2+TA2",
    ename: "NIHA K",
    venue: "SJT501",
    ctype: "ETH",
  },
  {
    crcode: "CSE1007",
    cname: "CSE1007 - Java Programming",
    slot: "C1+TC1",
    ename: "PRIYA G",
    venue: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "L19+L20",
    ename: "SASIKALA .R",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "L49+L50",
    ename: "SHARMILA BANU K",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "E1",
    ename: "SHARMILA BANU K",
    venue: "SJT422",
    ctype: "ETH",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "E2",
    ename: "SASIKALA .R",
    venue: "SJT422",
    ctype: "ETH",
  },
  {
    crcode: "CSE2013",
    cname: "CSE2013 - Theory of Computation",
    slot: "F1+TF1",
    ename: "KUMAR K",
    venue: "PRPG21",
    ctype: "TH",
  },
  {
    crcode: "CSE2013",
    cname: "CSE2013 - Theory of Computation",
    slot: "F2+TF2",
    ename: "SATHIYA KUMAR C",
    venue: "SJT626",
    ctype: "TH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L1+L2",
    ename: "KAUSER AHMED P",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "MEENAKSHI S P",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "JAYAKUMAR S",
    venue: "SJT419",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "LYDIA JANE G",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    ename: "RAJKUMAR R",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    ename: "JAYAKUMAR K",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    ename: "MARY MEKALA A",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    ename: "SRIDHAR RAJ S",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    ename: "RAJKUMAR R",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    ename: "JAYAKUMAR K",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L33+L34",
    ename: "MEENAKSHI S P",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    ename: "JAYAKUMAR S",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    ename: "LYDIA JANE G",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L37+L38",
    ename: "MARY MEKALA A",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L41+L42",
    ename: "SRIDHAR RAJ S",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L51+L52",
    ename: "ARCHANA T",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L57+L58",
    ename: "KAUSER AHMED P",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L7+L8",
    ename: "ARCHANA T",
    venue: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L1+L2",
    ename: "KAUSER AHMED P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "MEENAKSHI S P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "JAYAKUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "LYDIA JANE G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    ename: "RAJKUMAR R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    ename: "JAYAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    ename: "MARY MEKALA A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    ename: "SRIDHAR RAJ S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    ename: "RAJKUMAR R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    ename: "JAYAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L33+L34",
    ename: "MEENAKSHI S P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    ename: "JAYAKUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    ename: "LYDIA JANE G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L37+L38",
    ename: "MARY MEKALA A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L41+L42",
    ename: "SRIDHAR RAJ S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L51+L52",
    ename: "ARCHANA T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L57+L58",
    ename: "KAUSER AHMED P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L7+L8",
    ename: "ARCHANA T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "B1",
    ename: "JAYAKUMAR S",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "B2",
    ename: "JAYAKUMAR S",
    venue: "SJT505",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "MEENAKSHI S P",
    venue: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "RAJKUMAR R",
    venue: "SJT508",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "MARY MEKALA A",
    venue: "SJT311A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "KAUSER AHMED P",
    venue: "SJT302",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "ARCHANA T",
    venue: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "LYDIA JANE G",
    venue: "SJT408",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "JAYAKUMAR K",
    venue: "SJT823",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "SRIDHAR RAJ S",
    venue: "SJT222",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "MEENAKSHI S P",
    venue: "SJT302",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "RAJKUMAR R",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "MARY MEKALA A",
    venue: "SJT305",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "KAUSER AHMED P",
    venue: "SJT604",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "ARCHANA T",
    venue: "SJT422",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "LYDIA JANE G",
    venue: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "JAYAKUMAR K",
    venue: "SJT712",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "SRIDHAR RAJ S",
    venue: "SJT802",
    ctype: "ETH",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "L47+L48",
    ename: "SANKARDOSS V",
    venue: "TT433",
    ctype: "ELA",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "E1",
    ename: "SANKARDOSS V",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B1+TB1",
    ename: "ANBARASI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B2+TB2",
    ename: "JASMIN T. JOSE",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B1+TB1",
    ename: "ANBARASI M",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B2+TB2",
    ename: "JASMIN T. JOSE",
    venue: "SJT301",
    ctype: "ETH",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L27+L28",
    ename: "BHULAKSHMI BONTHU",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L37+L38",
    ename: "BHULAKSHMI BONTHU",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L27+L28",
    ename: "BHULAKSHMI BONTHU",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L37+L38",
    ename: "BHULAKSHMI BONTHU",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "A1",
    ename: "BHULAKSHMI BONTHU",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "A2",
    ename: "BHULAKSHMI BONTHU",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L1+L2",
    ename: "ANUSHA N",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L11+L12",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L13+L14",
    ename: "NARESH K",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L15+L16",
    ename: "ANURADHA G",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L21+L22",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L25+L26",
    ename: "ANURADHA G",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L27+L28",
    ename: "RAJAKUMAR K",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L31+L32",
    ename: "RAJAKUMAR K",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L37+L38",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L39+L40",
    ename: "AJITHA D",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L41+L42",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L43+L44",
    ename: "AJITHA D",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L45+L46",
    ename: "SHOBHA REKH",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L51+L52",
    ename: "SHOBHA REKH",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L7+L8",
    ename: "ANUSHA N",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L1+L2",
    ename: "ANUSHA N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L11+L12",
    ename: "KRISHNAMOORTHY A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L13+L14",
    ename: "NARESH K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L15+L16",
    ename: "ANURADHA G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L21+L22",
    ename: "KRISHNAMOORTHY A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L25+L26",
    ename: "ANURADHA G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L27+L28",
    ename: "RAJAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L31+L32",
    ename: "RAJAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L37+L38",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L39+L40",
    ename: "AJITHA D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L41+L42",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L43+L44",
    ename: "AJITHA D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L45+L46",
    ename: "SHOBHA REKH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L51+L52",
    ename: "SHOBHA REKH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L7+L8",
    ename: "ANUSHA N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "NARESH K",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "ANURADHA G",
    venue: "SJT505",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "RAJAKUMAR K",
    venue: "SJT522",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT617",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "AJITHA D",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "SHOBHA REKH",
    venue: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "ANUSHA N",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "ANURADHA G",
    venue: "SJT505",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "RAJAKUMAR K",
    venue: "SJT604",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "AJITHA D",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "SHOBHA REKH",
    venue: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "ANUSHA N",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L13+L14",
    ename: "ANTO S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L15+L16",
    ename: "SWATHI J.N",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L25+L26",
    ename: "SHASHANK MOULI SATAPATHY",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L29+L30",
    ename: "PREETHA EVANGELINE D",
    venue: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L3+L4",
    ename: "SIVAPRAKASH S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L31+L32",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L33+L34",
    ename: "NAVEENKUMAR J",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L37+L38",
    ename: "ANAND BIHARI",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L39+L40",
    ename: "PREETHA EVANGELINE D",
    venue: "SJT122",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L41+L42",
    ename: "SIVAPRAKASH S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L43+L44",
    ename: "AKILA VICTOR",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L45+L46",
    ename: "MARGRET ANOUNCIA S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L5+L6",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L55+L56",
    ename: "ANTO S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L9+L10",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L13+L14",
    ename: "ANTO S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L15+L16",
    ename: "SWATHI J.N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L25+L26",
    ename: "SHASHANK MOULI SATAPATHY",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L29+L30",
    ename: "PREETHA EVANGELINE D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L3+L4",
    ename: "SIVAPRAKASH S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L31+L32",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L33+L34",
    ename: "NAVEENKUMAR J",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L37+L38",
    ename: "ANAND BIHARI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L39+L40",
    ename: "PREETHA EVANGELINE D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L41+L42",
    ename: "SIVAPRAKASH S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L43+L44",
    ename: "AKILA VICTOR",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L45+L46",
    ename: "MARGRET ANOUNCIA S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L5+L6",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L55+L56",
    ename: "ANTO S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L9+L10",
    ename: "PEREPI RAJARAJESWARI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "MARGRET ANOUNCIA S",
    venue: "SJT303",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "SJT308",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "AKILA VICTOR",
    venue: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "ANTO S",
    venue: "SJT305",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "ANAND BIHARI",
    venue: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "NAVEENKUMAR J",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "SIVAPRAKASH S",
    venue: "SJT402",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "SWATHI J.N",
    venue: "SJT305",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "SHASHANK MOULI SATAPATHY",
    venue: "SJT308",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "ANTO S",
    venue: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "SIVAPRAKASH S",
    venue: "SJT402",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "D1",
    ename: "PREETHA EVANGELINE D",
    venue: "SJT702",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "D2",
    ename: "PREETHA EVANGELINE D",
    venue: "SJT703",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    ename: "KRISHNAMOORTHY A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    ename: "DHEEBA J",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A2+TA2",
    ename: "DHEEBA J",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "E2+TE2",
    ename: "SENTHILNATHAN",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    ename: "DHEEBA J",
    venue: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A2+TA2",
    ename: "DHEEBA J",
    venue: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "E2+TE2",
    ename: "SENTHILNATHAN",
    venue: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "MOHANA CM",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "SANTHI K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "SRIDHAR RAJ S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "ANUSHA N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "TUSAR KANTI MISHRA",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "DILIPKUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "MOHANA CM",
    venue: "SJT715",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "SANTHI K",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "SRIDHAR RAJ S",
    venue: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "ANUSHA N",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "TUSAR KANTI MISHRA",
    venue: "SJT617",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "DILIPKUMAR S",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L15+L16",
    ename: "SIVAPRAKASH S",
    venue: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L19+L20",
    ename: "DEEPSUBHRA GUHA ROY",
    venue: "SJTG17",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L29+L30",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L33+L34",
    ename: "DEEPSUBHRA GUHA ROY",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L39+L40",
    ename: "NAVEENKUMAR J",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L45+L46",
    ename: "CHELLATAMILAN T",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L51+L52",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A1+TA1",
    ename: "CHELLATAMILAN T",
    venue: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A1+TA1",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT407",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A1+TA1",
    ename: "DEEPSUBHRA GUHA ROY",
    venue: "SJT501",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A2+TA2",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A2+TA2",
    ename: "DEEPSUBHRA GUHA ROY",
    venue: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "D2+TD2",
    ename: "SIVAPRAKASH S",
    venue: "SJT501",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "G1+TG1",
    ename: "NAVEENKUMAR J",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L11+L12",
    ename: "RAJA S P",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L13+L14",
    ename: "CHANDRA MOHAN B",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L19+L20",
    ename: "ANAND M",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    ename: "ANIL KUMAR K",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    ename: "RUBY D",
    venue: "SJT419",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L23+L24",
    ename: "SIVA SHANMUGAM G",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    ename: "RAMANI S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    ename: "SELVI M",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    ename: "AMUTHA PRABAKAR M",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    ename: "SENDHIL KUMAR K.S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L31+L32",
    ename: "MURALI S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    ename: "AMUTHA PRABAKAR M",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    ename: "SARITHA MURALI",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L39+L40",
    ename: "SENDHIL KUMAR K.S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L41+L42",
    ename: "RUBY D",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L43+L44",
    ename: "SWETHA N G",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    ename: "AJU D",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    ename: "SELVI M",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    ename: "LAVANYA K",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    ename: "RAJARAJAN G",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L5+L6",
    ename: "KATHIRAVAN S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "ANIL KUMAR K",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "KATHIRAVAN S",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L53+L54",
    ename: "RAMANI S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    ename: "KUMARESAN A",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    ename: "CHANDRA MOHAN B",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L57+L58",
    ename: "SIVA SHANMUGAM G",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    ename: "SWETHA N G",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    ename: "RAJARAJAN G",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "KUMARESAN A",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "SIVAKUMAR. N",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L11+L12",
    ename: "RAJA S P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L13+L14",
    ename: "CHANDRA MOHAN B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L19+L20",
    ename: "ANAND M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    ename: "ANIL KUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    ename: "RUBY D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L23+L24",
    ename: "SIVA SHANMUGAM G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    ename: "RAMANI S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    ename: "SELVI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    ename: "AMUTHA PRABAKAR M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    ename: "SENDHIL KUMAR K.S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L31+L32",
    ename: "MURALI S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    ename: "AMUTHA PRABAKAR M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    ename: "SARITHA MURALI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L39+L40",
    ename: "SENDHIL KUMAR K.S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L41+L42",
    ename: "RUBY D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L43+L44",
    ename: "SWETHA N G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    ename: "AJU D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    ename: "SELVI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    ename: "LAVANYA K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    ename: "RAJARAJAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L5+L6",
    ename: "KATHIRAVAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "ANIL KUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "KATHIRAVAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L53+L54",
    ename: "RAMANI S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    ename: "KUMARESAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    ename: "CHANDRA MOHAN B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L57+L58",
    ename: "SIVA SHANMUGAM G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    ename: "SWETHA N G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    ename: "RAJARAJAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "KUMARESAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "SIVAKUMAR. N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "ANIL KUMAR K",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "AMUTHA PRABAKAR M",
    venue: "SJT324",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "SWETHA N G",
    venue: "SJT311A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "AJU D",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "MURALI S",
    venue: "SJT201",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "SENDHIL KUMAR K.S",
    venue: "SJT602A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "RAMANI S",
    venue: "SJT504",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "SIVA SHANMUGAM G",
    venue: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "LAVANYA K",
    venue: "SJT707",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "CHANDRA MOHAN B",
    venue: "SJT711",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "KATHIRAVAN S",
    venue: "SJT710",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "RAJARAJAN G",
    venue: "SJT712",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "SELVI M",
    venue: "SJT704",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "RUBY D",
    venue: "SJT715",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "KUMARESAN A",
    venue: "SJT507",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SIVA SHANMUGAM G",
    venue: "SJT302",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SWETHA N G",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "KUMARESAN A",
    venue: "SJT311A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "ANAND M",
    venue: "SJT201",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SIVAKUMAR. N",
    venue: "SJT208",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SENDHIL KUMAR K.S",
    venue: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "RAMANI S",
    venue: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "CHANDRA MOHAN B",
    venue: "SJT223",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "ANIL KUMAR K",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "KATHIRAVAN S",
    venue: "SJT522",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "RAJARAJAN G",
    venue: "SJT222",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "AMUTHA PRABAKAR M",
    venue: "SJT607",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SELVI M",
    venue: "SJT602A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "RUBY D",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "RAJA S P",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "G1",
    ename: "SARITHA MURALI",
    venue: "SJT405",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "GAYATHRI P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "ANURADHA D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "ILAYARAJA V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    ename: "DEEPIKAA S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    ename: "PARTHIBAN K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "GAYATHRI P",
    venue: "SJT308",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "ANURADHA D",
    venue: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "ILAYARAJA V",
    venue: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    ename: "DEEPIKAA S",
    venue: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    ename: "PARTHIBAN K",
    venue: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "LYDIA JANE G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "VETRISELVI T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "RAJESHKANNAN R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    ename: "DURGESH KUMAR",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    ename: "VETRISELVI T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "LYDIA JANE G",
    venue: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "VETRISELVI T",
    venue: "SJT504",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "RAJESHKANNAN R",
    venue: "SJT522",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    ename: "DURGESH KUMAR",
    venue: "SJT423",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    ename: "VETRISELVI T",
    venue: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "PRABHAKARAN D",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "CHERALATHAN K.K.",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "F2+TF2",
    ename: "PRABHAKARAN D",
    venue: "TT208",
    ctype: "ETH",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L13+L14+L21+L22",
    ename: "AJANTA SIRCAR",
    venue: "SJT721",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L31+L32+L47+L48",
    ename: "RAJU R.L.N",
    venue: "TT116",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L7+L8+L25+L26",
    ename: "SOUMEN MUKHERJEE",
    venue: "TT306",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L9+L10+L23+L24",
    ename: "VARSHA K",
    venue: "SJT701",
    ctype: "LO",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "C2",
    ename: "GAURAV SUSHANT",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "F1",
    ename: "SHILPI GUPTA",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "B1",
    ename: "COUMARAN G",
    venue: "TT207",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "CALAIVANANE R",
    venue: "PRP414",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "ANTOINETTE DANIEL",
    venue: "SMV319",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "G1",
    ename: "MALATHY O",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "HUM1021",
    cname: "HUM1021 - Ethics and Values",
    slot: "F1",
    ename: "TONY P JOSE",
    venue: "SJT107",
    ctype: "TH",
  },
  {
    crcode: "JAP1001",
    cname: "JAP1001 - Japanese for Beginners",
    slot: "G1",
    ename: "KHANJAN",
    venue: "SJT606",
    ctype: "TH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L3+L4",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L31+L32",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L33+L34",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "TT621",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E2+TE2",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA1",
    ename: "BHASKAR SEN GUPTA",
    venue: "SJT222",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA1",
    ename: "SANDEEP CHAKRABORTY",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA2",
    ename: "ARPAN KUMAR NAYAK",
    venue: "SJT205",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "ANUSHA P T",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "BALAKRISHNAN S",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "LEEMA ROSE VIANNIE",
    venue: "SJT305",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB2",
    ename: "LAXMI NARAYAN TRIPATHI",
    venue: "SJT202",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC1",
    ename: "TARUN",
    venue: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC1",
    ename: "SURESH KUMAR VANDRANGI",
    venue: "SJT404",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC2",
    ename: "UMA MAHENDRA KUMAR K",
    venue: "SJT205",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC2",
    ename: "SATHYA SWAROOP N.R",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD1",
    ename: "KURAGANTI VASU",
    venue: "SJT503",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD1",
    ename: "DHANOJ GUPTA",
    venue: "SJT504",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD1",
    ename: "JAGADEESH SURE",
    venue: "SJT505",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD2",
    ename: "SAMIR RANJAN MEHER",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "ABHISHEK KUMAR SINGH",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "DHRITIMAN GUPTA",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TF1",
    ename: "RAVI SHANKER BABU",
    venue: "SJT201",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "SHOBANA M K",
    venue: "SJT205",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "SUDIPTA SAHA",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "USHA RANI M",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT530A",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT524",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV101",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV102",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV320",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    ename: "ANANDA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    ename: "KALAAVATHI B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A2+TA2",
    ename: "ILAYARAJA V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    ename: "ANANDA KUMAR S",
    venue: "SJT409",
    ctype: "ETH",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    ename: "KALAAVATHI B",
    venue: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A2+TA2",
    ename: "ILAYARAJA V",
    venue: "SJT409",
    ctype: "ETH",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C1+TC1",
    ename: "BAIJU B V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C2+TC2",
    ename: "BAIJU B V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C1+TC1",
    ename: "BAIJU B V",
    venue: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C2+TC2",
    ename: "BAIJU B V",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "BCT4003",
    cname: "BCT4003 - Wearable Computing",
    slot: "E1+TE1",
    ename: "JAFAR ALI IBRAHIM S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT4003",
    cname: "BCT4003 - Wearable Computing",
    slot: "E1+TE1",
    ename: "JAFAR ALI IBRAHIM S",
    venue: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "A2+TA2",
    ename: "SURESH P.K",
    venue: "SMV217",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "B2+TB2",
    ename: "RAHUL VASHISHTH",
    venue: "MB225",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "E1+TE1",
    ename: "SURESH P.K",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1008",
    cname: "CBS1008 - Operations Research",
    slot: "L25+L26",
    ename: "SIVARAJ R",
    venue: "SMVG16",
    ctype: "ELA",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "SMV104",
    ctype: "ETH",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "CHE1009",
    cname: "CHE1009 - Biochemical Engineering",
    slot: "E1+TE1",
    ename: "ARUNA SINGH",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1011",
    cname: "CHE1011 - Optimization of Chemical Processes",
    slot: "F1+TF1",
    ename: "DHARMENDRA KUMAR BAL",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE1013",
    cname: "CHE1013 - Natural Gas Engineering",
    slot: "G1+TG1",
    ename: "ASLAM ABDULLAH M",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "CHE1018",
    cname: "CHE1018 - Membrane Separations Technology",
    slot: "G1+TG1",
    ename: "THOMAS THEODORE",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1019",
    cname: "CHE1019 - Polymer Technology",
    slot: "D1+TD1",
    ename: "AABID HUSSAIN SHAIK",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE2003",
    cname: "CHE2003 - Chemical Product Design",
    slot: "G2+TG2",
    ename: "SHANKAR RAMAN DHANUSHKODI",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "A2",
    ename: "KHANA BAIDYA",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B1",
    ename: "KHANA BAIDYA",
    venue: "TT208",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B2",
    ename: "KHANA BAIDYA",
    venue: "TT206",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "D1",
    ename: "KHANA BAIDYA",
    venue: "TT205",
    ctype: "TH",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "CTSG05",
    ctype: "ELA",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "F1+TF1",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "L53+L54",
    ename: "MALATHY  J",
    venue: "CTSG04",
    ctype: "ELA",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "A1+TA1",
    ename: "MALATHY  J",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "B2+TB2",
    ename: "GANAPATHY G.P",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C1+TC1",
    ename: "ARUNAVA RAY",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C2+TC2",
    ename: "VAANI N",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1013",
    cname: "CLE1013 - Environmental Impact Assessment",
    slot: "A2+TA2",
    ename: "SRIMURUGANANDAM B",
    venue: "CDMM404",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "C2+TC2",
    ename: "SASANKA BHUSHAN PULIPATI",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "D1+TD1",
    ename: "JAYAPRAKASH J",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "TB1",
    ename: "SUGANYA OM",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE2003",
    cname: "CLE2003 - Structural Analysis",
    slot: "C1+TC1+V2",
    ename: "SANTHI A.S",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "B1",
    ename: "DILLIP KUMAR BARIK",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2008",
    cname: "CLE2008 - Construction Planning and Management",
    slot: "C1+TC1",
    ename: "JOHN SUSHIL PACKIARAJ",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "CDMM105",
    ctype: "ETH",
  },
  {
    crcode: "CLE2022",
    cname: "CLE2022 - Economics and Business Finance for Civil Engineers",
    slot: "B2+TB2",
    ename: "PRASANNA VENKATESAN R",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "L59+L60",
    ename: "SURENDAR M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "B1",
    ename: "SURENDAR M",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE3001",
    cname: "CLE3001 - Quantity Surveying and Estimating",
    slot: "G2",
    ename: "SOFI A",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "L39+L40",
    ename: "HAREESH M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "E1+TE1+V5",
    ename: "HAREESH M",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "L29+L30",
    ename: "THIRUMALINI S",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "D2+TD2",
    ename: "THIRUMALINI S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A1+TA1",
    ename: "MANOOV R",
    venue: "SJT422",
    ctype: "TH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A1+TA1",
    ename: "POORNIMA N",
    venue: "SJT423",
    ctype: "TH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A2+TA2",
    ename: "MANOOV R",
    venue: "SJT422",
    ctype: "TH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A2+TA2",
    ename: "POORNIMA N",
    venue: "SJT415",
    ctype: "TH",
  },
  {
    crcode: "CSE2008",
    cname: "CSE2008 - Network Security",
    slot: "G1+TG1",
    ename: "KARTHIK G M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2008",
    cname: "CSE2008 - Network Security",
    slot: "G1+TG1",
    ename: "KARTHIK G M",
    venue: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "D2+TD2",
    ename: "KRISHNARAJ N",
    venue: "SJT702",
    ctype: "TH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "G1+TG1",
    ename: "SANTHI H",
    venue: "SJT407",
    ctype: "TH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "G1+TG1",
    ename: "JAYASHREE J",
    venue: "SJT409",
    ctype: "TH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "G2+TG2",
    ename: "VIJAYASHREE J",
    venue: "SJT403",
    ctype: "TH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L11+L12",
    ename: "KANNAN A",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L21+L22",
    ename: "ARUP GHOSH",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L31+L32",
    ename: "ARUP GHOSH",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L49+L50",
    ename: "ILANTHENRAL K P S K",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L57+L58",
    ename: "KANNAN A",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E1",
    ename: "ILANTHENRAL K P S K",
    venue: "SJT507",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E1",
    ename: "KANNAN A",
    venue: "SJT617",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E1",
    ename: "ARUP GHOSH",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E2",
    ename: "KANNAN A",
    venue: "SJT407",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E2",
    ename: "ARUP GHOSH",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L11+L12",
    ename: "SURESH P",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L19+L20",
    ename: "ALKHA MOHAN",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L33+L34",
    ename: "ALKHA MOHAN",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L39+L40",
    ename: "ABDUL GAFFAR H",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C1+TC1",
    ename: "ABDUL GAFFAR H",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C1+TC1",
    ename: "ALKHA MOHAN",
    venue: "SJT604",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C2+TC2",
    ename: "ALKHA MOHAN",
    venue: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C2+TC2",
    ename: "SURESH P",
    venue: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "NALINI N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "GUNAVATHI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "VASANTHA W B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    ename: "NALINI N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    ename: "GUNAVATHI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "NALINI N",
    venue: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "GUNAVATHI C",
    venue: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "VASANTHA W B",
    venue: "SJT405",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    ename: "NALINI N",
    venue: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    ename: "GUNAVATHI C",
    venue: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E1+TE1",
    ename: "DEEPA.K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    ename: "SARAVANAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    ename: "DEEPA.K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E1+TE1",
    ename: "DEEPA.K",
    venue: "SJT408",
    ctype: "ETH",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    ename: "SARAVANAKUMAR K",
    venue: "SJT707",
    ctype: "ETH",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    ename: "DEEPA.K",
    venue: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1018",
    cname: "EEE1018 - Nanotechnology Fundamentals and its Applications",
    slot: "F1+TF1",
    ename: "PARTHA SHARATHI MALLICK",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L11+L12",
    ename: "KOWSALYA M",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L15+L16",
    ename: "RAVI K",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L21+L22",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L25+L26",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L31+L32",
    ename: "GOKULAKRISHNAN G",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L33+L34",
    ename: "VIJAYAKUMAR D",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L39+L40",
    ename: "SARAVANAKUMAR R",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L43+L44",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L55+L56",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L9+L10",
    ename: "CHITRA A",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE2001",
    cname: "EEE2001 - Network Theory",
    slot: "C1+TC1",
    ename: "PALANISAMY K",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "EEE2003",
    cname: "EEE2003 - Electromechanical Energy Conversion",
    slot: "C1+TC1",
    ename: "MAGESHVARAN R",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    ename: "JAGANATHA PANDIAN B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    ename: "JAGANATHA PANDIAN B",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L33+L34",
    ename: "INDRAGANDHI V",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L37+L38",
    ename: "INDRAGANDHI V",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "B1+TB1",
    ename: "INDRAGANDHI V",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    ename: "GOKULAKRISHNAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    ename: "GOKULAKRISHNAN G",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "HIN1003",
    cname: "HIN1003 - Prathamik Hindi",
    slot: "C1+TC1",
    ename: "JAYALAKSHMI K",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "L15+L16",
    ename: "JAYALAKSHMI K",
    venue: "TT107",
    ctype: "ELA",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "B1",
    ename: "JAYALAKSHMI K",
    venue: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "B2+TB2",
    ename: "RASHMI M",
    venue: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "F1+TF1",
    ename: "BALAMURUGAN J",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    ename: "BHUVANESWARI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    ename: "BHUVANESWARI M",
    venue: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    ename: "BANGALORE MORARJI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    ename: "BANGALORE MORARJI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    ename: "BANGALORE MORARJI",
    venue: "SMVG21",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    ename: "BANGALORE MORARJI",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    ename: "SUDHEER C.V.",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    ename: "SUDHEER C.V.",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TB2",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TG1",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1033",
    cname: "HUM1033 - Micro Economics",
    slot: "D1+TD1",
    ename: "NILAVATHY K",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1038",
    cname: "HUM1038 - International Economics",
    slot: "E2+TE2",
    ename: "ALLI P",
    venue: "TT423",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "B1+TB1",
    ename: "RASHMI M",
    venue: "SJT323",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "C2+TC2",
    ename: "PRABAKAR S",
    venue: "SMV101",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "D2+TD2",
    ename: "BALAMURUGAN J",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "F1+TF1",
    ename: "PRABAKAR S",
    venue: "MB226",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "G2+TG2",
    ename: "KUBENDRAN A",
    venue: "TT305",
    ctype: "TH",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    ename: "KUBENDRAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    ename: "KUBENDRAN A",
    venue: "TT305",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L55+L56",
    ename: "NAVIN KUMAR",
    venue: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L57+L58",
    ename: "BHUVANESWARI M",
    venue: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "E1",
    ename: "BHUVANESWARI M",
    venue: "TT413",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "F2",
    ename: "NAVIN KUMAR",
    venue: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITE4099",
    cname: "ITE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITL1002",
    cname: "ITL1002 - Italiano Di Base",
    slot: "G1",
    ename: "JACOPO MOSESSO",
    venue: "SJT604",
    ctype: "TH",
  },
  {
    crcode: "MEE1001",
    cname: "MEE1001 - Engineering Drawing",
    slot: "L31+L32+L37+L38",
    ename: "CHINMAYA SAHU",
    venue: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE1004",
    cname: "MEE1004 - Fluid Mechanics",
    slot: "L5+L6",
    ename: "ABINASH MOHANTA",
    venue: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1008",
    cname: "MEE1008 - MEMS",
    slot: "B2+TB2",
    ename: "RAGHURAMAN D R S",
    venue: "MB312",
    ctype: "TH",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    ename: "SRIDHAR V G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    ename: "SRIDHAR V G",
    venue: "MB312",
    ctype: "ETH",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L23+L24",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L5+L6",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "D2+TD2+V6",
    ename: "GAYATHRI V",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE1027",
    cname: "MEE1027 - Instrumentation and Control Engineering",
    slot: "L37+L38",
    ename: "SOVAN SUNDAR DASGUPTA",
    venue: "GDN137",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "L39+L40",
    ename: "VIJESH V JOSHI",
    venue: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "G1+TG1",
    ename: "VIJESH V JOSHI",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "L57+L58",
    ename: "GUNDABATTINI EDISON",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "GUNDABATTINI EDISON",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L3+L4",
    ename: "RAMMOHAN A",
    venue: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L9+L10",
    ename: "RAMMOHAN A",
    venue: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "E2+TE2",
    ename: "RAMMOHAN A",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    ename: "SENTHIL KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    ename: "SENTHIL KUMAR S",
    venue: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    ename: "ASOKAN M.A",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    ename: "GAURAV GUPTA",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L15+L16",
    ename: "ASOKAN M.A",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L27+L28",
    ename: "GAURAV GUPTA",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    ename: "SABOOR S",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    ename: "SENTHIL KUMAR M",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L37+L38",
    ename: "SABOOR S",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L51+L52",
    ename: "SENTHIL KUMAR M",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L19+L20",
    ename: "NAVEEN J",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L27+L28",
    ename: "MOHANKUMAR K V",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L3+L4",
    ename: "NAVEEN J",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L35+L36",
    ename: "SRIDHARAN K",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L39+L40",
    ename: "SRIDHARAN K",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L45+L46",
    ename: "MANIKANDAN M",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L49+L50",
    ename: "MANIKANDAN M",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L7+L8",
    ename: "MOHANKUMAR K V",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    ename: "SRIDHARAN K",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    ename: "MANIKANDAN M",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    ename: "NAVEEN J",
    venue: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    ename: "MOHANKUMAR K V",
    venue: "MB225",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L11+L12",
    ename: "TAPANO KUMAR HOTTA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L15+L16",
    ename: "GOVINDHA RASU N",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L3+L4",
    ename: "TAPANO KUMAR HOTTA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L35+L36",
    ename: "ARUNA KUMAR BEHURA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L43+L44",
    ename: "ARUNA KUMAR BEHURA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L45+L46",
    ename: "NISHANT TIWARI",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L53+L54",
    ename: "NISHANT TIWARI",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L9+L10",
    ename: "GOVINDHA RASU N",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    ename: "GOVINDHA RASU N",
    venue: "GDNG08A",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    ename: "TAPANO KUMAR HOTTA",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "ARUNA KUMAR BEHURA",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "NISHANT TIWARI",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L11+L12",
    ename: "JAMBESWAR SAHU",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L41+L42",
    ename: "VENKATESHWARLU B",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L53+L54",
    ename: "ANOJ GIRI",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    ename: "VENKATESHWARLU B",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    ename: "ANOJ GIRI",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G2",
    ename: "JAMBESWAR SAHU",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L11+L12+L21+L22",
    ename: "RAJKUMAR E",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L31+L32+L39+L40",
    ename: "RAJKUMAR E",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B1",
    ename: "RAJKUMAR E",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B2",
    ename: "RAJKUMAR E",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    ename: "GUNJI BALA MURALI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    ename: "GUNJI BALA MURALI",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2009",
    cname: "MEE2009 - Tribology",
    slot: "E1+TE1+V7",
    ename: "ARULVEL S",
    venue: "GDN129",
    ctype: "TH",
  },
  {
    crcode: "MEE2010",
    cname: "MEE2010 - Design of Composite Materials",
    slot: "D2+TD2+V6",
    ename: "SK ARIFUL RAHAMAN",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    ename: "RIJESH M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    ename: "RIJESH M",
    venue: "MB226",
    ctype: "ETH",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    ename: "CHINMAYA PRASAD MOHANTY",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    ename: "CHINMAYA PRASAD MOHANTY",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L15+L16",
    ename: "RAJAMURUGAN G",
    venue: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L3+L4",
    ename: "RAJAMURUGAN G",
    venue: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "F2+TF2",
    ename: "RAJAMURUGAN G",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    ename: "RAJA K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    ename: "RAJA K",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2020",
    cname: "MEE2020 - Metal Forming Theory and Practice",
    slot: "G2+TG2",
    ename: "DEVASRI FULORIA",
    venue: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE2022",
    cname: "MEE2022 - Power Plant Engineering",
    slot: "B1+TB1",
    ename: "ASOKAN M.A",
    venue: "MB211",
    ctype: "TH",
  },
  {
    crcode: "MEE2023",
    cname: "MEE2023 - Gas Dynamics and Jet Propulsion",
    slot: "E1+TE1+V7",
    ename: "HUMRUTHA G",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    ename: "YAGNA S MUKKAMALA",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    ename: "YAGNA S MUKKAMALA",
    venue: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2031",
    cname: "MEE2031 - Theory of Metal Cutting and Forming",
    slot: "F1+TF1",
    ename: "PANDIVELAN C",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2032",
    cname: "MEE2032 - Kinematics and Dynamics of Machinery",
    slot: "D2+TD2+V6",
    ename: "NIRANJANA BEHERA",
    venue: "MB225",
    ctype: "TH",
  },
  {
    crcode: "MEE2038",
    cname: "MEE2038 - Thermal and Heat Transfer",
    slot: "A1+TA1+V1",
    ename: "DEEPAKKUMAR R",
    venue: "MB224",
    ctype: "TH",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    ename: "BASKAR P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    ename: "BASKAR P",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2050",
    cname: "MEE2050 - Vehicle Dynamics",
    slot: "E1+TE1+V7",
    ename: "SAKTHIVEL P",
    venue: "MB309",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    ename: "NARENDIRANATH BABU T",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    ename: "AHANKARI SANDEEP SURESHRAO",
    venue: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "B2+TB2+V4",
    ename: "VINOTH JEBARAJ A",
    venue: "MB214",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "D2+TD2+V6",
    ename: "GOKUL KUMAR K",
    venue: "MB229",
    ctype: "TH",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "L33+L34",
    ename: "PADMANABHAN K",
    venue: "MB110",
    ctype: "ELA",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "E1+TE1+V7",
    ename: "PADMANABHAN K",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3004",
    cname: "MEE3004 - Internal Combustion Engines",
    slot: "B1+TB1",
    ename: "NANTHA GOPAL K",
    venue: "GDNG08",
    ctype: "TH",
  },
  {
    crcode: "MEE3005",
    cname: "MEE3005 - Refrigeration and Airconditioning",
    slot: "D2+TD2+TDD2+V6",
    ename: "RAMESH KUMAR C",
    venue: "MB226",
    ctype: "TH",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L19+L20",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L9+L10",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "B2",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "L41+L42",
    ename: "DEEPA A",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "D1",
    ename: "DEEPA A",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L39+L40",
    ename: "NATARAJ G",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L41+L42",
    ename: "NATARAJ G",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "G1+TG1",
    ename: "NATARAJ G",
    venue: "MB211",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    ename: "RENOLD ELSEN S",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    ename: "SRINIVASAN NARAYANAN",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    ename: "VENKATESAN K",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    ename: "OYYARAVELU R",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    ename: "DEVENDIRAN S",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    ename: "CHIRANJEEVI C",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    ename: "RAJA SEKHAR Y",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    ename: "BIKASH ROUTH",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    ename: "RENOLD ELSEN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    ename: "SRINIVASAN NARAYANAN",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    ename: "VENKATESAN K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    ename: "OYYARAVELU R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    ename: "DEVENDIRAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    ename: "CHIRANJEEVI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    ename: "RAJA SEKHAR Y",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    ename: "BIKASH ROUTH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "OYYARAVELU R",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "DEVENDIRAN S",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "CHIRANJEEVI C",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "RAJA SEKHAR Y",
    venue: "MB213",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "VENKATESAN K",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "BIKASH ROUTH",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "RENOLD ELSEN S",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "SRINIVASAN NARAYANAN",
    venue: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    ename: "ANANDAVEL K",
    venue: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    ename: "ANANDAVEL K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "G2",
    ename: "ANANDAVEL K",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L19+L20",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L3+L4",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "D2+TD2+V6",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB227",
    ctype: "ETH",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "SET5003",
    cname: "SET5003 - Science, Engineering and Technology Project - III",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BKT4001",
    cname: "BKT4001 - Blockchain Ecosystem",
    slot: "G1+TG1",
    ename: "UMADEVI K S",
    venue: "SJT501",
    ctype: "TH",
  },
  {
    crcode: "BKT4005",
    cname: "BKT4005 - Blockchain Architecture Design and Use Cases",
    slot: "C1+TC1",
    ename: "MADHU VISWANATHAM V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BKT4005",
    cname: "BKT4005 - Blockchain Architecture Design and Use Cases",
    slot: "C1+TC1",
    ename: "MADHU VISWANATHAM V",
    venue: "SJT715",
    ctype: "ETH",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E1+TE1",
    ename: "GOPICHAND G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E2+TE2",
    ename: "GOPICHAND G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E1+TE1",
    ename: "GOPICHAND G",
    venue: "SJT707",
    ctype: "ETH",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E2+TE2",
    ename: "GOPICHAND G",
    venue: "SJT702",
    ctype: "ETH",
  },
  {
    crcode: "ENG1000",
    cname: "ENG1000 - Foundation English - I",
    slot: "L11+L12+L27+L28",
    ename: "SRINIVASAN R",
    venue: "TT432",
    ctype: "LO",
  },
  {
    crcode: "ENG2000",
    cname: "ENG2000 - Foundation English - II",
    slot: "L33+L34+L47+L48",
    ename: "SANKAR G",
    venue: "SJT721",
    ctype: "LO",
  },

  {
    crcode: "CHE1006",
    cname: "CHE1006 - Heat Transfer",
    slot: "L23+L24",
    ename: "VELU S",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "CHE1006",
    cname: "CHE1006 - Heat Transfer",
    slot: "L23+L24",
    ename: "NIRMALA G S",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "CHE1006",
    cname: "CHE1006 - Heat Transfer",
    slot: "L23+L24",
    ename: "VELU S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1006",
    cname: "CHE1006 - Heat Transfer",
    slot: "L23+L24",
    ename: "NIRMALA G S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1006",
    cname: "CHE1006 - Heat Transfer",
    slot: "C2",
    ename: "VELU S",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "CHE1022",
    cname: "CHE1022 - Mechanical Operations",
    slot: "L33+L34",
    ename: "BABU PONNUSAMI A",
    venue: "SMVG29",
    ctype: "ELA",
  },
  {
    crcode: "CHE1022",
    cname: "CHE1022 - Mechanical Operations",
    slot: "E1+TE1",
    ename: "BABU PONNUSAMI A",
    venue: "SMVG21",
    ctype: "ETH",
  },
  {
    crcode: "CHE2001",
    cname: "CHE2001 - Chemical Reaction Engineering",
    slot: "L19+L20",
    ename: "ASLAM ABDULLAH M",
    venue: "SMV127",
    ctype: "ELA",
  },
  {
    crcode: "CHE2001",
    cname: "CHE2001 - Chemical Reaction Engineering",
    slot: "L3+L4",
    ename: "ASLAM ABDULLAH M",
    venue: "SMV127",
    ctype: "ELA",
  },
  {
    crcode: "CHE2001",
    cname: "CHE2001 - Chemical Reaction Engineering",
    slot: "L31+L32",
    ename: "MURUGANANDAM L",
    venue: "SMV127",
    ctype: "ELA",
  },
  {
    crcode: "CHE2001",
    cname: "CHE2001 - Chemical Reaction Engineering",
    slot: "L31+L32",
    ename: "RIMA BISWAS",
    venue: "SMV127",
    ctype: "ELA",
  },
  {
    crcode: "CHE2001",
    cname: "CHE2001 - Chemical Reaction Engineering",
    slot: "L49+L50",
    ename: "MURUGANANDAM L",
    venue: "SMV127",
    ctype: "ELA",
  },
  {
    crcode: "CHE2001",
    cname: "CHE2001 - Chemical Reaction Engineering",
    slot: "L49+L50",
    ename: "RIMA BISWAS",
    venue: "SMV127",
    ctype: "ELA",
  },
  {
    crcode: "CHE2001",
    cname: "CHE2001 - Chemical Reaction Engineering",
    slot: "B1+TB1",
    ename: "MURUGANANDAM L",
    venue: "SMV101",
    ctype: "ETH",
  },
  {
    crcode: "CHE2001",
    cname: "CHE2001 - Chemical Reaction Engineering",
    slot: "B2+TB2",
    ename: "ASLAM ABDULLAH M",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "CHE3001",
    cname: "CHE3001 - Computational Methods in Process Engineering",
    slot: "L29+L30",
    ename: "MONASH P",
    venue: "SMVG31",
    ctype: "ELA",
  },
  {
    crcode: "CHE3001",
    cname: "CHE3001 - Computational Methods in Process Engineering",
    slot: "L33+L34",
    ename: "MONASH P",
    venue: "SMVG31",
    ctype: "ELA",
  },
  {
    crcode: "CHE3001",
    cname: "CHE3001 - Computational Methods in Process Engineering",
    slot: "C1+TC1",
    ename: "MONASH P",
    venue: "SMV101",
    ctype: "ETH",
  },
  {
    crcode: "CHE3003",
    cname: "CHE3003 - Mass Transfer",
    slot: "A1+TA1",
    ename: "NIRMALA G S",
    venue: "SMV101",
    ctype: "TH",
  },
  {
    crcode: "CHE3003",
    cname: "CHE3003 - Mass Transfer",
    slot: "A2+TA2",
    ename: "MONASH P",
    venue: "SMV101",
    ctype: "TH",
  },
  {
    crcode: "MEE1001",
    cname: "MEE1001 - Engineering Drawing",
    slot: "L31+L32+L37+L38",
    ename: "CHINMAYA SAHU",
    venue: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "SMV104",
    ctype: "ETH",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "CHE1009",
    cname: "CHE1009 - Biochemical Engineering",
    slot: "E1+TE1",
    ename: "ARUNA SINGH",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1010",
    cname: "CHE1010 - Process Plant Utilities",
    slot: "E2+TE2",
    ename: "CHITRA D",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1011",
    cname: "CHE1011 - Optimization of Chemical Processes",
    slot: "F1+TF1",
    ename: "DHARMENDRA KUMAR BAL",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE1013",
    cname: "CHE1013 - Natural Gas Engineering",
    slot: "G1+TG1",
    ename: "ASLAM ABDULLAH M",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "CHE1018",
    cname: "CHE1018 - Membrane Separations Technology",
    slot: "G1+TG1",
    ename: "THOMAS THEODORE",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1019",
    cname: "CHE1019 - Polymer Technology",
    slot: "D1+TD1",
    ename: "AABID HUSSAIN SHAIK",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE2003",
    cname: "CHE2003 - Chemical Product Design",
    slot: "G2+TG2",
    ename: "SHANKAR RAMAN DHANUSHKODI",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "L47+L48",
    ename: "SANKARDOSS V",
    venue: "TT433",
    ctype: "ELA",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "E1",
    ename: "SANKARDOSS V",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L23+L24",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L5+L6",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "D2+TD2+V6",
    ename: "GAYATHRI V",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L19+L20",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L3+L4",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "D2+TD2+V6",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB227",
    ctype: "ETH",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "PRABHAKARAN D",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "CHERALATHAN K.K.",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "F2+TF2",
    ename: "PRABHAKARAN D",
    venue: "TT208",
    ctype: "ETH",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L13+L14+L21+L22",
    ename: "AJANTA SIRCAR",
    venue: "SJT721",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L31+L32+L47+L48",
    ename: "RAJU R.L.N",
    venue: "TT116",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L7+L8+L25+L26",
    ename: "SOUMEN MUKHERJEE",
    venue: "TT306",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L9+L10+L23+L24",
    ename: "VARSHA K",
    venue: "SJT701",
    ctype: "LO",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "C2",
    ename: "GAURAV SUSHANT",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "F1",
    ename: "SHILPI GUPTA",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "B1",
    ename: "COUMARAN G",
    venue: "TT207",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "CALAIVANANE R",
    venue: "PRP414",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "ANTOINETTE DANIEL",
    venue: "SMV319",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "G1",
    ename: "MALATHY O",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "HUM1021",
    cname: "HUM1021 - Ethics and Values",
    slot: "F1",
    ename: "TONY P JOSE",
    venue: "SJT107",
    ctype: "TH",
  },
  {
    crcode: "JAP1001",
    cname: "JAP1001 - Japanese for Beginners",
    slot: "G1",
    ename: "KHANJAN",
    venue: "SJT606",
    ctype: "TH",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TD2",
    ename: "SHANKAR RAMAN DHANUSHKODI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TF1",
    ename: "SHANKAR RAMAN DHANUSHKODI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TD2",
    ename: "SHANKAR RAMAN DHANUSHKODI",
    venue: "SMV128",
    ctype: "ETH",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TF1",
    ename: "SHANKAR RAMAN DHANUSHKODI",
    venue: "SMV104",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L3+L4",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L31+L32",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L33+L34",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "TT621",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E2+TE2",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA1",
    ename: "BHASKAR SEN GUPTA",
    venue: "SJT222",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "ANUSHA P T",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "BALAKRISHNAN S",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "LEEMA ROSE VIANNIE",
    venue: "SJT305",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC1",
    ename: "TARUN",
    venue: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC2",
    ename: "SATHYA SWAROOP N.R",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "ABHISHEK KUMAR SINGH",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "DHRITIMAN GUPTA",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TF1",
    ename: "RAVI SHANKER BABU",
    venue: "SJT201",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "SUDIPTA SAHA",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "USHA RANI M",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT530A",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT524",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV101",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV102",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV320",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "A2+TA2",
    ename: "SURESH P.K",
    venue: "SMV217",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "B2+TB2",
    ename: "RAHUL VASHISHTH",
    venue: "MB225",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "E1+TE1",
    ename: "SURESH P.K",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1008",
    cname: "CBS1008 - Operations Research",
    slot: "L25+L26",
    ename: "SIVARAJ R",
    venue: "SMVG16",
    ctype: "ELA",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "A2",
    ename: "KHANA BAIDYA",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B1",
    ename: "KHANA BAIDYA",
    venue: "TT208",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B2",
    ename: "KHANA BAIDYA",
    venue: "TT206",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "D1",
    ename: "KHANA BAIDYA",
    venue: "TT205",
    ctype: "TH",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "CTSG05",
    ctype: "ELA",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "F1+TF1",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "L53+L54",
    ename: "MALATHY  J",
    venue: "CTSG04",
    ctype: "ELA",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "A1+TA1",
    ename: "MALATHY  J",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "B2+TB2",
    ename: "GANAPATHY G.P",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C1+TC1",
    ename: "ARUNAVA RAY",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C2+TC2",
    ename: "VAANI N",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1013",
    cname: "CLE1013 - Environmental Impact Assessment",
    slot: "A2+TA2",
    ename: "SRIMURUGANANDAM B",
    venue: "CDMM404",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "C2+TC2",
    ename: "SASANKA BHUSHAN PULIPATI",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "D1+TD1",
    ename: "JAYAPRAKASH J",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "TB1",
    ename: "SUGANYA OM",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE2003",
    cname: "CLE2003 - Structural Analysis",
    slot: "C1+TC1+V2",
    ename: "SANTHI A.S",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "B1",
    ename: "DILLIP KUMAR BARIK",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2008",
    cname: "CLE2008 - Construction Planning and Management",
    slot: "C1+TC1",
    ename: "JOHN SUSHIL PACKIARAJ",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "CDMM105",
    ctype: "ETH",
  },
  {
    crcode: "CLE2022",
    cname: "CLE2022 - Economics and Business Finance for Civil Engineers",
    slot: "B2+TB2",
    ename: "PRASANNA VENKATESAN R",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "L59+L60",
    ename: "SURENDAR M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "B1",
    ename: "SURENDAR M",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE3001",
    cname: "CLE3001 - Quantity Surveying and Estimating",
    slot: "G2",
    ename: "SOFI A",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "L39+L40",
    ename: "HAREESH M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "E1+TE1+V5",
    ename: "HAREESH M",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "L29+L30",
    ename: "THIRUMALINI S",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "D2+TD2",
    ename: "THIRUMALINI S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1018",
    cname: "EEE1018 - Nanotechnology Fundamentals and its Applications",
    slot: "F1+TF1",
    ename: "PARTHA SHARATHI MALLICK",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L11+L12",
    ename: "KOWSALYA M",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L15+L16",
    ename: "RAVI K",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L21+L22",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L25+L26",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L31+L32",
    ename: "GOKULAKRISHNAN G",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L33+L34",
    ename: "VIJAYAKUMAR D",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L39+L40",
    ename: "SARAVANAKUMAR R",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L43+L44",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L55+L56",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L9+L10",
    ename: "CHITRA A",
    venue: "TT037",
    ctype: "LO",
  },

  {
    crcode: "CSE1007",
    cname: "CSE1007 - Java Programming",
    slot: "C1+TC1",
    venue: "PRIYA G",
    ename: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L1+L2",
    venue: "ANUSHA N",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L11+L12",
    venue: "KRISHNAMOORTHY A",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L13+L14",
    venue: "NARESH K",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L15+L16",
    venue: "ANURADHA G",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L21+L22",
    venue: "KRISHNAMOORTHY A",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L25+L26",
    venue: "ANURADHA G",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L27+L28",
    venue: "RAJAKUMAR K",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L31+L32",
    venue: "RAJAKUMAR K",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L37+L38",
    venue: "SARASWATHI PRIYADHARSHINI A",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L39+L40",
    venue: "AJITHA D",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L41+L42",
    venue: "SARASWATHI PRIYADHARSHINI A",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L43+L44",
    venue: "AJITHA D",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L45+L46",
    venue: "SHOBHA REKH",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L51+L52",
    venue: "SHOBHA REKH",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L7+L8",
    venue: "ANUSHA N",
    ename: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L1+L2",
    venue: "ANUSHA N",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L11+L12",
    venue: "KRISHNAMOORTHY A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L13+L14",
    venue: "NARESH K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L15+L16",
    venue: "ANURADHA G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L21+L22",
    venue: "KRISHNAMOORTHY A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L25+L26",
    venue: "ANURADHA G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L27+L28",
    venue: "RAJAKUMAR K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L31+L32",
    venue: "RAJAKUMAR K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L37+L38",
    venue: "SARASWATHI PRIYADHARSHINI A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L39+L40",
    venue: "AJITHA D",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L41+L42",
    venue: "SARASWATHI PRIYADHARSHINI A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L43+L44",
    venue: "AJITHA D",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L45+L46",
    venue: "SHOBHA REKH",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L51+L52",
    venue: "SHOBHA REKH",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L7+L8",
    venue: "ANUSHA N",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    venue: "KRISHNAMOORTHY A",
    ename: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    venue: "NARESH K",
    ename: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    venue: "ANURADHA G",
    ename: "SJT505",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    venue: "RAJAKUMAR K",
    ename: "SJT522",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    venue: "SARASWATHI PRIYADHARSHINI A",
    ename: "SJT617",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    venue: "AJITHA D",
    ename: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    venue: "SHOBHA REKH",
    ename: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    venue: "ANUSHA N",
    ename: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    venue: "KRISHNAMOORTHY A",
    ename: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    venue: "ANURADHA G",
    ename: "SJT505",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    venue: "RAJAKUMAR K",
    ename: "SJT604",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    venue: "SARASWATHI PRIYADHARSHINI A",
    ename: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    venue: "AJITHA D",
    ename: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    venue: "SHOBHA REKH",
    ename: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    venue: "ANUSHA N",
    ename: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE2013",
    cname: "CSE2013 - Theory of Computation",
    slot: "F1+TF1",
    venue: "KUMAR K",
    ename: "PRPG21",
    ctype: "TH",
  },
  {
    crcode: "CSE2013",
    cname: "CSE2013 - Theory of Computation",
    slot: "F2+TF2",
    venue: "SATHIYA KUMAR C",
    ename: "SJT626",
    ctype: "TH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L13+L14",
    venue: "ANTO S",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L15+L16",
    venue: "SWATHI J.N",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L25+L26",
    venue: "SHASHANK MOULI SATAPATHY",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L29+L30",
    venue: "PREETHA EVANGELINE D",
    ename: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L3+L4",
    venue: "SIVAPRAKASH S",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L31+L32",
    venue: "USHUS ELIZEBETH ZACHARIAH",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L33+L34",
    venue: "NAVEENKUMAR J",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L37+L38",
    venue: "ANAND BIHARI",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L39+L40",
    venue: "PREETHA EVANGELINE D",
    ename: "SJT122",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L41+L42",
    venue: "SIVAPRAKASH S",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L43+L44",
    venue: "AKILA VICTOR",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L45+L46",
    venue: "MARGRET ANOUNCIA S",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L5+L6",
    venue: "USHUS ELIZEBETH ZACHARIAH",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L55+L56",
    venue: "ANTO S",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L9+L10",
    venue: "PEREPI RAJARAJESWARI",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L13+L14",
    venue: "ANTO S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L15+L16",
    venue: "SWATHI J.N",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L25+L26",
    venue: "SHASHANK MOULI SATAPATHY",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L29+L30",
    venue: "PREETHA EVANGELINE D",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L3+L4",
    venue: "SIVAPRAKASH S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L31+L32",
    venue: "USHUS ELIZEBETH ZACHARIAH",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L33+L34",
    venue: "NAVEENKUMAR J",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L37+L38",
    venue: "ANAND BIHARI",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L39+L40",
    venue: "PREETHA EVANGELINE D",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L41+L42",
    venue: "SIVAPRAKASH S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L43+L44",
    venue: "AKILA VICTOR",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L45+L46",
    venue: "MARGRET ANOUNCIA S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L5+L6",
    venue: "USHUS ELIZEBETH ZACHARIAH",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L55+L56",
    venue: "ANTO S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L9+L10",
    venue: "PEREPI RAJARAJESWARI",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    venue: "MARGRET ANOUNCIA S",
    ename: "SJT303",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    venue: "USHUS ELIZEBETH ZACHARIAH",
    ename: "SJT308",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    venue: "AKILA VICTOR",
    ename: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    venue: "ANTO S",
    ename: "SJT305",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    venue: "ANAND BIHARI",
    ename: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    venue: "NAVEENKUMAR J",
    ename: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    venue: "SIVAPRAKASH S",
    ename: "SJT402",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    venue: "SWATHI J.N",
    ename: "SJT305",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    venue: "USHUS ELIZEBETH ZACHARIAH",
    ename: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    venue: "SHASHANK MOULI SATAPATHY",
    ename: "SJT308",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    venue: "ANTO S",
    ename: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    venue: "PEREPI RAJARAJESWARI",
    ename: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    venue: "SIVAPRAKASH S",
    ename: "SJT402",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "D1",
    venue: "PREETHA EVANGELINE D",
    ename: "SJT702",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "D2",
    venue: "PREETHA EVANGELINE D",
    ename: "SJT703",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L1+L2",
    venue: "KAUSER AHMED P",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    venue: "MEENAKSHI S P",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    venue: "JAYAKUMAR S",
    ename: "SJT419",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    venue: "LYDIA JANE G",
    ename: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    venue: "RAJKUMAR R",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    venue: "JAYAKUMAR K",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    venue: "MARY MEKALA A",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    venue: "SRIDHAR RAJ S",
    ename: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    venue: "RAJKUMAR R",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    venue: "JAYAKUMAR K",
    ename: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L33+L34",
    venue: "MEENAKSHI S P",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    venue: "JAYAKUMAR S",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    venue: "LYDIA JANE G",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L37+L38",
    venue: "MARY MEKALA A",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L41+L42",
    venue: "SRIDHAR RAJ S",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L51+L52",
    venue: "ARCHANA T",
    ename: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L57+L58",
    venue: "KAUSER AHMED P",
    ename: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L7+L8",
    venue: "ARCHANA T",
    ename: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L1+L2",
    venue: "KAUSER AHMED P",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    venue: "MEENAKSHI S P",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    venue: "JAYAKUMAR S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    venue: "LYDIA JANE G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    venue: "RAJKUMAR R",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    venue: "JAYAKUMAR K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    venue: "MARY MEKALA A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    venue: "SRIDHAR RAJ S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    venue: "RAJKUMAR R",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    venue: "JAYAKUMAR K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L33+L34",
    venue: "MEENAKSHI S P",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    venue: "JAYAKUMAR S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    venue: "LYDIA JANE G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L37+L38",
    venue: "MARY MEKALA A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L41+L42",
    venue: "SRIDHAR RAJ S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L51+L52",
    venue: "ARCHANA T",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L57+L58",
    venue: "KAUSER AHMED P",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L7+L8",
    venue: "ARCHANA T",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "B1",
    venue: "JAYAKUMAR S",
    ename: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "B2",
    venue: "JAYAKUMAR S",
    ename: "SJT505",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    venue: "MEENAKSHI S P",
    ename: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    venue: "RAJKUMAR R",
    ename: "SJT508",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    venue: "MARY MEKALA A",
    ename: "SJT311A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    venue: "KAUSER AHMED P",
    ename: "SJT302",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    venue: "ARCHANA T",
    ename: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    venue: "LYDIA JANE G",
    ename: "SJT408",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    venue: "JAYAKUMAR K",
    ename: "SJT823",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    venue: "SRIDHAR RAJ S",
    ename: "SJT222",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    venue: "MEENAKSHI S P",
    ename: "SJT302",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    venue: "RAJKUMAR R",
    ename: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    venue: "MARY MEKALA A",
    ename: "SJT305",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    venue: "KAUSER AHMED P",
    ename: "SJT604",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    venue: "ARCHANA T",
    ename: "SJT422",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    venue: "LYDIA JANE G",
    ename: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    venue: "JAYAKUMAR K",
    ename: "SJT712",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    venue: "SRIDHAR RAJ S",
    ename: "SJT802",
    ctype: "ETH",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "L47+L48",
    venue: "SANKARDOSS V",
    ename: "TT433",
    ctype: "ELA",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "E1",
    venue: "SANKARDOSS V",
    ename: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A1+TA1",
    venue: "MANOOV R",
    ename: "SJT422",
    ctype: "TH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A1+TA1",
    venue: "POORNIMA N",
    ename: "SJT423",
    ctype: "TH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A2+TA2",
    venue: "MANOOV R",
    ename: "SJT422",
    ctype: "TH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A2+TA2",
    venue: "POORNIMA N",
    ename: "SJT415",
    ctype: "TH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    venue: "KRISHNAMOORTHY A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    venue: "DHEEBA J",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A2+TA2",
    venue: "DHEEBA J",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "E2+TE2",
    venue: "SENTHILNATHAN",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    venue: "KRISHNAMOORTHY A",
    ename: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    venue: "DHEEBA J",
    ename: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A2+TA2",
    venue: "DHEEBA J",
    ename: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "E2+TE2",
    venue: "SENTHILNATHAN",
    ename: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    venue: "MOHANA CM",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    venue: "SANTHI K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    venue: "SRIDHAR RAJ S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    venue: "ANUSHA N",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    venue: "TUSAR KANTI MISHRA",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    venue: "SARASWATHI PRIYADHARSHINI A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    venue: "DILIPKUMAR S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    venue: "MOHANA CM",
    ename: "SJT715",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    venue: "SANTHI K",
    ename: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    venue: "SRIDHAR RAJ S",
    ename: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    venue: "ANUSHA N",
    ename: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    venue: "TUSAR KANTI MISHRA",
    ename: "SJT617",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    venue: "SARASWATHI PRIYADHARSHINI A",
    ename: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    venue: "DILIPKUMAR S",
    ename: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L15+L16",
    venue: "SIVAPRAKASH S",
    ename: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L19+L20",
    venue: "DEEPSUBHRA GUHA ROY",
    ename: "SJTG17",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L29+L30",
    venue: "PEREPI RAJARAJESWARI",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L33+L34",
    venue: "DEEPSUBHRA GUHA ROY",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L39+L40",
    venue: "NAVEENKUMAR J",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L45+L46",
    venue: "CHELLATAMILAN T",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L51+L52",
    venue: "PEREPI RAJARAJESWARI",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A1+TA1",
    venue: "CHELLATAMILAN T",
    ename: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A1+TA1",
    venue: "PEREPI RAJARAJESWARI",
    ename: "SJT407",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A1+TA1",
    venue: "DEEPSUBHRA GUHA ROY",
    ename: "SJT501",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A2+TA2",
    venue: "PEREPI RAJARAJESWARI",
    ename: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A2+TA2",
    venue: "DEEPSUBHRA GUHA ROY",
    ename: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "D2+TD2",
    venue: "SIVAPRAKASH S",
    ename: "SJT501",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "G1+TG1",
    venue: "NAVEENKUMAR J",
    ename: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L11+L12",
    venue: "RAJA S P",
    ename: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L13+L14",
    venue: "CHANDRA MOHAN B",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L19+L20",
    venue: "ANAND M",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    venue: "ANIL KUMAR K",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    venue: "RUBY D",
    ename: "SJT419",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L23+L24",
    venue: "SIVA SHANMUGAM G",
    ename: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    venue: "RAMANI S",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    venue: "SELVI M",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    venue: "AMUTHA PRABAKAR M",
    ename: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    venue: "SENDHIL KUMAR K.S",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L31+L32",
    venue: "MURALI S",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    venue: "AMUTHA PRABAKAR M",
    ename: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    venue: "SARITHA MURALI",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L39+L40",
    venue: "SENDHIL KUMAR K.S",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L41+L42",
    venue: "RUBY D",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L43+L44",
    venue: "SWETHA N G",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    venue: "AJU D",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    venue: "SELVI M",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    venue: "LAVANYA K",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    venue: "RAJARAJAN G",
    ename: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L5+L6",
    venue: "KATHIRAVAN S",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    venue: "ANIL KUMAR K",
    ename: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    venue: "KATHIRAVAN S",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L53+L54",
    venue: "RAMANI S",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    venue: "KUMARESAN A",
    ename: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    venue: "CHANDRA MOHAN B",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L57+L58",
    venue: "SIVA SHANMUGAM G",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    venue: "SWETHA N G",
    ename: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    venue: "RAJARAJAN G",
    ename: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    venue: "KUMARESAN A",
    ename: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    venue: "SIVAKUMAR. N",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L11+L12",
    venue: "RAJA S P",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L13+L14",
    venue: "CHANDRA MOHAN B",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L19+L20",
    venue: "ANAND M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    venue: "ANIL KUMAR K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    venue: "RUBY D",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L23+L24",
    venue: "SIVA SHANMUGAM G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    venue: "RAMANI S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    venue: "SELVI M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    venue: "AMUTHA PRABAKAR M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    venue: "SENDHIL KUMAR K.S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L31+L32",
    venue: "MURALI S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    venue: "AMUTHA PRABAKAR M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    venue: "SARITHA MURALI",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L39+L40",
    venue: "SENDHIL KUMAR K.S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L41+L42",
    venue: "RUBY D",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L43+L44",
    venue: "SWETHA N G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    venue: "AJU D",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    venue: "SELVI M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    venue: "LAVANYA K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    venue: "RAJARAJAN G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L5+L6",
    venue: "KATHIRAVAN S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    venue: "ANIL KUMAR K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    venue: "KATHIRAVAN S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L53+L54",
    venue: "RAMANI S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    venue: "KUMARESAN A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    venue: "CHANDRA MOHAN B",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L57+L58",
    venue: "SIVA SHANMUGAM G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    venue: "SWETHA N G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    venue: "RAJARAJAN G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    venue: "KUMARESAN A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    venue: "SIVAKUMAR. N",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "ANIL KUMAR K",
    ename: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "AMUTHA PRABAKAR M",
    ename: "SJT324",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "SWETHA N G",
    ename: "SJT311A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "AJU D",
    ename: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "MURALI S",
    ename: "SJT201",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "SENDHIL KUMAR K.S",
    ename: "SJT602A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "RAMANI S",
    ename: "SJT504",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "SIVA SHANMUGAM G",
    ename: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "LAVANYA K",
    ename: "SJT707",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "CHANDRA MOHAN B",
    ename: "SJT711",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "KATHIRAVAN S",
    ename: "SJT710",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "RAJARAJAN G",
    ename: "SJT712",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "SELVI M",
    ename: "SJT704",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "RUBY D",
    ename: "SJT715",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    venue: "KUMARESAN A",
    ename: "SJT507",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "SIVA SHANMUGAM G",
    ename: "SJT302",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "SWETHA N G",
    ename: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "KUMARESAN A",
    ename: "SJT311A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "ANAND M",
    ename: "SJT201",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "SIVAKUMAR. N",
    ename: "SJT208",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "SENDHIL KUMAR K.S",
    ename: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "RAMANI S",
    ename: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "CHANDRA MOHAN B",
    ename: "SJT223",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "ANIL KUMAR K",
    ename: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "KATHIRAVAN S",
    ename: "SJT522",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "RAJARAJAN G",
    ename: "SJT222",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "AMUTHA PRABAKAR M",
    ename: "SJT607",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "SELVI M",
    ename: "SJT602A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "RUBY D",
    ename: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    venue: "RAJA S P",
    ename: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "G1",
    venue: "SARITHA MURALI",
    ename: "SJT405",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    venue: "GAYATHRI P",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    venue: "ANURADHA D",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    venue: "ILAYARAJA V",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    venue: "DEEPIKAA S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    venue: "PARTHIBAN K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    venue: "GAYATHRI P",
    ename: "SJT308",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    venue: "ANURADHA D",
    ename: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    venue: "ILAYARAJA V",
    ename: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    venue: "DEEPIKAA S",
    ename: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    venue: "PARTHIBAN K",
    ename: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E1+TE1",
    venue: "DEEPA.K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    venue: "SARAVANAKUMAR K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    venue: "DEEPA.K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E1+TE1",
    venue: "DEEPA.K",
    ename: "SJT408",
    ctype: "ETH",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    venue: "SARAVANAKUMAR K",
    ename: "SJT707",
    ctype: "ETH",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    venue: "DEEPA.K",
    ename: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    venue: "LYDIA JANE G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    venue: "VETRISELVI T",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    venue: "RAJESHKANNAN R",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    venue: "DURGESH KUMAR",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    venue: "VETRISELVI T",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    venue: "LYDIA JANE G",
    ename: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    venue: "VETRISELVI T",
    ename: "SJT504",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    venue: "RAJESHKANNAN R",
    ename: "SJT522",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    venue: "DURGESH KUMAR",
    ename: "SJT423",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    venue: "VETRISELVI T",
    ename: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    venue: "PRABHAKARAN D",
    ename: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    venue: "CHERALATHAN K.K.",
    ename: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "F2+TF2",
    venue: "PRABHAKARAN D",
    ename: "TT208",
    ctype: "ETH",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L13+L14+L21+L22",
    venue: "AJANTA SIRCAR",
    ename: "SJT721",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L31+L32+L47+L48",
    venue: "RAJU R.L.N",
    ename: "TT116",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L7+L8+L25+L26",
    venue: "SOUMEN MUKHERJEE",
    ename: "TT306",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L9+L10+L23+L24",
    venue: "VARSHA K",
    ename: "SJT701",
    ctype: "LO",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "C2",
    venue: "GAURAV SUSHANT",
    ename: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "F1",
    venue: "SHILPI GUPTA",
    ename: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "B1",
    venue: "COUMARAN G",
    ename: "TT207",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    venue: "CALAIVANANE R",
    ename: "PRP414",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    venue: "ANTOINETTE DANIEL",
    ename: "SMV319",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "G1",
    venue: "MALATHY O",
    ename: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "HUM1021",
    cname: "HUM1021 - Ethics and Values",
    slot: "F1",
    venue: "TONY P JOSE",
    ename: "SJT107",
    ctype: "TH",
  },
  {
    crcode: "JAP1001",
    cname: "JAP1001 - Japanese for Beginners",
    slot: "G1",
    venue: "KHANJAN",
    ename: "SJT606",
    ctype: "TH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L3+L4",
    venue: "SHAIK KALEEMULLA",
    ename: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L31+L32",
    venue: "VIJAYA CHAMUNDEESWARI S P",
    ename: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L33+L34",
    venue: "SHAIK KALEEMULLA",
    ename: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    venue: "SHAIK KALEEMULLA",
    ename: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    venue: "VIJAYA CHAMUNDEESWARI S P",
    ename: "TT621",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E2+TE2",
    venue: "SHAIK KALEEMULLA",
    ename: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA1",
    venue: "BHASKAR SEN GUPTA",
    ename: "SJT222",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA1",
    venue: "SANDEEP CHAKRABORTY",
    ename: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA2",
    venue: "ARPAN KUMAR NAYAK",
    ename: "SJT205",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    venue: "ANUSHA P T",
    ename: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    venue: "BALAKRISHNAN S",
    ename: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    venue: "LEEMA ROSE VIANNIE",
    ename: "SJT305",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB2",
    venue: "LAXMI NARAYAN TRIPATHI",
    ename: "SJT202",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC1",
    venue: "TARUN",
    ename: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC1",
    venue: "SURESH KUMAR VANDRANGI",
    ename: "SJT404",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC2",
    venue: "UMA MAHENDRA KUMAR K",
    ename: "SJT205",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC2",
    venue: "SATHYA SWAROOP N.R",
    ename: "TT404",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD1",
    venue: "KURAGANTI VASU",
    ename: "SJT503",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD1",
    venue: "DHANOJ GUPTA",
    ename: "SJT504",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD1",
    venue: "JAGADEESH SURE",
    ename: "SJT505",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD2",
    venue: "SAMIR RANJAN MEHER",
    ename: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    venue: "ABHISHEK KUMAR SINGH",
    ename: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    venue: "DHRITIMAN GUPTA",
    ename: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TF1",
    venue: "RAVI SHANKER BABU",
    ename: "SJT201",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    venue: "SHOBANA M K",
    ename: "SJT205",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    venue: "SUDIPTA SAHA",
    ename: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    venue: "USHA RANI M",
    ename: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "FACE (APT)",
    ename: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "FACE (APT)",
    ename: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "FACE (APT)",
    ename: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    venue: "FACE (APT)",
    ename: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "TT530A",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "TT524",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    venue: "FACE (APT)",
    ename: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SMV101",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "SMART (APT)",
    ename: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "FACE (APT)",
    ename: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    venue: "ETHNUS (APT)",
    ename: "SMV102",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "SMV320",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "FACE (APT)",
    ename: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "FACE (APT)",
    ename: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "ETHNUS (APT)",
    ename: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "FACE (APT)",
    ename: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "FACE (APT)",
    ename: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    venue: "SMART (APT)",
    ename: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B1+TB1",
    venue: "ANBARASI M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B2+TB2",
    venue: "JASMIN T. JOSE",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B1+TB1",
    venue: "ANBARASI M",
    ename: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B2+TB2",
    venue: "JASMIN T. JOSE",
    ename: "SJT301",
    ctype: "ETH",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L27+L28",
    venue: "BHULAKSHMI BONTHU",
    ename: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L37+L38",
    venue: "BHULAKSHMI BONTHU",
    ename: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L27+L28",
    venue: "BHULAKSHMI BONTHU",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L37+L38",
    venue: "BHULAKSHMI BONTHU",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "A1",
    venue: "BHULAKSHMI BONTHU",
    ename: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "A2",
    venue: "BHULAKSHMI BONTHU",
    ename: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    venue: "ANANDA KUMAR S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    venue: "KALAAVATHI B",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A2+TA2",
    venue: "ILAYARAJA V",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    venue: "ANANDA KUMAR S",
    ename: "SJT409",
    ctype: "ETH",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    venue: "KALAAVATHI B",
    ename: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A2+TA2",
    venue: "ILAYARAJA V",
    ename: "SJT409",
    ctype: "ETH",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C1+TC1",
    venue: "BAIJU B V",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C2+TC2",
    venue: "BAIJU B V",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C1+TC1",
    venue: "BAIJU B V",
    ename: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C2+TC2",
    venue: "BAIJU B V",
    ename: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "BCT4003",
    cname: "BCT4003 - Wearable Computing",
    slot: "E1+TE1",
    venue: "JAFAR ALI IBRAHIM S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT4003",
    cname: "BCT4003 - Wearable Computing",
    slot: "E1+TE1",
    venue: "JAFAR ALI IBRAHIM S",
    ename: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "A2+TA2",
    venue: "SURESH P.K",
    ename: "SMV217",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "B2+TB2",
    venue: "RAHUL VASHISHTH",
    ename: "MB225",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "E1+TE1",
    venue: "SURESH P.K",
    ename: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BKT1001",
    cname: "BKT1001 - Blockchain and Distributed Ledger Technology",
    slot: "L33+L34",
    venue: "CHANDRU VIGNESH S",
    ename: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "BKT1001",
    cname: "BKT1001 - Blockchain and Distributed Ledger Technology",
    slot: "A1+TA1",
    venue: "CHANDRU VIGNESH S",
    ename: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "BKT2001",
    cname: "BKT2001 - Cryptography  and Information Security",
    slot: "L9+L10",
    venue: "RISHIN HALDAR",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "BKT2001",
    cname: "BKT2001 - Cryptography  and Information Security",
    slot: "B2+TB2",
    venue: "RISHIN HALDAR",
    ename: "SJT323",
    ctype: "ETH",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "L11+L12",
    venue: "NIHA K",
    ename: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "L39+L40",
    venue: "SWARNALATHA P",
    ename: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "A1+TA1",
    venue: "SWARNALATHA P",
    ename: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "A2+TA2",
    venue: "NIHA K",
    ename: "SJT501",
    ctype: "ETH",
  },
  {
    crcode: "BKT4001",
    cname: "BKT4001 - Blockchain Ecosystem",
    slot: "G1+TG1",
    venue: "UMADEVI K S",
    ename: "SJT501",
    ctype: "TH",
  },
  {
    crcode: "BKT4005",
    cname: "BKT4005 - Blockchain Architecture Design and Use Cases",
    slot: "C1+TC1",
    venue: "MADHU VISWANATHAM V",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BKT4005",
    cname: "BKT4005 - Blockchain Architecture Design and Use Cases",
    slot: "C1+TC1",
    venue: "MADHU VISWANATHAM V",
    ename: "SJT715",
    ctype: "ETH",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E1+TE1",
    venue: "GOPICHAND G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E2+TE2",
    venue: "GOPICHAND G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E1+TE1",
    venue: "GOPICHAND G",
    ename: "SJT707",
    ctype: "ETH",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E2+TE2",
    venue: "GOPICHAND G",
    ename: "SJT702",
    ctype: "ETH",
  },
  {
    crcode: "CBS1008",
    cname: "CBS1008 - Operations Research",
    slot: "L25+L26",
    venue: "SIVARAJ R",
    ename: "SMVG16",
    ctype: "ELA",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    venue: "NAGAMALLESWARA RAO K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    venue: "MAHESH GANESAPILLAI",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    venue: "NAGAMALLESWARA RAO K",
    ename: "SMV104",
    ctype: "ETH",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    venue: "MAHESH GANESAPILLAI",
    ename: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "CHE1009",
    cname: "CHE1009 - Biochemical Engineering",
    slot: "E1+TE1",
    venue: "ARUNA SINGH",
    ename: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1011",
    cname: "CHE1011 - Optimization of Chemical Processes",
    slot: "F1+TF1",
    venue: "DHARMENDRA KUMAR BAL",
    ename: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE1013",
    cname: "CHE1013 - Natural Gas Engineering",
    slot: "G1+TG1",
    venue: "ASLAM ABDULLAH M",
    ename: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    venue: "ARUNA SINGH",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    venue: "ARUNA SINGH",
    ename: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "CHE1018",
    cname: "CHE1018 - Membrane Separations Technology",
    slot: "G1+TG1",
    venue: "THOMAS THEODORE",
    ename: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1019",
    cname: "CHE1019 - Polymer Technology",
    slot: "D1+TD1",
    venue: "AABID HUSSAIN SHAIK",
    ename: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE2003",
    cname: "CHE2003 - Chemical Product Design",
    slot: "G2+TG2",
    venue: "SHANKAR RAMAN DHANUSHKODI",
    ename: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "A2",
    venue: "KHANA BAIDYA",
    ename: "TT104",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B1",
    venue: "KHANA BAIDYA",
    ename: "TT208",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B2",
    venue: "KHANA BAIDYA",
    ename: "TT206",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "D1",
    venue: "KHANA BAIDYA",
    ename: "TT205",
    ctype: "TH",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    venue: "VASANTHA KUMAR S",
    ename: "CTSG05",
    ctype: "ELA",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    venue: "VASANTHA KUMAR S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "F1+TF1",
    venue: "VASANTHA KUMAR S",
    ename: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "L53+L54",
    venue: "MALATHY  J",
    ename: "CTSG04",
    ctype: "ELA",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "A1+TA1",
    venue: "MALATHY  J",
    ename: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "B2+TB2",
    venue: "GANAPATHY G.P",
    ename: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C1+TC1",
    venue: "ARUNAVA RAY",
    ename: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C2+TC2",
    venue: "VAANI N",
    ename: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1013",
    cname: "CLE1013 - Environmental Impact Assessment",
    slot: "A2+TA2",
    venue: "SRIMURUGANANDAM B",
    ename: "CDMM404",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "C2+TC2",
    venue: "SASANKA BHUSHAN PULIPATI",
    ename: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "D1+TD1",
    venue: "JAYAPRAKASH J",
    ename: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    venue: "SUGANYA OM",
    ename: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    venue: "SUGANYA OM",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "TB1",
    venue: "SUGANYA OM",
    ename: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE2003",
    cname: "CLE2003 - Structural Analysis",
    slot: "C1+TC1+V2",
    venue: "SANTHI A.S",
    ename: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    venue: "DILLIP KUMAR BARIK",
    ename: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    venue: "DILLIP KUMAR BARIK",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "B1",
    venue: "DILLIP KUMAR BARIK",
    ename: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2008",
    cname: "CLE2008 - Construction Planning and Management",
    slot: "C1+TC1",
    venue: "JOHN SUSHIL PACKIARAJ",
    ename: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    venue: "MUTHUKUMAR M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    venue: "MUTHUKUMAR M",
    ename: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    venue: "SHANTHA KUMAR S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    venue: "SHANTHA KUMAR S",
    ename: "CDMM105",
    ctype: "ETH",
  },
  {
    crcode: "CLE2022",
    cname: "CLE2022 - Economics and Business Finance for Civil Engineers",
    slot: "B2+TB2",
    venue: "PRASANNA VENKATESAN R",
    ename: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "L59+L60",
    venue: "SURENDAR M",
    ename: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "B1",
    venue: "SURENDAR M",
    ename: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE3001",
    cname: "CLE3001 - Quantity Surveying and Estimating",
    slot: "G2",
    venue: "SOFI A",
    ename: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "L39+L40",
    venue: "HAREESH M",
    ename: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "E1+TE1+V5",
    venue: "HAREESH M",
    ename: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    venue: "VASANTHA KUMAR S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    venue: "VASANTHA KUMAR S",
    ename: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "L29+L30",
    venue: "THIRUMALINI S",
    ename: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "D2+TD2",
    venue: "THIRUMALINI S",
    ename: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE2008",
    cname: "CSE2008 - Network Security",
    slot: "G1+TG1",
    venue: "KARTHIK G M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2008",
    cname: "CSE2008 - Network Security",
    slot: "G1+TG1",
    venue: "KARTHIK G M",
    ename: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "L19+L20",
    venue: "SASIKALA .R",
    ename: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "L49+L50",
    venue: "SHARMILA BANU K",
    ename: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "E1",
    venue: "SHARMILA BANU K",
    ename: "SJT422",
    ctype: "ETH",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "E2",
    venue: "SASIKALA .R",
    ename: "SJT422",
    ctype: "ETH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "D2+TD2",
    venue: "KRISHNARAJ N",
    ename: "SJT702",
    ctype: "TH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "G1+TG1",
    venue: "SANTHI H",
    ename: "SJT407",
    ctype: "TH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "G1+TG1",
    venue: "JAYASHREE J",
    ename: "SJT409",
    ctype: "TH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "G2+TG2",
    venue: "VIJAYASHREE J",
    ename: "SJT403",
    ctype: "TH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L11+L12",
    venue: "KANNAN A",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L21+L22",
    venue: "ARUP GHOSH",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L31+L32",
    venue: "ARUP GHOSH",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L49+L50",
    venue: "ILANTHENRAL K P S K",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L57+L58",
    venue: "KANNAN A",
    ename: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E1",
    venue: "ILANTHENRAL K P S K",
    ename: "SJT507",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E1",
    venue: "KANNAN A",
    ename: "SJT617",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E1",
    venue: "ARUP GHOSH",
    ename: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E2",
    venue: "KANNAN A",
    ename: "SJT407",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E2",
    venue: "ARUP GHOSH",
    ename: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L11+L12",
    venue: "SURESH P",
    ename: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L19+L20",
    venue: "ALKHA MOHAN",
    ename: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L33+L34",
    venue: "ALKHA MOHAN",
    ename: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L39+L40",
    venue: "ABDUL GAFFAR H",
    ename: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C1+TC1",
    venue: "ABDUL GAFFAR H",
    ename: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C1+TC1",
    venue: "ALKHA MOHAN",
    ename: "SJT604",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C2+TC2",
    venue: "ALKHA MOHAN",
    ename: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C2+TC2",
    venue: "SURESH P",
    ename: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    venue: "NALINI N",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    venue: "GUNAVATHI C",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    venue: "VASANTHA W B",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    venue: "NALINI N",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    venue: "GUNAVATHI C",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    venue: "NALINI N",
    ename: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    venue: "GUNAVATHI C",
    ename: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    venue: "VASANTHA W B",
    ename: "SJT405",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    venue: "NALINI N",
    ename: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    venue: "GUNAVATHI C",
    ename: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    venue: "VIDHYAPATHI C.M",
    ename: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    venue: "VIDHYAPATHI C.M",
    ename: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    venue: "VIDHYAPATHI C.M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    venue: "VIDHYAPATHI C.M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1018",
    cname: "EEE1018 - Nanotechnology Fundamentals and its Applications",
    slot: "F1+TF1",
    venue: "PARTHA SHARATHI MALLICK",
    ename: "TT404",
    ctype: "TH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    venue: "RANI C",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    venue: "RAGHUNATHAN T",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    venue: "RANI C",
    ename: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    venue: "RAGHUNATHAN T",
    ename: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L11+L12",
    venue: "KOWSALYA M",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L15+L16",
    venue: "RAVI K",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L21+L22",
    venue: "SATYAJIT DAS",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L25+L26",
    venue: "SATYAJIT MOHANTY",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L31+L32",
    venue: "GOKULAKRISHNAN G",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L33+L34",
    venue: "VIJAYAKUMAR D",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L39+L40",
    venue: "SARAVANAKUMAR R",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L43+L44",
    venue: "SATYAJIT MOHANTY",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L55+L56",
    venue: "SATYAJIT DAS",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L9+L10",
    venue: "CHITRA A",
    ename: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE2001",
    cname: "EEE2001 - Network Theory",
    slot: "C1+TC1",
    venue: "PALANISAMY K",
    ename: "TT104",
    ctype: "TH",
  },
  {
    crcode: "EEE2003",
    cname: "EEE2003 - Electromechanical Energy Conversion",
    slot: "C1+TC1",
    venue: "MAGESHVARAN R",
    ename: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    venue: "JAGANATHA PANDIAN B",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    venue: "JAGANATHA PANDIAN B",
    ename: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L33+L34",
    venue: "INDRAGANDHI V",
    ename: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L37+L38",
    venue: "INDRAGANDHI V",
    ename: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "B1+TB1",
    venue: "INDRAGANDHI V",
    ename: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    venue: "GOKULAKRISHNAN G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    venue: "GOKULAKRISHNAN G",
    ename: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "HIN1003",
    cname: "HIN1003 - Prathamik Hindi",
    slot: "C1+TC1",
    venue: "JAYALAKSHMI K",
    ename: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "L15+L16",
    venue: "JAYALAKSHMI K",
    ename: "TT107",
    ctype: "ELA",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "B1",
    venue: "JAYALAKSHMI K",
    ename: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "B2+TB2",
    venue: "RASHMI M",
    ename: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "F1+TF1",
    venue: "BALAMURUGAN J",
    ename: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    venue: "BHUVANESWARI M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    venue: "BHUVANESWARI M",
    ename: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    venue: "BANGALORE MORARJI",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    venue: "BANGALORE MORARJI",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    venue: "BANGALORE MORARJI",
    ename: "SMVG21",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    venue: "BANGALORE MORARJI",
    ename: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    venue: "SUDHEER C.V.",
    ename: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    venue: "SUDHEER C.V.",
    ename: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    venue: "SUDHEER C.V.",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    venue: "SUDHEER C.V.",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TB2",
    venue: "SUDHEER C.V.",
    ename: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TG1",
    venue: "SUDHEER C.V.",
    ename: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1033",
    cname: "HUM1033 - Micro Economics",
    slot: "D1+TD1",
    venue: "NILAVATHY K",
    ename: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1038",
    cname: "HUM1038 - International Economics",
    slot: "E2+TE2",
    venue: "ALLI P",
    ename: "TT423",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "B1+TB1",
    venue: "RASHMI M",
    ename: "SJT323",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "C2+TC2",
    venue: "PRABAKAR S",
    ename: "SMV101",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "D2+TD2",
    venue: "BALAMURUGAN J",
    ename: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "F1+TF1",
    venue: "PRABAKAR S",
    ename: "MB226",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "G2+TG2",
    venue: "KUBENDRAN A",
    ename: "TT305",
    ctype: "TH",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    venue: "KUBENDRAN A",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    venue: "KUBENDRAN A",
    ename: "TT305",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L55+L56",
    venue: "NAVIN KUMAR",
    ename: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L57+L58",
    venue: "BHUVANESWARI M",
    ename: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "E1",
    venue: "BHUVANESWARI M",
    ename: "TT413",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "F2",
    venue: "NAVIN KUMAR",
    ename: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITE4099",
    cname: "ITE4099 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITL1002",
    cname: "ITL1002 - Italiano Di Base",
    slot: "G1",
    venue: "JACOPO MOSESSO",
    ename: "SJT604",
    ctype: "TH",
  },
  {
    crcode: "MEE1001",
    cname: "MEE1001 - Engineering Drawing",
    slot: "L31+L32+L37+L38",
    venue: "CHINMAYA SAHU",
    ename: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE1004",
    cname: "MEE1004 - Fluid Mechanics",
    slot: "L5+L6",
    venue: "ABINASH MOHANTA",
    ename: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1008",
    cname: "MEE1008 - MEMS",
    slot: "B2+TB2",
    venue: "RAGHURAMAN D R S",
    ename: "MB312",
    ctype: "TH",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    venue: "SRIDHAR V G",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    venue: "SRIDHAR V G",
    ename: "MB312",
    ctype: "ETH",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L23+L24",
    venue: "GAYATHRI V",
    ename: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L5+L6",
    venue: "GAYATHRI V",
    ename: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "D2+TD2+V6",
    venue: "GAYATHRI V",
    ename: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE1027",
    cname: "MEE1027 - Instrumentation and Control Engineering",
    slot: "L37+L38",
    venue: "SOVAN SUNDAR DASGUPTA",
    ename: "GDN137",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "L39+L40",
    venue: "VIJESH V JOSHI",
    ename: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "G1+TG1",
    venue: "VIJESH V JOSHI",
    ename: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "L57+L58",
    venue: "GUNDABATTINI EDISON",
    ename: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "E1+TE1+V7",
    venue: "GUNDABATTINI EDISON",
    ename: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L3+L4",
    venue: "RAMMOHAN A",
    ename: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L9+L10",
    venue: "RAMMOHAN A",
    ename: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "E2+TE2",
    venue: "RAMMOHAN A",
    ename: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    venue: "SENTHIL KUMAR S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    venue: "SENTHIL KUMAR S",
    ename: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    venue: "ASOKAN M.A",
    ename: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    venue: "GAURAV GUPTA",
    ename: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L15+L16",
    venue: "ASOKAN M.A",
    ename: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L27+L28",
    venue: "GAURAV GUPTA",
    ename: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    venue: "SABOOR S",
    ename: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    venue: "SENTHIL KUMAR M",
    ename: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L37+L38",
    venue: "SABOOR S",
    ename: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L51+L52",
    venue: "SENTHIL KUMAR M",
    ename: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L19+L20",
    venue: "NAVEEN J",
    ename: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L27+L28",
    venue: "MOHANKUMAR K V",
    ename: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L3+L4",
    venue: "NAVEEN J",
    ename: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L35+L36",
    venue: "SRIDHARAN K",
    ename: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L39+L40",
    venue: "SRIDHARAN K",
    ename: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L45+L46",
    venue: "MANIKANDAN M",
    ename: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L49+L50",
    venue: "MANIKANDAN M",
    ename: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L7+L8",
    venue: "MOHANKUMAR K V",
    ename: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    venue: "SRIDHARAN K",
    ename: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    venue: "MANIKANDAN M",
    ename: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    venue: "NAVEEN J",
    ename: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    venue: "MOHANKUMAR K V",
    ename: "MB225",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L11+L12",
    venue: "TAPANO KUMAR HOTTA",
    ename: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L15+L16",
    venue: "GOVINDHA RASU N",
    ename: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L3+L4",
    venue: "TAPANO KUMAR HOTTA",
    ename: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L35+L36",
    venue: "ARUNA KUMAR BEHURA",
    ename: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L43+L44",
    venue: "ARUNA KUMAR BEHURA",
    ename: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L45+L46",
    venue: "NISHANT TIWARI",
    ename: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L53+L54",
    venue: "NISHANT TIWARI",
    ename: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L9+L10",
    venue: "GOVINDHA RASU N",
    ename: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    venue: "GOVINDHA RASU N",
    ename: "GDNG08A",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    venue: "TAPANO KUMAR HOTTA",
    ename: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    venue: "ARUNA KUMAR BEHURA",
    ename: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    venue: "NISHANT TIWARI",
    ename: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L11+L12",
    venue: "JAMBESWAR SAHU",
    ename: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L41+L42",
    venue: "VENKATESHWARLU B",
    ename: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L53+L54",
    venue: "ANOJ GIRI",
    ename: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    venue: "VENKATESHWARLU B",
    ename: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    venue: "ANOJ GIRI",
    ename: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G2",
    venue: "JAMBESWAR SAHU",
    ename: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L11+L12+L21+L22",
    venue: "RAJKUMAR E",
    ename: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L31+L32+L39+L40",
    venue: "RAJKUMAR E",
    ename: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B1",
    venue: "RAJKUMAR E",
    ename: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B2",
    venue: "RAJKUMAR E",
    ename: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    venue: "GUNJI BALA MURALI",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    venue: "GUNJI BALA MURALI",
    ename: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2009",
    cname: "MEE2009 - Tribology",
    slot: "E1+TE1+V7",
    venue: "ARULVEL S",
    ename: "GDN129",
    ctype: "TH",
  },
  {
    crcode: "MEE2010",
    cname: "MEE2010 - Design of Composite Materials",
    slot: "D2+TD2+V6",
    venue: "SK ARIFUL RAHAMAN",
    ename: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    venue: "RIJESH M",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    venue: "RIJESH M",
    ename: "MB226",
    ctype: "ETH",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    venue: "CHINMAYA PRASAD MOHANTY",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    venue: "CHINMAYA PRASAD MOHANTY",
    ename: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L15+L16",
    venue: "RAJAMURUGAN G",
    ename: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L3+L4",
    venue: "RAJAMURUGAN G",
    ename: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "F2+TF2",
    venue: "RAJAMURUGAN G",
    ename: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    venue: "RAJA K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    venue: "RAJA K",
    ename: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2020",
    cname: "MEE2020 - Metal Forming Theory and Practice",
    slot: "G2+TG2",
    venue: "DEVASRI FULORIA",
    ename: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE2022",
    cname: "MEE2022 - Power Plant Engineering",
    slot: "B1+TB1",
    venue: "ASOKAN M.A",
    ename: "MB211",
    ctype: "TH",
  },
  {
    crcode: "MEE2023",
    cname: "MEE2023 - Gas Dynamics and Jet Propulsion",
    slot: "E1+TE1+V7",
    venue: "HUMRUTHA G",
    ename: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    venue: "YAGNA S MUKKAMALA",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    venue: "YAGNA S MUKKAMALA",
    ename: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2031",
    cname: "MEE2031 - Theory of Metal Cutting and Forming",
    slot: "F1+TF1",
    venue: "PANDIVELAN C",
    ename: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2032",
    cname: "MEE2032 - Kinematics and Dynamics of Machinery",
    slot: "D2+TD2+V6",
    venue: "NIRANJANA BEHERA",
    ename: "MB225",
    ctype: "TH",
  },
  {
    crcode: "MEE2038",
    cname: "MEE2038 - Thermal and Heat Transfer",
    slot: "A1+TA1+V1",
    venue: "DEEPAKKUMAR R",
    ename: "MB224",
    ctype: "TH",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    venue: "BASKAR P",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    venue: "BASKAR P",
    ename: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2050",
    cname: "MEE2050 - Vehicle Dynamics",
    slot: "E1+TE1+V7",
    venue: "SAKTHIVEL P",
    ename: "MB309",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    venue: "NARENDIRANATH BABU T",
    ename: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    venue: "AHANKARI SANDEEP SURESHRAO",
    ename: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "B2+TB2+V4",
    venue: "VINOTH JEBARAJ A",
    ename: "MB214",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "D2+TD2+V6",
    venue: "GOKUL KUMAR K",
    ename: "MB229",
    ctype: "TH",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "L33+L34",
    venue: "PADMANABHAN K",
    ename: "MB110",
    ctype: "ELA",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "E1+TE1+V7",
    venue: "PADMANABHAN K",
    ename: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3004",
    cname: "MEE3004 - Internal Combustion Engines",
    slot: "B1+TB1",
    venue: "NANTHA GOPAL K",
    ename: "GDNG08",
    ctype: "TH",
  },
  {
    crcode: "MEE3005",
    cname: "MEE3005 - Refrigeration and Airconditioning",
    slot: "D2+TD2+TDD2+V6",
    venue: "RAMESH KUMAR C",
    ename: "MB226",
    ctype: "TH",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L19+L20",
    venue: "BIBHUTI BHUSAN SAHOO",
    ename: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L9+L10",
    venue: "BIBHUTI BHUSAN SAHOO",
    ename: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "B2",
    venue: "BIBHUTI BHUSAN SAHOO",
    ename: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "L41+L42",
    venue: "DEEPA A",
    ename: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "D1",
    venue: "DEEPA A",
    ename: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L39+L40",
    venue: "NATARAJ G",
    ename: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L41+L42",
    venue: "NATARAJ G",
    ename: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "G1+TG1",
    venue: "NATARAJ G",
    ename: "MB211",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    venue: "RENOLD ELSEN S",
    ename: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    venue: "SRINIVASAN NARAYANAN",
    ename: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    venue: "VENKATESAN K",
    ename: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    venue: "OYYARAVELU R",
    ename: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    venue: "DEVENDIRAN S",
    ename: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    venue: "CHIRANJEEVI C",
    ename: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    venue: "RAJA SEKHAR Y",
    ename: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    venue: "BIKASH ROUTH",
    ename: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    venue: "RENOLD ELSEN S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    venue: "SRINIVASAN NARAYANAN",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    venue: "VENKATESAN K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    venue: "OYYARAVELU R",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    venue: "DEVENDIRAN S",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    venue: "CHIRANJEEVI C",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    venue: "RAJA SEKHAR Y",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    venue: "BIKASH ROUTH",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    venue: "OYYARAVELU R",
    ename: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    venue: "DEVENDIRAN S",
    ename: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    venue: "CHIRANJEEVI C",
    ename: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    venue: "RAJA SEKHAR Y",
    ename: "MB213",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    venue: "VENKATESAN K",
    ename: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    venue: "BIKASH ROUTH",
    ename: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    venue: "RENOLD ELSEN S",
    ename: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    venue: "SRINIVASAN NARAYANAN",
    ename: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    venue: "ANANDAVEL K",
    ename: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    venue: "ANANDAVEL K",
    ename: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "G2",
    venue: "ANANDAVEL K",
    ename: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L19+L20",
    venue: "KEYUR BHANUPRASAD JOSHI",
    ename: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L3+L4",
    venue: "KEYUR BHANUPRASAD JOSHI",
    ename: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "D2+TD2+V6",
    venue: "KEYUR BHANUPRASAD JOSHI",
    ename: "MB227",
    ctype: "ETH",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    venue: "PLACEMENT AND TRAINING",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "SET5003",
    cname: "SET5003 - Science",
    slot: "Engineering and Technology Project - III",
    venue: "ACADEMICS",
    ename: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ENG1000",
    cname: "ENG1000 - Foundation English - I",
    slot: "L11+L12+L27+L28",
    venue: "SRINIVASAN R",
    ename: "TT432",
    ctype: "LO",
  },
  {
    crcode: "ENG2000",
    cname: "ENG2000 - Foundation English - II",
    slot: "L33+L34+L47+L48",
    venue: "SANKAR G",
    ename: "SJT721",
    ctype: "LO",
  },

  {
    crcode: "CSE1007",
    cname: "CSE1007 - Java Programming",
    slot: "C1+TC1",
    ename: "PRIYA G",
    venue: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "L19+L20",
    ename: "SASIKALA .R",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "L49+L50",
    ename: "SHARMILA BANU K",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "E1",
    ename: "SHARMILA BANU K",
    venue: "SJT422",
    ctype: "ETH",
  },
  {
    crcode: "CSE2010",
    cname: "CSE2010 - Advanced C Programming",
    slot: "E2",
    ename: "SASIKALA .R",
    venue: "SJT422",
    ctype: "ETH",
  },
  {
    crcode: "CSE2013",
    cname: "CSE2013 - Theory of Computation",
    slot: "F1+TF1",
    ename: "KUMAR K",
    venue: "PRPG21",
    ctype: "TH",
  },
  {
    crcode: "CSE2013",
    cname: "CSE2013 - Theory of Computation",
    slot: "F2+TF2",
    ename: "SATHIYA KUMAR C",
    venue: "SJT626",
    ctype: "TH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L15+L16",
    ename: "SIVAPRAKASH S",
    venue: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L19+L20",
    ename: "DEEPSUBHRA GUHA ROY",
    venue: "SJTG17",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L29+L30",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L33+L34",
    ename: "DEEPSUBHRA GUHA ROY",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L39+L40",
    ename: "NAVEENKUMAR J",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L45+L46",
    ename: "CHELLATAMILAN T",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "L51+L52",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A1+TA1",
    ename: "CHELLATAMILAN T",
    venue: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A1+TA1",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT407",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A1+TA1",
    ename: "DEEPSUBHRA GUHA ROY",
    venue: "SJT501",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A2+TA2",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "A2+TA2",
    ename: "DEEPSUBHRA GUHA ROY",
    venue: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "D2+TD2",
    ename: "SIVAPRAKASH S",
    venue: "SJT501",
    ctype: "ETH",
  },
  {
    crcode: "CSE3035",
    cname: "CSE3035 - Principles of Cloud Computing",
    slot: "G1+TG1",
    ename: "NAVEENKUMAR J",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "D2+TD2",
    ename: "KRISHNARAJ N",
    venue: "SJT702",
    ctype: "TH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "G1+TG1",
    ename: "SANTHI H",
    venue: "SJT407",
    ctype: "TH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "G1+TG1",
    ename: "JAYASHREE J",
    venue: "SJT409",
    ctype: "TH",
  },
  {
    crcode: "CSE3044",
    cname: "CSE3044 - Cryptography and Network Security",
    slot: "G2+TG2",
    ename: "VIJAYASHREE J",
    venue: "SJT403",
    ctype: "TH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L11+L12",
    ename: "KANNAN A",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L21+L22",
    ename: "ARUP GHOSH",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L31+L32",
    ename: "ARUP GHOSH",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L49+L50",
    ename: "ILANTHENRAL K P S K",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "L57+L58",
    ename: "KANNAN A",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E1",
    ename: "ILANTHENRAL K P S K",
    venue: "SJT507",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E1",
    ename: "KANNAN A",
    venue: "SJT617",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E1",
    ename: "ARUP GHOSH",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E2",
    ename: "KANNAN A",
    venue: "SJT407",
    ctype: "ETH",
  },
  {
    crcode: "CSE3045",
    cname: "CSE3045 - Mathematical Modeling for Data Science",
    slot: "E2",
    ename: "ARUP GHOSH",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L11+L12",
    ename: "SURESH P",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L19+L20",
    ename: "ALKHA MOHAN",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L33+L34",
    ename: "ALKHA MOHAN",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "L39+L40",
    ename: "ABDUL GAFFAR H",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C1+TC1",
    ename: "ABDUL GAFFAR H",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C1+TC1",
    ename: "ALKHA MOHAN",
    venue: "SJT604",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C2+TC2",
    ename: "ALKHA MOHAN",
    venue: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "CSE3046",
    cname: "CSE3046 - Programming for Data Science",
    slot: "C2+TC2",
    ename: "SURESH P",
    venue: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "NALINI N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "GUNAVATHI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "VASANTHA W B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    ename: "NALINI N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    ename: "GUNAVATHI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "NALINI N",
    venue: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "GUNAVATHI C",
    venue: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B1",
    ename: "VASANTHA W B",
    venue: "SJT405",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    ename: "NALINI N",
    venue: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3047",
    cname: "CSE3047 - Predictive Analytics",
    slot: "B2",
    ename: "GUNAVATHI C",
    venue: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "L47+L48",
    ename: "SANKARDOSS V",
    venue: "TT433",
    ctype: "ELA",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "E1",
    ename: "SANKARDOSS V",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A1+TA1",
    ename: "MANOOV R",
    venue: "SJT422",
    ctype: "TH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A1+TA1",
    ename: "POORNIMA N",
    venue: "SJT423",
    ctype: "TH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A2+TA2",
    ename: "MANOOV R",
    venue: "SJT422",
    ctype: "TH",
  },
  {
    crcode: "CSE1006",
    cname: "CSE1006 - Blockchain and Cryptocurrency Technologies",
    slot: "A2+TA2",
    ename: "POORNIMA N",
    venue: "SJT415",
    ctype: "TH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L13+L14",
    ename: "ANTO S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L15+L16",
    ename: "SWATHI J.N",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L25+L26",
    ename: "SHASHANK MOULI SATAPATHY",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L29+L30",
    ename: "PREETHA EVANGELINE D",
    venue: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L3+L4",
    ename: "SIVAPRAKASH S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L31+L32",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L33+L34",
    ename: "NAVEENKUMAR J",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L37+L38",
    ename: "ANAND BIHARI",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L39+L40",
    ename: "PREETHA EVANGELINE D",
    venue: "SJT122",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L41+L42",
    ename: "SIVAPRAKASH S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L43+L44",
    ename: "AKILA VICTOR",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L45+L46",
    ename: "MARGRET ANOUNCIA S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L5+L6",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L55+L56",
    ename: "ANTO S",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L9+L10",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L13+L14",
    ename: "ANTO S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L15+L16",
    ename: "SWATHI J.N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L25+L26",
    ename: "SHASHANK MOULI SATAPATHY",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L29+L30",
    ename: "PREETHA EVANGELINE D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L3+L4",
    ename: "SIVAPRAKASH S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L31+L32",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L33+L34",
    ename: "NAVEENKUMAR J",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L37+L38",
    ename: "ANAND BIHARI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L39+L40",
    ename: "PREETHA EVANGELINE D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L41+L42",
    ename: "SIVAPRAKASH S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L43+L44",
    ename: "AKILA VICTOR",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L45+L46",
    ename: "MARGRET ANOUNCIA S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L5+L6",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L55+L56",
    ename: "ANTO S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "L9+L10",
    ename: "PEREPI RAJARAJESWARI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "MARGRET ANOUNCIA S",
    venue: "SJT303",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "SJT308",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "AKILA VICTOR",
    venue: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "ANTO S",
    venue: "SJT305",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "ANAND BIHARI",
    venue: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "NAVEENKUMAR J",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B1",
    ename: "SIVAPRAKASH S",
    venue: "SJT402",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "SWATHI J.N",
    venue: "SJT305",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "USHUS ELIZEBETH ZACHARIAH",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "SHASHANK MOULI SATAPATHY",
    venue: "SJT308",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "ANTO S",
    venue: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "PEREPI RAJARAJESWARI",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "B2",
    ename: "SIVAPRAKASH S",
    venue: "SJT402",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "D1",
    ename: "PREETHA EVANGELINE D",
    venue: "SJT702",
    ctype: "ETH",
  },
  {
    crcode: "CSE3001",
    cname: "CSE3001 - Software Engineering",
    slot: "D2",
    ename: "PREETHA EVANGELINE D",
    venue: "SJT703",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    ename: "KRISHNAMOORTHY A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    ename: "DHEEBA J",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A2+TA2",
    ename: "DHEEBA J",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "E2+TE2",
    ename: "SENTHILNATHAN",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A1+TA1",
    ename: "DHEEBA J",
    venue: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "A2+TA2",
    ename: "DHEEBA J",
    venue: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3009",
    cname: "CSE3009 - Internet of Things",
    slot: "E2+TE2",
    ename: "SENTHILNATHAN",
    venue: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "GAYATHRI P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "ANURADHA D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "ILAYARAJA V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    ename: "DEEPIKAA S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    ename: "PARTHIBAN K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "GAYATHRI P",
    venue: "SJT308",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "ANURADHA D",
    venue: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C1+TC1",
    ename: "ILAYARAJA V",
    venue: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    ename: "DEEPIKAA S",
    venue: "SJT311",
    ctype: "ETH",
  },
  {
    crcode: "CSE4003",
    cname: "CSE4003 - Cyber Security",
    slot: "C2+TC2",
    ename: "PARTHIBAN K",
    venue: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "LYDIA JANE G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "VETRISELVI T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "RAJESHKANNAN R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    ename: "DURGESH KUMAR",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    ename: "VETRISELVI T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "LYDIA JANE G",
    venue: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "VETRISELVI T",
    venue: "SJT504",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B1+TB1",
    ename: "RAJESHKANNAN R",
    venue: "SJT522",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    ename: "DURGESH KUMAR",
    venue: "SJT423",
    ctype: "ETH",
  },
  {
    crcode: "CSE4022",
    cname: "CSE4022 - Natural Language Processing",
    slot: "B2+TB2",
    ename: "VETRISELVI T",
    venue: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "PRABHAKARAN D",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "CHERALATHAN K.K.",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "F2+TF2",
    ename: "PRABHAKARAN D",
    venue: "TT208",
    ctype: "ETH",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L13+L14+L21+L22",
    ename: "AJANTA SIRCAR",
    venue: "SJT721",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L31+L32+L47+L48",
    ename: "RAJU R.L.N",
    venue: "TT116",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L7+L8+L25+L26",
    ename: "SOUMEN MUKHERJEE",
    venue: "TT306",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L9+L10+L23+L24",
    ename: "VARSHA K",
    venue: "SJT701",
    ctype: "LO",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "C2",
    ename: "GAURAV SUSHANT",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "F1",
    ename: "SHILPI GUPTA",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "B1",
    ename: "COUMARAN G",
    venue: "TT207",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "CALAIVANANE R",
    venue: "PRP414",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "ANTOINETTE DANIEL",
    venue: "SMV319",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "G1",
    ename: "MALATHY O",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "HUM1021",
    cname: "HUM1021 - Ethics and Values",
    slot: "F1",
    ename: "TONY P JOSE",
    venue: "SJT107",
    ctype: "TH",
  },
  {
    crcode: "JAP1001",
    cname: "JAP1001 - Japanese for Beginners",
    slot: "G1",
    ename: "KHANJAN",
    venue: "SJT606",
    ctype: "TH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L3+L4",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L31+L32",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L33+L34",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "TT621",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E2+TE2",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA1",
    ename: "BHASKAR SEN GUPTA",
    venue: "SJT222",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA1",
    ename: "SANDEEP CHAKRABORTY",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA2",
    ename: "ARPAN KUMAR NAYAK",
    venue: "SJT205",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "ANUSHA P T",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "BALAKRISHNAN S",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "LEEMA ROSE VIANNIE",
    venue: "SJT305",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB2",
    ename: "LAXMI NARAYAN TRIPATHI",
    venue: "SJT202",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC1",
    ename: "TARUN",
    venue: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC1",
    ename: "SURESH KUMAR VANDRANGI",
    venue: "SJT404",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC2",
    ename: "UMA MAHENDRA KUMAR K",
    venue: "SJT205",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC2",
    ename: "SATHYA SWAROOP N.R",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD1",
    ename: "KURAGANTI VASU",
    venue: "SJT503",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD1",
    ename: "DHANOJ GUPTA",
    venue: "SJT504",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD1",
    ename: "JAGADEESH SURE",
    venue: "SJT505",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TD2",
    ename: "SAMIR RANJAN MEHER",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "ABHISHEK KUMAR SINGH",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "DHRITIMAN GUPTA",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TF1",
    ename: "RAVI SHANKER BABU",
    venue: "SJT201",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "SHOBANA M K",
    venue: "SJT205",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "SUDIPTA SAHA",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "USHA RANI M",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT530A",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT524",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV101",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV102",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV320",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B1+TB1",
    ename: "ANBARASI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B2+TB2",
    ename: "JASMIN T. JOSE",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B1+TB1",
    ename: "ANBARASI M",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "BCI2001",
    cname: "BCI2001 - Data Privacy",
    slot: "B2+TB2",
    ename: "JASMIN T. JOSE",
    venue: "SJT301",
    ctype: "ETH",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L27+L28",
    ename: "BHULAKSHMI BONTHU",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L37+L38",
    ename: "BHULAKSHMI BONTHU",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L27+L28",
    ename: "BHULAKSHMI BONTHU",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "L37+L38",
    ename: "BHULAKSHMI BONTHU",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "A1",
    ename: "BHULAKSHMI BONTHU",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "BCI3001",
    cname: "BCI3001 - Web Security",
    slot: "A2",
    ename: "BHULAKSHMI BONTHU",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    ename: "ANANDA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    ename: "KALAAVATHI B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A2+TA2",
    ename: "ILAYARAJA V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    ename: "ANANDA KUMAR S",
    venue: "SJT409",
    ctype: "ETH",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A1+TA1",
    ename: "KALAAVATHI B",
    venue: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "BCT3001",
    cname: "BCT3001 - Wireless Ad-hoc and Sensor Networks",
    slot: "A2+TA2",
    ename: "ILAYARAJA V",
    venue: "SJT409",
    ctype: "ETH",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C1+TC1",
    ename: "BAIJU B V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C2+TC2",
    ename: "BAIJU B V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C1+TC1",
    ename: "BAIJU B V",
    venue: "SJT315",
    ctype: "ETH",
  },
  {
    crcode: "BCT3004",
    cname: "BCT3004 - Privacy and Security in Internet of Things",
    slot: "C2+TC2",
    ename: "BAIJU B V",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "BCT4003",
    cname: "BCT4003 - Wearable Computing",
    slot: "E1+TE1",
    ename: "JAFAR ALI IBRAHIM S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BCT4003",
    cname: "BCT4003 - Wearable Computing",
    slot: "E1+TE1",
    ename: "JAFAR ALI IBRAHIM S",
    venue: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "A2+TA2",
    ename: "SURESH P.K",
    venue: "SMV217",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "B2+TB2",
    ename: "RAHUL VASHISHTH",
    venue: "MB225",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "E1+TE1",
    ename: "SURESH P.K",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BKT1001",
    cname: "BKT1001 - Blockchain and Distributed Ledger Technology",
    slot: "L33+L34",
    ename: "CHANDRU VIGNESH S",
    venue: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "BKT1001",
    cname: "BKT1001 - Blockchain and Distributed Ledger Technology",
    slot: "A1+TA1",
    ename: "CHANDRU VIGNESH S",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "BKT2001",
    cname: "BKT2001 - Cryptography  and Information Security",
    slot: "L9+L10",
    ename: "RISHIN HALDAR",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "BKT2001",
    cname: "BKT2001 - Cryptography  and Information Security",
    slot: "B2+TB2",
    ename: "RISHIN HALDAR",
    venue: "SJT323",
    ctype: "ETH",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "L11+L12",
    ename: "NIHA K",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "L39+L40",
    ename: "SWARNALATHA P",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "A1+TA1",
    ename: "SWARNALATHA P",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "BKT3001",
    cname: "BKT3001 - Design and Development of Blockchain Applications",
    slot: "A2+TA2",
    ename: "NIHA K",
    venue: "SJT501",
    ctype: "ETH",
  },
  {
    crcode: "BKT4001",
    cname: "BKT4001 - Blockchain Ecosystem",
    slot: "G1+TG1",
    ename: "UMADEVI K S",
    venue: "SJT501",
    ctype: "TH",
  },
  {
    crcode: "BKT4005",
    cname: "BKT4005 - Blockchain Architecture Design and Use Cases",
    slot: "C1+TC1",
    ename: "MADHU VISWANATHAM V",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BKT4005",
    cname: "BKT4005 - Blockchain Architecture Design and Use Cases",
    slot: "C1+TC1",
    ename: "MADHU VISWANATHAM V",
    venue: "SJT715",
    ctype: "ETH",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E1+TE1",
    ename: "GOPICHAND G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E2+TE2",
    ename: "GOPICHAND G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E1+TE1",
    ename: "GOPICHAND G",
    venue: "SJT707",
    ctype: "ETH",
  },
  {
    crcode: "BKT4006",
    cname: "BKT4006 - Cryptocurrency Technologies",
    slot: "E2+TE2",
    ename: "GOPICHAND G",
    venue: "SJT702",
    ctype: "ETH",
  },
  {
    crcode: "CBS1008",
    cname: "CBS1008 - Operations Research",
    slot: "L25+L26",
    ename: "SIVARAJ R",
    venue: "SMVG16",
    ctype: "ELA",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "SMV104",
    ctype: "ETH",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "CHE1009",
    cname: "CHE1009 - Biochemical Engineering",
    slot: "E1+TE1",
    ename: "ARUNA SINGH",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1011",
    cname: "CHE1011 - Optimization of Chemical Processes",
    slot: "F1+TF1",
    ename: "DHARMENDRA KUMAR BAL",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE1013",
    cname: "CHE1013 - Natural Gas Engineering",
    slot: "G1+TG1",
    ename: "ASLAM ABDULLAH M",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "CHE1018",
    cname: "CHE1018 - Membrane Separations Technology",
    slot: "G1+TG1",
    ename: "THOMAS THEODORE",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1019",
    cname: "CHE1019 - Polymer Technology",
    slot: "D1+TD1",
    ename: "AABID HUSSAIN SHAIK",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE2003",
    cname: "CHE2003 - Chemical Product Design",
    slot: "G2+TG2",
    ename: "SHANKAR RAMAN DHANUSHKODI",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "A2",
    ename: "KHANA BAIDYA",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B1",
    ename: "KHANA BAIDYA",
    venue: "TT208",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B2",
    ename: "KHANA BAIDYA",
    venue: "TT206",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "D1",
    ename: "KHANA BAIDYA",
    venue: "TT205",
    ctype: "TH",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "CTSG05",
    ctype: "ELA",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "F1+TF1",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "L53+L54",
    ename: "MALATHY  J",
    venue: "CTSG04",
    ctype: "ELA",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "A1+TA1",
    ename: "MALATHY  J",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "B2+TB2",
    ename: "GANAPATHY G.P",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C1+TC1",
    ename: "ARUNAVA RAY",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C2+TC2",
    ename: "VAANI N",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1013",
    cname: "CLE1013 - Environmental Impact Assessment",
    slot: "A2+TA2",
    ename: "SRIMURUGANANDAM B",
    venue: "CDMM404",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "C2+TC2",
    ename: "SASANKA BHUSHAN PULIPATI",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "D1+TD1",
    ename: "JAYAPRAKASH J",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "TB1",
    ename: "SUGANYA OM",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE2003",
    cname: "CLE2003 - Structural Analysis",
    slot: "C1+TC1+V2",
    ename: "SANTHI A.S",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "B1",
    ename: "DILLIP KUMAR BARIK",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2008",
    cname: "CLE2008 - Construction Planning and Management",
    slot: "C1+TC1",
    ename: "JOHN SUSHIL PACKIARAJ",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "CDMM105",
    ctype: "ETH",
  },
  {
    crcode: "CLE2022",
    cname: "CLE2022 - Economics and Business Finance for Civil Engineers",
    slot: "B2+TB2",
    ename: "PRASANNA VENKATESAN R",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "L59+L60",
    ename: "SURENDAR M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "B1",
    ename: "SURENDAR M",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE3001",
    cname: "CLE3001 - Quantity Surveying and Estimating",
    slot: "G2",
    ename: "SOFI A",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "L39+L40",
    ename: "HAREESH M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "E1+TE1+V5",
    ename: "HAREESH M",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "L29+L30",
    ename: "THIRUMALINI S",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "D2+TD2",
    ename: "THIRUMALINI S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L1+L2",
    ename: "ANUSHA N",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L11+L12",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L13+L14",
    ename: "NARESH K",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L15+L16",
    ename: "ANURADHA G",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L21+L22",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L25+L26",
    ename: "ANURADHA G",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L27+L28",
    ename: "RAJAKUMAR K",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L31+L32",
    ename: "RAJAKUMAR K",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L37+L38",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L39+L40",
    ename: "AJITHA D",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L41+L42",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L43+L44",
    ename: "AJITHA D",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L45+L46",
    ename: "SHOBHA REKH",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L51+L52",
    ename: "SHOBHA REKH",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L7+L8",
    ename: "ANUSHA N",
    venue: "SJT515",
    ctype: "ELA",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L1+L2",
    ename: "ANUSHA N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L11+L12",
    ename: "KRISHNAMOORTHY A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L13+L14",
    ename: "NARESH K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L15+L16",
    ename: "ANURADHA G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L21+L22",
    ename: "KRISHNAMOORTHY A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L25+L26",
    ename: "ANURADHA G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L27+L28",
    ename: "RAJAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L31+L32",
    ename: "RAJAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L37+L38",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L39+L40",
    ename: "AJITHA D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L41+L42",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L43+L44",
    ename: "AJITHA D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L45+L46",
    ename: "SHOBHA REKH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L51+L52",
    ename: "SHOBHA REKH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "L7+L8",
    ename: "ANUSHA N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT503",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "NARESH K",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "ANURADHA G",
    venue: "SJT505",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "RAJAKUMAR K",
    venue: "SJT522",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT617",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "AJITHA D",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "SHOBHA REKH",
    venue: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D1",
    ename: "ANUSHA N",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "KRISHNAMOORTHY A",
    venue: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "ANURADHA G",
    venue: "SJT505",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "RAJAKUMAR K",
    venue: "SJT604",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "AJITHA D",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "SHOBHA REKH",
    venue: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE2006",
    cname: "CSE2006 - Microprocessor and Interfacing",
    slot: "D2",
    ename: "ANUSHA N",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE2008",
    cname: "CSE2008 - Network Security",
    slot: "G1+TG1",
    ename: "KARTHIK G M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE2008",
    cname: "CSE2008 - Network Security",
    slot: "G1+TG1",
    ename: "KARTHIK G M",
    venue: "SJT403",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L1+L2",
    ename: "KAUSER AHMED P",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "MEENAKSHI S P",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "JAYAKUMAR S",
    venue: "SJT419",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "LYDIA JANE G",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    ename: "RAJKUMAR R",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    ename: "JAYAKUMAR K",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    ename: "MARY MEKALA A",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    ename: "SRIDHAR RAJ S",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    ename: "RAJKUMAR R",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    ename: "JAYAKUMAR K",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L33+L34",
    ename: "MEENAKSHI S P",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    ename: "JAYAKUMAR S",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    ename: "LYDIA JANE G",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L37+L38",
    ename: "MARY MEKALA A",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L41+L42",
    ename: "SRIDHAR RAJ S",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L51+L52",
    ename: "ARCHANA T",
    venue: "SJT517",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L57+L58",
    ename: "KAUSER AHMED P",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L7+L8",
    ename: "ARCHANA T",
    venue: "SJT516",
    ctype: "ELA",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L1+L2",
    ename: "KAUSER AHMED P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "MEENAKSHI S P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "JAYAKUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L15+L16",
    ename: "LYDIA JANE G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    ename: "RAJKUMAR R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L19+L20",
    ename: "JAYAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    ename: "MARY MEKALA A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L3+L4",
    ename: "SRIDHAR RAJ S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    ename: "RAJKUMAR R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L31+L32",
    ename: "JAYAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L33+L34",
    ename: "MEENAKSHI S P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    ename: "JAYAKUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L35+L36",
    ename: "LYDIA JANE G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L37+L38",
    ename: "MARY MEKALA A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L41+L42",
    ename: "SRIDHAR RAJ S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L51+L52",
    ename: "ARCHANA T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L57+L58",
    ename: "KAUSER AHMED P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "L7+L8",
    ename: "ARCHANA T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "B1",
    ename: "JAYAKUMAR S",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "B2",
    ename: "JAYAKUMAR S",
    venue: "SJT505",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "MEENAKSHI S P",
    venue: "SJT421",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "RAJKUMAR R",
    venue: "SJT508",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "MARY MEKALA A",
    venue: "SJT311A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "KAUSER AHMED P",
    venue: "SJT302",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "ARCHANA T",
    venue: "SJT404",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "LYDIA JANE G",
    venue: "SJT408",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "JAYAKUMAR K",
    venue: "SJT823",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C1",
    ename: "SRIDHAR RAJ S",
    venue: "SJT222",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "MEENAKSHI S P",
    venue: "SJT302",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "RAJKUMAR R",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "MARY MEKALA A",
    venue: "SJT305",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "KAUSER AHMED P",
    venue: "SJT604",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "ARCHANA T",
    venue: "SJT422",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "LYDIA JANE G",
    venue: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "JAYAKUMAR K",
    venue: "SJT712",
    ctype: "ETH",
  },
  {
    crcode: "CSE3002",
    cname: "CSE3002 - Internet and Web Programming",
    slot: "C2",
    ename: "SRIDHAR RAJ S",
    venue: "SJT802",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L11+L12",
    ename: "RAJA S P",
    venue: "SJT417",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L13+L14",
    ename: "CHANDRA MOHAN B",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L19+L20",
    ename: "ANAND M",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    ename: "ANIL KUMAR K",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    ename: "RUBY D",
    venue: "SJT419",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L23+L24",
    ename: "SIVA SHANMUGAM G",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    ename: "RAMANI S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    ename: "SELVI M",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    ename: "AMUTHA PRABAKAR M",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    ename: "SENDHIL KUMAR K.S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L31+L32",
    ename: "MURALI S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    ename: "AMUTHA PRABAKAR M",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    ename: "SARITHA MURALI",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L39+L40",
    ename: "SENDHIL KUMAR K.S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L41+L42",
    ename: "RUBY D",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L43+L44",
    ename: "SWETHA N G",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    ename: "AJU D",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    ename: "SELVI M",
    venue: "SJT416",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    ename: "LAVANYA K",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    ename: "RAJARAJAN G",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L5+L6",
    ename: "KATHIRAVAN S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "ANIL KUMAR K",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "KATHIRAVAN S",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L53+L54",
    ename: "RAMANI S",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    ename: "KUMARESAN A",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    ename: "CHANDRA MOHAN B",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L57+L58",
    ename: "SIVA SHANMUGAM G",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    ename: "SWETHA N G",
    venue: "SJT316",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    ename: "RAJARAJAN G",
    venue: "SJT418",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "KUMARESAN A",
    venue: "SJT318",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "SIVAKUMAR. N",
    venue: "SJT317",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L11+L12",
    ename: "RAJA S P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L13+L14",
    ename: "CHANDRA MOHAN B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L19+L20",
    ename: "ANAND M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    ename: "ANIL KUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L21+L22",
    ename: "RUBY D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L23+L24",
    ename: "SIVA SHANMUGAM G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    ename: "RAMANI S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L25+L26",
    ename: "SELVI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    ename: "AMUTHA PRABAKAR M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L27+L28",
    ename: "SENDHIL KUMAR K.S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L31+L32",
    ename: "MURALI S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    ename: "AMUTHA PRABAKAR M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L37+L38",
    ename: "SARITHA MURALI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L39+L40",
    ename: "SENDHIL KUMAR K.S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L41+L42",
    ename: "RUBY D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L43+L44",
    ename: "SWETHA N G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    ename: "AJU D",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L47+L48",
    ename: "SELVI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    ename: "LAVANYA K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L49+L50",
    ename: "RAJARAJAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L5+L6",
    ename: "KATHIRAVAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "ANIL KUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "KATHIRAVAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L53+L54",
    ename: "RAMANI S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    ename: "KUMARESAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L55+L56",
    ename: "CHANDRA MOHAN B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L57+L58",
    ename: "SIVA SHANMUGAM G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    ename: "SWETHA N G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L7+L8",
    ename: "RAJARAJAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "KUMARESAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "SIVAKUMAR. N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "ANIL KUMAR K",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "AMUTHA PRABAKAR M",
    venue: "SJT324",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "SWETHA N G",
    venue: "SJT311A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "AJU D",
    venue: "SJT401",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "MURALI S",
    venue: "SJT201",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "SENDHIL KUMAR K.S",
    venue: "SJT602A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "RAMANI S",
    venue: "SJT504",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "SIVA SHANMUGAM G",
    venue: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "LAVANYA K",
    venue: "SJT707",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "CHANDRA MOHAN B",
    venue: "SJT711",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "KATHIRAVAN S",
    venue: "SJT710",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "RAJARAJAN G",
    venue: "SJT712",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "SELVI M",
    venue: "SJT704",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "RUBY D",
    venue: "SJT715",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "KUMARESAN A",
    venue: "SJT507",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SIVA SHANMUGAM G",
    venue: "SJT302",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SWETHA N G",
    venue: "SJT304",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "KUMARESAN A",
    venue: "SJT311A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "ANAND M",
    venue: "SJT201",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SIVAKUMAR. N",
    venue: "SJT208",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SENDHIL KUMAR K.S",
    venue: "SJT424",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "RAMANI S",
    venue: "SJT606",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "CHANDRA MOHAN B",
    venue: "SJT223",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "ANIL KUMAR K",
    venue: "SJT514",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "KATHIRAVAN S",
    venue: "SJT522",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "RAJARAJAN G",
    venue: "SJT222",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "AMUTHA PRABAKAR M",
    venue: "SJT607",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "SELVI M",
    venue: "SJT602A",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "RUBY D",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "RAJA S P",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "G1",
    ename: "SARITHA MURALI",
    venue: "SJT405",
    ctype: "ETH",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E1+TE1",
    ename: "DEEPA.K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    ename: "SARAVANAKUMAR K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    ename: "DEEPA.K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E1+TE1",
    ename: "DEEPA.K",
    venue: "SJT408",
    ctype: "ETH",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    ename: "SARAVANAKUMAR K",
    venue: "SJT707",
    ctype: "ETH",
  },
  {
    crcode: "CSE4015",
    cname: "CSE4015 - Human Computer Interaction",
    slot: "E2+TE2",
    ename: "DEEPA.K",
    venue: "SJT709",
    ctype: "ETH",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1018",
    cname: "EEE1018 - Nanotechnology Fundamentals and its Applications",
    slot: "F1+TF1",
    ename: "PARTHA SHARATHI MALLICK",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L11+L12",
    ename: "KOWSALYA M",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L15+L16",
    ename: "RAVI K",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L21+L22",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L25+L26",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L31+L32",
    ename: "GOKULAKRISHNAN G",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L33+L34",
    ename: "VIJAYAKUMAR D",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L39+L40",
    ename: "SARAVANAKUMAR R",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L43+L44",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L55+L56",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L9+L10",
    ename: "CHITRA A",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE2001",
    cname: "EEE2001 - Network Theory",
    slot: "C1+TC1",
    ename: "PALANISAMY K",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "EEE2003",
    cname: "EEE2003 - Electromechanical Energy Conversion",
    slot: "C1+TC1",
    ename: "MAGESHVARAN R",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    ename: "JAGANATHA PANDIAN B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    ename: "JAGANATHA PANDIAN B",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L33+L34",
    ename: "INDRAGANDHI V",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L37+L38",
    ename: "INDRAGANDHI V",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "B1+TB1",
    ename: "INDRAGANDHI V",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    ename: "GOKULAKRISHNAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    ename: "GOKULAKRISHNAN G",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "HIN1003",
    cname: "HIN1003 - Prathamik Hindi",
    slot: "C1+TC1",
    ename: "JAYALAKSHMI K",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "L15+L16",
    ename: "JAYALAKSHMI K",
    venue: "TT107",
    ctype: "ELA",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "B1",
    ename: "JAYALAKSHMI K",
    venue: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "B2+TB2",
    ename: "RASHMI M",
    venue: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "F1+TF1",
    ename: "BALAMURUGAN J",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    ename: "BHUVANESWARI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    ename: "BHUVANESWARI M",
    venue: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    ename: "BANGALORE MORARJI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    ename: "BANGALORE MORARJI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    ename: "BANGALORE MORARJI",
    venue: "SMVG21",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    ename: "BANGALORE MORARJI",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    ename: "SUDHEER C.V.",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    ename: "SUDHEER C.V.",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TB2",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TG1",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1033",
    cname: "HUM1033 - Micro Economics",
    slot: "D1+TD1",
    ename: "NILAVATHY K",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1038",
    cname: "HUM1038 - International Economics",
    slot: "E2+TE2",
    ename: "ALLI P",
    venue: "TT423",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "B1+TB1",
    ename: "RASHMI M",
    venue: "SJT323",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "C2+TC2",
    ename: "PRABAKAR S",
    venue: "SMV101",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "D2+TD2",
    ename: "BALAMURUGAN J",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "F1+TF1",
    ename: "PRABAKAR S",
    venue: "MB226",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "G2+TG2",
    ename: "KUBENDRAN A",
    venue: "TT305",
    ctype: "TH",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    ename: "KUBENDRAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    ename: "KUBENDRAN A",
    venue: "TT305",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L55+L56",
    ename: "NAVIN KUMAR",
    venue: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L57+L58",
    ename: "BHUVANESWARI M",
    venue: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "E1",
    ename: "BHUVANESWARI M",
    venue: "TT413",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "F2",
    ename: "NAVIN KUMAR",
    venue: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITE4099",
    cname: "ITE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITL1002",
    cname: "ITL1002 - Italiano Di Base",
    slot: "G1",
    ename: "JACOPO MOSESSO",
    venue: "SJT604",
    ctype: "TH",
  },
  {
    crcode: "MEE1001",
    cname: "MEE1001 - Engineering Drawing",
    slot: "L31+L32+L37+L38",
    ename: "CHINMAYA SAHU",
    venue: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE1004",
    cname: "MEE1004 - Fluid Mechanics",
    slot: "L5+L6",
    ename: "ABINASH MOHANTA",
    venue: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1008",
    cname: "MEE1008 - MEMS",
    slot: "B2+TB2",
    ename: "RAGHURAMAN D R S",
    venue: "MB312",
    ctype: "TH",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    ename: "SRIDHAR V G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    ename: "SRIDHAR V G",
    venue: "MB312",
    ctype: "ETH",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L23+L24",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L5+L6",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "D2+TD2+V6",
    ename: "GAYATHRI V",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE1027",
    cname: "MEE1027 - Instrumentation and Control Engineering",
    slot: "L37+L38",
    ename: "SOVAN SUNDAR DASGUPTA",
    venue: "GDN137",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "L39+L40",
    ename: "VIJESH V JOSHI",
    venue: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "G1+TG1",
    ename: "VIJESH V JOSHI",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "L57+L58",
    ename: "GUNDABATTINI EDISON",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "GUNDABATTINI EDISON",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L3+L4",
    ename: "RAMMOHAN A",
    venue: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L9+L10",
    ename: "RAMMOHAN A",
    venue: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "E2+TE2",
    ename: "RAMMOHAN A",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    ename: "SENTHIL KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    ename: "SENTHIL KUMAR S",
    venue: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    ename: "ASOKAN M.A",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    ename: "GAURAV GUPTA",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L15+L16",
    ename: "ASOKAN M.A",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L27+L28",
    ename: "GAURAV GUPTA",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    ename: "SABOOR S",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    ename: "SENTHIL KUMAR M",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L37+L38",
    ename: "SABOOR S",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L51+L52",
    ename: "SENTHIL KUMAR M",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L19+L20",
    ename: "NAVEEN J",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L27+L28",
    ename: "MOHANKUMAR K V",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L3+L4",
    ename: "NAVEEN J",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L35+L36",
    ename: "SRIDHARAN K",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L39+L40",
    ename: "SRIDHARAN K",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L45+L46",
    ename: "MANIKANDAN M",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L49+L50",
    ename: "MANIKANDAN M",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L7+L8",
    ename: "MOHANKUMAR K V",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    ename: "SRIDHARAN K",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    ename: "MANIKANDAN M",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    ename: "NAVEEN J",
    venue: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    ename: "MOHANKUMAR K V",
    venue: "MB225",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L11+L12",
    ename: "TAPANO KUMAR HOTTA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L15+L16",
    ename: "GOVINDHA RASU N",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L3+L4",
    ename: "TAPANO KUMAR HOTTA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L35+L36",
    ename: "ARUNA KUMAR BEHURA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L43+L44",
    ename: "ARUNA KUMAR BEHURA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L45+L46",
    ename: "NISHANT TIWARI",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L53+L54",
    ename: "NISHANT TIWARI",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L9+L10",
    ename: "GOVINDHA RASU N",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    ename: "GOVINDHA RASU N",
    venue: "GDNG08A",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    ename: "TAPANO KUMAR HOTTA",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "ARUNA KUMAR BEHURA",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "NISHANT TIWARI",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L11+L12",
    ename: "JAMBESWAR SAHU",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L41+L42",
    ename: "VENKATESHWARLU B",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L53+L54",
    ename: "ANOJ GIRI",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    ename: "VENKATESHWARLU B",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    ename: "ANOJ GIRI",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G2",
    ename: "JAMBESWAR SAHU",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L11+L12+L21+L22",
    ename: "RAJKUMAR E",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L31+L32+L39+L40",
    ename: "RAJKUMAR E",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B1",
    ename: "RAJKUMAR E",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B2",
    ename: "RAJKUMAR E",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    ename: "GUNJI BALA MURALI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    ename: "GUNJI BALA MURALI",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2009",
    cname: "MEE2009 - Tribology",
    slot: "E1+TE1+V7",
    ename: "ARULVEL S",
    venue: "GDN129",
    ctype: "TH",
  },
  {
    crcode: "MEE2010",
    cname: "MEE2010 - Design of Composite Materials",
    slot: "D2+TD2+V6",
    ename: "SK ARIFUL RAHAMAN",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    ename: "RIJESH M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    ename: "RIJESH M",
    venue: "MB226",
    ctype: "ETH",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    ename: "CHINMAYA PRASAD MOHANTY",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    ename: "CHINMAYA PRASAD MOHANTY",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L15+L16",
    ename: "RAJAMURUGAN G",
    venue: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L3+L4",
    ename: "RAJAMURUGAN G",
    venue: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "F2+TF2",
    ename: "RAJAMURUGAN G",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    ename: "RAJA K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    ename: "RAJA K",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2020",
    cname: "MEE2020 - Metal Forming Theory and Practice",
    slot: "G2+TG2",
    ename: "DEVASRI FULORIA",
    venue: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE2022",
    cname: "MEE2022 - Power Plant Engineering",
    slot: "B1+TB1",
    ename: "ASOKAN M.A",
    venue: "MB211",
    ctype: "TH",
  },
  {
    crcode: "MEE2023",
    cname: "MEE2023 - Gas Dynamics and Jet Propulsion",
    slot: "E1+TE1+V7",
    ename: "HUMRUTHA G",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    ename: "YAGNA S MUKKAMALA",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    ename: "YAGNA S MUKKAMALA",
    venue: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2031",
    cname: "MEE2031 - Theory of Metal Cutting and Forming",
    slot: "F1+TF1",
    ename: "PANDIVELAN C",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2032",
    cname: "MEE2032 - Kinematics and Dynamics of Machinery",
    slot: "D2+TD2+V6",
    ename: "NIRANJANA BEHERA",
    venue: "MB225",
    ctype: "TH",
  },
  {
    crcode: "MEE2038",
    cname: "MEE2038 - Thermal and Heat Transfer",
    slot: "A1+TA1+V1",
    ename: "DEEPAKKUMAR R",
    venue: "MB224",
    ctype: "TH",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    ename: "BASKAR P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    ename: "BASKAR P",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2050",
    cname: "MEE2050 - Vehicle Dynamics",
    slot: "E1+TE1+V7",
    ename: "SAKTHIVEL P",
    venue: "MB309",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    ename: "NARENDIRANATH BABU T",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    ename: "AHANKARI SANDEEP SURESHRAO",
    venue: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "B2+TB2+V4",
    ename: "VINOTH JEBARAJ A",
    venue: "MB214",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "D2+TD2+V6",
    ename: "GOKUL KUMAR K",
    venue: "MB229",
    ctype: "TH",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "L33+L34",
    ename: "PADMANABHAN K",
    venue: "MB110",
    ctype: "ELA",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "E1+TE1+V7",
    ename: "PADMANABHAN K",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3004",
    cname: "MEE3004 - Internal Combustion Engines",
    slot: "B1+TB1",
    ename: "NANTHA GOPAL K",
    venue: "GDNG08",
    ctype: "TH",
  },
  {
    crcode: "MEE3005",
    cname: "MEE3005 - Refrigeration and Airconditioning",
    slot: "D2+TD2+TDD2+V6",
    ename: "RAMESH KUMAR C",
    venue: "MB226",
    ctype: "TH",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L19+L20",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L9+L10",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "B2",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "L41+L42",
    ename: "DEEPA A",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "D1",
    ename: "DEEPA A",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L39+L40",
    ename: "NATARAJ G",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L41+L42",
    ename: "NATARAJ G",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "G1+TG1",
    ename: "NATARAJ G",
    venue: "MB211",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    ename: "RENOLD ELSEN S",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    ename: "SRINIVASAN NARAYANAN",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    ename: "VENKATESAN K",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    ename: "OYYARAVELU R",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    ename: "DEVENDIRAN S",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    ename: "CHIRANJEEVI C",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    ename: "RAJA SEKHAR Y",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    ename: "BIKASH ROUTH",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    ename: "RENOLD ELSEN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    ename: "SRINIVASAN NARAYANAN",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    ename: "VENKATESAN K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    ename: "OYYARAVELU R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    ename: "DEVENDIRAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    ename: "CHIRANJEEVI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    ename: "RAJA SEKHAR Y",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    ename: "BIKASH ROUTH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "OYYARAVELU R",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "DEVENDIRAN S",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "CHIRANJEEVI C",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "RAJA SEKHAR Y",
    venue: "MB213",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "VENKATESAN K",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "BIKASH ROUTH",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "RENOLD ELSEN S",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "SRINIVASAN NARAYANAN",
    venue: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    ename: "ANANDAVEL K",
    venue: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    ename: "ANANDAVEL K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "G2",
    ename: "ANANDAVEL K",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L19+L20",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L3+L4",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "D2+TD2+V6",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB227",
    ctype: "ETH",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "SET5003",
    cname: "SET5003 - Science",
    slot: "Engineering and Technology Project - III",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "MOHANA CM",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "SANTHI K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "SRIDHAR RAJ S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "ANUSHA N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "TUSAR KANTI MISHRA",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "DILIPKUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "MOHANA CM",
    venue: "SJT715",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "SANTHI K",
    venue: "SJT603",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "SRIDHAR RAJ S",
    venue: "SJT626",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E1+TE1",
    ename: "ANUSHA N",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "TUSAR KANTI MISHRA",
    venue: "SJT617",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "SARASWATHI PRIYADHARSHINI A",
    venue: "SJT619",
    ctype: "ETH",
  },
  {
    crcode: "CSE3013",
    cname: "CSE3013 - Artificial Intelligence",
    slot: "E2+TE2",
    ename: "DILIPKUMAR S",
    venue: "SJT627",
    ctype: "ETH",
  },
  {
    crcode: "ENG1000",
    cname: "ENG1000 - Foundation English - I",
    slot: "L11+L12+L27+L28",
    ename: "SRINIVASAN R",
    venue: "TT432",
    ctype: "LO",
  },
  {
    crcode: "ENG2000",
    cname: "ENG2000 - Foundation English - II",
    slot: "L33+L34+L47+L48",
    ename: "SANKAR G",
    venue: "SJT721",
    ctype: "LO",
  },

  {
    crcode: "CSE1007",
    cname: "CSE1007 - Java Programming",
    slot: "L45+L46",
    ename: "SENTHIL MURUGAN B",
    venue: "SJT119",
    ctype: "ELA",
  },
  {
    crcode: "CSE1007",
    cname: "CSE1007 - Java Programming",
    slot: "B1+TB1",
    ename: "SENTHIL MURUGAN B",
    venue: "SJT105",
    ctype: "ETH",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "L47+L48",
    ename: "SANKARDOSS V",
    venue: "TT433",
    ctype: "ELA",
  },
  {
    crcode: "EEE1001",
    cname: "EEE1001 - Basic Electrical and Electronics Engineering",
    slot: "E1",
    ename: "SANKARDOSS V",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "ITE1006",
    cname: "ITE1006 - Theory of Computation",
    slot: "B2+TB2",
    ename: "UMA MAHESWARI G",
    venue: "SJT125",
    ctype: "TH",
  },
  {
    crcode: "ITE2001",
    cname: "ITE2001 - Computer Architecture and Organization",
    slot: "C2+TC2",
    ename: "MALASERENE I",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "ITE2002",
    cname: "ITE2002 - Operating Systems",
    slot: "L1+L2",
    ename: "GAYATHRI A",
    venue: "SJT120",
    ctype: "ELA",
  },
  {
    crcode: "ITE2002",
    cname: "ITE2002 - Operating Systems",
    slot: "A2+TA2",
    ename: "GAYATHRI A",
    venue: "SJT208",
    ctype: "ETH",
  },
  {
    crcode: "ITE3001",
    cname: "ITE3001 - Data Communication and Computer Networks",
    slot: "L35+L36",
    ename: "KUMAR P.J",
    venue: "SJT218",
    ctype: "ELA",
  },
  {
    crcode: "ITE3001",
    cname: "ITE3001 - Data Communication and Computer Networks",
    slot: "G1+TG1",
    ename: "KUMAR P.J",
    venue: "SJT115",
    ctype: "ETH",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E1+TE1",
    ename: "ASWANI KUMAR CHERUKURI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E1+TE1",
    ename: "SELVA RANI B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E1+TE1",
    ename: "USHA DEVI G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E2+TE2",
    ename: "ASWANI KUMAR CHERUKURI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E2+TE2",
    ename: "SHANTHARAJAH S P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E1+TE1",
    ename: "ASWANI KUMAR CHERUKURI",
    venue: "SJT201",
    ctype: "ETH",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E1+TE1",
    ename: "SELVA RANI B",
    venue: "SJT801",
    ctype: "ETH",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E1+TE1",
    ename: "USHA DEVI G",
    venue: "SJT802",
    ctype: "ETH",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E2+TE2",
    ename: "ASWANI KUMAR CHERUKURI",
    venue: "SJT126",
    ctype: "ETH",
  },
  {
    crcode: "ITE4001",
    cname: "ITE4001 - Network and Information Security",
    slot: "E2+TE2",
    ename: "SHANTHARAJAH S P",
    venue: "SJT125",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L35+L36",
    ename: "NAVANEETHAN C",
    venue: "SJT119",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "JEYANTHI N",
    venue: "SJT218",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "THANDEESWARAN R",
    venue: "SJTG18",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "JEYANTHI N",
    venue: "SJT216",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "THANDEESWARAN R",
    venue: "SJT217",
    ctype: "ELA",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L35+L36",
    ename: "NAVANEETHAN C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "JEYANTHI N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L51+L52",
    ename: "THANDEESWARAN R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "JEYANTHI N",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "L9+L10",
    ename: "THANDEESWARAN R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "JEYANTHI N",
    venue: "SJT825",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "NAVANEETHAN C",
    venue: "SJT824",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F1",
    ename: "THANDEESWARAN R",
    venue: "SJT823",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "JEYANTHI N",
    venue: "SJT124",
    ctype: "ETH",
  },
  {
    crcode: "CSE3501",
    cname: "CSE3501 - Information Security Analysis and Audit",
    slot: "F2",
    ename: "THANDEESWARAN R",
    venue: "SJT125",
    ctype: "ETH",
  },
  {
    crcode: "ITE1007",
    cname: "ITE1007 - Object Oriented Analysis and Design",
    slot: "A1+TA1",
    ename: "GUNASEKARAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE1007",
    cname: "ITE1007 - Object Oriented Analysis and Design",
    slot: "A2+TA2",
    ename: "GUNASEKARAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE1007",
    cname: "ITE1007 - Object Oriented Analysis and Design",
    slot: "A1+TA1",
    ename: "GUNASEKARAN G",
    venue: "SJT825",
    ctype: "ETH",
  },
  {
    crcode: "ITE1007",
    cname: "ITE1007 - Object Oriented Analysis and Design",
    slot: "A2+TA2",
    ename: "GUNASEKARAN G",
    venue: "SJT127",
    ctype: "ETH",
  },
  {
    crcode: "ITE1008",
    cname: "ITE1008 - Open Source programming",
    slot: "F2+TF2",
    ename: "VIJAYARANI A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE1008",
    cname: "ITE1008 - Open Source programming",
    slot: "F2+TF2",
    ename: "VIJAYARANI A",
    venue: "SJT126",
    ctype: "ETH",
  },
  {
    crcode: "ITE1015",
    cname: "ITE1015 - Soft Computing",
    slot: "B1+TB1",
    ename: "BALAKRUSHNA TRIPATHY",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE1015",
    cname: "ITE1015 - Soft Computing",
    slot: "B2+TB2",
    ename: "BALAKRUSHNA TRIPATHY",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE1015",
    cname: "ITE1015 - Soft Computing",
    slot: "B1+TB1",
    ename: "BALAKRUSHNA TRIPATHY",
    venue: "SJT211A",
    ctype: "ETH",
  },
  {
    crcode: "ITE1015",
    cname: "ITE1015 - Soft Computing",
    slot: "B2+TB2",
    ename: "BALAKRUSHNA TRIPATHY",
    venue: "SJT211A",
    ctype: "ETH",
  },
  {
    crcode: "ITE1017",
    cname: "ITE1017 - Transformation Techniques",
    slot: "C1+TC1",
    ename: "PRABUKUMAR M",
    venue: "PRPG08",
    ctype: "TH",
  },
  {
    crcode: "ITE1017",
    cname: "ITE1017 - Transformation Techniques",
    slot: "C2+TC2",
    ename: "RAMESH P.S",
    venue: "SJT222",
    ctype: "TH",
  },
  {
    crcode: "ITE2004",
    cname: "ITE2004 - Software Testing",
    slot: "G1+TG1",
    ename: "PRASANNA M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE2004",
    cname: "ITE2004 - Software Testing",
    slot: "G1+TG1",
    ename: "PRASANNA M",
    venue: "SJT118",
    ctype: "ETH",
  },
  {
    crcode: "ITE2006",
    cname: "ITE2006 - Data Mining Techniques",
    slot: "C1+TC1",
    ename: "VALARMATHI B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE2006",
    cname: "ITE2006 - Data Mining Techniques",
    slot: "C2+TC2",
    ename: "VALARMATHI B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE2006",
    cname: "ITE2006 - Data Mining Techniques",
    slot: "C1+TC1",
    ename: "VALARMATHI B",
    venue: "SJT824",
    ctype: "ETH",
  },
  {
    crcode: "ITE2006",
    cname: "ITE2006 - Data Mining Techniques",
    slot: "C2+TC2",
    ename: "VALARMATHI B",
    venue: "SJT221",
    ctype: "ETH",
  },
  {
    crcode: "ITE2010",
    cname: "ITE2010 - Artificial Intelligence",
    slot: "D1+TD1",
    ename: "HARSHITA PATEL",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE2010",
    cname: "ITE2010 - Artificial Intelligence",
    slot: "D1+TD1",
    ename: "HARSHITA PATEL",
    venue: "SJT211A",
    ctype: "ETH",
  },
  {
    crcode: "ITE2011",
    cname: "ITE2011 - Machine Learning",
    slot: "C1+TC1",
    ename: "DURAI RAJ VINCENT P.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE2011",
    cname: "ITE2011 - Machine Learning",
    slot: "C1+TC1",
    ename: "DURAI RAJ VINCENT P.M",
    venue: "SJT126",
    ctype: "ETH",
  },
  {
    crcode: "ITE2013",
    cname: "ITE2013 - Big Data Analytics",
    slot: "A2+TA2",
    ename: "DAPHNE LOPEZ",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE2013",
    cname: "ITE2013 - Big Data Analytics",
    slot: "A2+TA2",
    ename: "DAPHNE LOPEZ",
    venue: "SJT221",
    ctype: "ETH",
  },
  {
    crcode: "ITE2014",
    cname: "ITE2014 - Software Project Management",
    slot: "B1",
    ename: "SUJATHA R",
    venue: "SJT825",
    ctype: "TH",
  },
  {
    crcode: "ITE2014",
    cname: "ITE2014 - Software Project Management",
    slot: "B2",
    ename: "SUMAIYA THASEEN I",
    venue: "SJT113",
    ctype: "TH",
  },
  {
    crcode: "ITE2015",
    cname: "ITE2015 - Information System Audit",
    slot: "C1",
    ename: "HARI RAM VISHWAKARMA",
    venue: "SJT825",
    ctype: "TH",
  },
  {
    crcode: "ITE3007",
    cname: "ITE3007 - Cloud Computing and Virtualization",
    slot: "G1+TG1",
    ename: "NADESH R.K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ITE3007",
    cname: "ITE3007 - Cloud Computing and Virtualization",
    slot: "G1+TG1",
    ename: "NADESH R.K",
    venue: "SJT211",
    ctype: "ETH",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "PRABHAKARAN D",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "L25+L26",
    ename: "CHERALATHAN K.K.",
    venue: "SMVG027",
    ctype: "ELA",
  },
  {
    crcode: "CHY1701",
    cname: "CHY1701 - Engineering Chemistry",
    slot: "F2+TF2",
    ename: "PRABHAKARAN D",
    venue: "TT208",
    ctype: "ETH",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L13+L14+L21+L22",
    ename: "AJANTA SIRCAR",
    venue: "SJT721",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L31+L32+L47+L48",
    ename: "RAJU R.L.N",
    venue: "TT116",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L7+L8+L25+L26",
    ename: "SOUMEN MUKHERJEE",
    venue: "TT306",
    ctype: "LO",
  },
  {
    crcode: "ENG1901",
    cname: "ENG1901 - Technical English - I",
    slot: "L9+L10+L23+L24",
    ename: "VARSHA K",
    venue: "SJT701",
    ctype: "LO",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "C2",
    ename: "GAURAV SUSHANT",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "ESP1001",
    cname: "ESP1001 - ESPANOL FUNDAMENTAL",
    slot: "F1",
    ename: "SHILPI GUPTA",
    venue: "SJT513",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "B1",
    ename: "COUMARAN G",
    venue: "TT207",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "CALAIVANANE R",
    venue: "PRP414",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "F1",
    ename: "ANTOINETTE DANIEL",
    venue: "SMV319",
    ctype: "TH",
  },
  {
    crcode: "FRE1001",
    cname: "FRE1001 - Francais quotidien",
    slot: "G1",
    ename: "MALATHY O",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "HUM1021",
    cname: "HUM1021 - Ethics and Values",
    slot: "F1",
    ename: "TONY P JOSE",
    venue: "SJT107",
    ctype: "TH",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITE1904",
    cname: "ITE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "JAP1001",
    cname: "JAP1001 - Japanese for Beginners",
    slot: "G1",
    ename: "KHANJAN",
    venue: "SJT606",
    ctype: "TH",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG1",
    ename: "ASIS KUMAR TRIPATHY",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG1",
    ename: "NEELU KHARE",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG1",
    ename: "JAYARAM REDDY A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG2",
    ename: "TAPAN KUMAR DAS",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG2",
    ename: "CHANDRA MOULISWARAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG1",
    ename: "ASIS KUMAR TRIPATHY",
    venue: "SJT127",
    ctype: "ETH",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG1",
    ename: "NEELU KHARE",
    venue: "SJT211A",
    ctype: "ETH",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG1",
    ename: "JAYARAM REDDY A",
    venue: "SJT221",
    ctype: "ETH",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG2",
    ename: "TAPAN KUMAR DAS",
    venue: "SJT115",
    ctype: "ETH",
  },
  {
    crcode: "MGT1022",
    cname: "MGT1022 - Lean Start-up Management",
    slot: "TG2",
    ename: "CHANDRA MOULISWARAN S",
    venue: "SJT118",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L3+L4",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L31+L32",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "L33+L34",
    ename: "SHAIK KALEEMULLA",
    venue: "PRPG17",
    ctype: "ELA",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E1+TE1",
    ename: "VIJAYA CHAMUNDEESWARI S P",
    venue: "TT621",
    ctype: "ETH",
  },
  {
    crcode: "PHY1701",
    cname: "PHY1701 - Engineering Physics",
    slot: "E2+TE2",
    ename: "SHAIK KALEEMULLA",
    venue: "TT620",
    ctype: "ETH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TA1",
    ename: "BHASKAR SEN GUPTA",
    venue: "SJT222",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "ANUSHA P T",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "BALAKRISHNAN S",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TB1",
    ename: "LEEMA ROSE VIANNIE",
    venue: "SJT305",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC1",
    ename: "TARUN",
    venue: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TC2",
    ename: "SATHYA SWAROOP N.R",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "ABHISHEK KUMAR SINGH",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TE1",
    ename: "DHRITIMAN GUPTA",
    venue: "SJT208",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TF1",
    ename: "RAVI SHANKER BABU",
    venue: "SJT201",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "SUDIPTA SAHA",
    venue: "SJT215",
    ctype: "TH",
  },
  {
    crcode: "PHY1901",
    cname: "PHY1901 - Introduction to Innovative Projects",
    slot: "TG1",
    ename: "USHA RANI M",
    venue: "SJT223",
    ctype: "TH",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT105",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT305",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT104",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT109",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT308",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT311A",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT315",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT323",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT324",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT401",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT402",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT403",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT404",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT405",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "SJT409",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT415",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT421",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SJT422",
    ctype: "SS",
  },
  {
    crcode: "STS3201",
    cname: "STS3201 - Programming Skills for Employment",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SJT423",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT530A",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT524",
    ctype: "SS",
  },
  {
    crcode: "STS3301",
    cname: "STS3301 - JAVA for Beginners",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "TT530",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV101",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "SMART (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "FACE (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D1+TD1",
    ename: "ETHNUS (APT)",
    venue: "SMV102",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV320",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "SMV116",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT413",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT201",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT204",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMV126",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "TT304",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "TT331",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB119",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "ETHNUS (APT)",
    venue: "MB211",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "MB212",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "MB213",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "FACE (APT)",
    venue: "SMVG21",
    ctype: "SS",
  },
  {
    crcode: "STS3401",
    cname: "STS3401 - Foundation to Programming Skills",
    slot: "D2+TD2",
    ename: "SMART (APT)",
    venue: "GDN106",
    ctype: "SS",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "A2+TA2",
    ename: "SURESH P.K",
    venue: "SMV217",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "B2+TB2",
    ename: "RAHUL VASHISHTH",
    venue: "MB225",
    ctype: "TH",
  },
  {
    crcode: "BIT1026",
    cname: "BIT1026 - Food, Nutrition and Health",
    slot: "E1+TE1",
    ename: "SURESH P.K",
    venue: "SMV214",
    ctype: "TH",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT1904",
    cname: "BIT1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT4099",
    cname: "BIT4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "BIT6099",
    cname: "BIT6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1008",
    cname: "CBS1008 - Operations Research",
    slot: "L25+L26",
    ename: "SIVARAJ R",
    venue: "SMVG16",
    ctype: "ELA",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CBS1904",
    cname: "CBS1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "F1",
    ename: "NAGAMALLESWARA RAO K",
    venue: "SMV104",
    ctype: "ETH",
  },
  {
    crcode: "CHE1007",
    cname: "CHE1007 - Safety and Hazard Analysis",
    slot: "G1",
    ename: "MAHESH GANESAPILLAI",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "CHE1009",
    cname: "CHE1009 - Biochemical Engineering",
    slot: "E1+TE1",
    ename: "ARUNA SINGH",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1011",
    cname: "CHE1011 - Optimization of Chemical Processes",
    slot: "F1+TF1",
    ename: "DHARMENDRA KUMAR BAL",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE1013",
    cname: "CHE1013 - Natural Gas Engineering",
    slot: "G1+TG1",
    ename: "ASLAM ABDULLAH M",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CHE1017",
    cname: "CHE1017 - Food Process Engineering",
    slot: "F2",
    ename: "ARUNA SINGH",
    venue: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "CHE1018",
    cname: "CHE1018 - Membrane Separations Technology",
    slot: "G1+TG1",
    ename: "THOMAS THEODORE",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1019",
    cname: "CHE1019 - Polymer Technology",
    slot: "D1+TD1",
    ename: "AABID HUSSAIN SHAIK",
    venue: "SMV104",
    ctype: "TH",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE1904",
    cname: "CHE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE2003",
    cname: "CHE2003 - Chemical Product Design",
    slot: "G2+TG2",
    ename: "SHANKAR RAMAN DHANUSHKODI",
    venue: "SMV102",
    ctype: "TH",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHE4099",
    cname: "CHE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "A2",
    ename: "KHANA BAIDYA",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B1",
    ename: "KHANA BAIDYA",
    venue: "TT208",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "B2",
    ename: "KHANA BAIDYA",
    venue: "TT206",
    ctype: "TH",
  },
  {
    crcode: "CHI1001",
    cname: "CHI1001 - Chinese for Engineers",
    slot: "D1",
    ename: "KHANA BAIDYA",
    venue: "TT205",
    ctype: "TH",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "CTSG05",
    ctype: "ELA",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "L57+L58",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE1003",
    cname: "CLE1003 - Surveying",
    slot: "F1+TF1",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "L53+L54",
    ename: "MALATHY  J",
    venue: "CTSG04",
    ctype: "ELA",
  },
  {
    crcode: "CLE1004",
    cname: "CLE1004 - Soil Mechanics and Foundation Engineering",
    slot: "A1+TA1",
    ename: "MALATHY  J",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "B2+TB2",
    ename: "GANAPATHY G.P",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C1+TC1",
    ename: "ARUNAVA RAY",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1010",
    cname: "CLE1010 - Natural Disaster Mitigation and Management",
    slot: "C2+TC2",
    ename: "VAANI N",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE1013",
    cname: "CLE1013 - Environmental Impact Assessment",
    slot: "A2+TA2",
    ename: "SRIMURUGANANDAM B",
    venue: "CDMM404",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "C2+TC2",
    ename: "SASANKA BHUSHAN PULIPATI",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE1016",
    cname: "CLE1016 - Urban Planning",
    slot: "D1+TD1",
    ename: "JAYAPRAKASH J",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE1904",
    cname: "CLE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "L55+L56",
    ename: "SUGANYA OM",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2001",
    cname: "CLE2001 - Building Drawing",
    slot: "TB1",
    ename: "SUGANYA OM",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE2003",
    cname: "CLE2003 - Structural Analysis",
    slot: "C1+TC1+V2",
    ename: "SANTHI A.S",
    venue: "CDMM105",
    ctype: "TH",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "L35+L36",
    ename: "DILLIP KUMAR BARIK",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2004",
    cname: "CLE2004 - Water Resource Engineering",
    slot: "B1",
    ename: "DILLIP KUMAR BARIK",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2008",
    cname: "CLE2008 - Construction Planning and Management",
    slot: "C1+TC1",
    ename: "JOHN SUSHIL PACKIARAJ",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2010",
    cname: "CLE2010 - Ground Improvement Techniques",
    slot: "F1",
    ename: "MUTHUKUMAR M",
    venue: "CDMM405",
    ctype: "ETH",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE2018",
    cname: "CLE2018 - Industrial Wastes Treatment and Disposal",
    slot: "G1",
    ename: "SHANTHA KUMAR S",
    venue: "CDMM105",
    ctype: "ETH",
  },
  {
    crcode: "CLE2022",
    cname: "CLE2022 - Economics and Business Finance for Civil Engineers",
    slot: "B2+TB2",
    ename: "PRASANNA VENKATESAN R",
    venue: "CDMM402",
    ctype: "TH",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "L59+L60",
    ename: "SURENDAR M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE2023",
    cname: "CLE2023 - GIS and Remote Sensing",
    slot: "B1",
    ename: "SURENDAR M",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE3001",
    cname: "CLE3001 - Quantity Surveying and Estimating",
    slot: "G2",
    ename: "SOFI A",
    venue: "CDMM405",
    ctype: "TH",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "L39+L40",
    ename: "HAREESH M",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE3002",
    cname: "CLE3002 - Basics of Structural Design",
    slot: "E1+TE1+V5",
    ename: "HAREESH M",
    venue: "CDMM403",
    ctype: "ETH",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "CLE3007",
    cname: "CLE3007 - Traffic Engineering",
    slot: "F2",
    ename: "VASANTHA KUMAR S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "L29+L30",
    ename: "THIRUMALINI S",
    venue: "GDN153",
    ctype: "ELA",
  },
  {
    crcode: "CLE4001",
    cname: "CLE4001 - Design of Steel Structures",
    slot: "D2+TD2",
    ename: "THIRUMALINI S",
    venue: "CDMM402",
    ctype: "ETH",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE4099",
    cname: "CLE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CLE6099",
    cname: "CLE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE1904",
    cname: "CSE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "CSE6099",
    cname: "CSE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE1904",
    cname: "ECE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "TT729",
    ctype: "ELA",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L15+L16",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE3003",
    cname: "ECE3003 - Microcontroller and its Applications",
    slot: "L41+L42",
    ename: "VIDHYAPATHI C.M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE4099",
    cname: "ECE4099 - Co-Op / Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ECE6099",
    cname: "ECE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1018",
    cname: "EEE1018 - Nanotechnology Fundamentals and its Applications",
    slot: "F1+TF1",
    ename: "PARTHA SHARATHI MALLICK",
    venue: "TT404",
    ctype: "TH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "E2+TE2+V5",
    ename: "RANI C",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE1020",
    cname: "EEE1020 - Engineering Optimization",
    slot: "G1+TG1+V1",
    ename: "RAGHUNATHAN T",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L11+L12",
    ename: "KOWSALYA M",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L15+L16",
    ename: "RAVI K",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L21+L22",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L25+L26",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L31+L32",
    ename: "GOKULAKRISHNAN G",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L33+L34",
    ename: "VIJAYAKUMAR D",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L39+L40",
    ename: "SARAVANAKUMAR R",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L43+L44",
    ename: "SATYAJIT MOHANTY",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L55+L56",
    ename: "SATYAJIT DAS",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1021",
    cname: "EEE1021 - Electrical Safety",
    slot: "L9+L10",
    ename: "CHITRA A",
    venue: "TT037",
    ctype: "LO",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE1904",
    cname: "EEE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE2001",
    cname: "EEE2001 - Network Theory",
    slot: "C1+TC1",
    ename: "PALANISAMY K",
    venue: "TT104",
    ctype: "TH",
  },
  {
    crcode: "EEE2003",
    cname: "EEE2003 - Electromechanical Energy Conversion",
    slot: "C1+TC1",
    ename: "MAGESHVARAN R",
    venue: "TT424",
    ctype: "ETH",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    ename: "JAGANATHA PANDIAN B",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE2004",
    cname: "EEE2004 - Measurement and Instrumentation",
    slot: "C1",
    ename: "JAGANATHA PANDIAN B",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L33+L34",
    ename: "INDRAGANDHI V",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "L37+L38",
    ename: "INDRAGANDHI V",
    venue: "TT044",
    ctype: "ELA",
  },
  {
    crcode: "EEE3004",
    cname: "EEE3004 - Power Electronics and Drives",
    slot: "B1+TB1",
    ename: "INDRAGANDHI V",
    venue: "TT423",
    ctype: "ETH",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    ename: "GOKULAKRISHNAN G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "EEE4003",
    cname: "EEE4003 - Generation and Utilization of Electrical Energy",
    slot: "C2",
    ename: "GOKULAKRISHNAN G",
    venue: "TT404",
    ctype: "ETH",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "EEE6099",
    cname: "EEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "HIN1003",
    cname: "HIN1003 - Prathamik Hindi",
    slot: "C1+TC1",
    ename: "JAYALAKSHMI K",
    venue: "SMV109",
    ctype: "TH",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "L15+L16",
    ename: "JAYALAKSHMI K",
    venue: "TT107",
    ctype: "ELA",
  },
  {
    crcode: "HIN1004",
    cname: "HIN1004 - Functional Hindi",
    slot: "B1",
    ename: "JAYALAKSHMI K",
    venue: "SMV102",
    ctype: "ETH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "B2+TB2",
    ename: "RASHMI M",
    venue: "SJT302",
    ctype: "TH",
  },
  {
    crcode: "HUM1012",
    cname: "HUM1012 - Introduction to Sociology",
    slot: "F1+TF1",
    ename: "BALAMURUGAN J",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    ename: "BHUVANESWARI M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1022",
    cname: "HUM1022 - Psychology in Everyday Life",
    slot: "B2",
    ename: "BHUVANESWARI M",
    venue: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    ename: "BANGALORE MORARJI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    ename: "BANGALORE MORARJI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "A1",
    ename: "BANGALORE MORARJI",
    venue: "SMVG21",
    ctype: "ETH",
  },
  {
    crcode: "HUM1024",
    cname: "HUM1024 - India and Contemporary World",
    slot: "C1",
    ename: "BANGALORE MORARJI",
    venue: "SMVG11",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ELA",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L11+L12",
    ename: "SUDHEER C.V.",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "L23+L24",
    ename: "SUDHEER C.V.",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TB2",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1025",
    cname: "HUM1025 - Indian Classical Music",
    slot: "TG1",
    ename: "SUDHEER C.V.",
    venue: "SJT301A",
    ctype: "ETH",
  },
  {
    crcode: "HUM1033",
    cname: "HUM1033 - Micro Economics",
    slot: "D1+TD1",
    ename: "NILAVATHY K",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1038",
    cname: "HUM1038 - International Economics",
    slot: "E2+TE2",
    ename: "ALLI P",
    venue: "TT423",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "B1+TB1",
    ename: "RASHMI M",
    venue: "SJT323",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "C2+TC2",
    ename: "PRABAKAR S",
    venue: "SMV101",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "D2+TD2",
    ename: "BALAMURUGAN J",
    venue: "SJT307",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "F1+TF1",
    ename: "PRABAKAR S",
    venue: "MB226",
    ctype: "TH",
  },
  {
    crcode: "HUM1040",
    cname: "HUM1040 - Indian Social Problems",
    slot: "G2+TG2",
    ename: "KUBENDRAN A",
    venue: "TT305",
    ctype: "TH",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    ename: "KUBENDRAN A",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "HUM1043",
    cname: "HUM1043 - Mass Media and Society",
    slot: "G1",
    ename: "KUBENDRAN A",
    venue: "TT305",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L55+L56",
    ename: "NAVIN KUMAR",
    venue: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "L57+L58",
    ename: "BHUVANESWARI M",
    venue: "TT432",
    ctype: "ELA",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "E1",
    ename: "BHUVANESWARI M",
    venue: "TT413",
    ctype: "ETH",
  },
  {
    crcode: "HUM1045",
    cname: "HUM1045 - Introduction to Psychology",
    slot: "F2",
    ename: "NAVIN KUMAR",
    venue: "TT531",
    ctype: "ETH",
  },
  {
    crcode: "ITE4099",
    cname: "ITE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ITL1002",
    cname: "ITL1002 - Italiano Di Base",
    slot: "G1",
    ename: "JACOPO MOSESSO",
    venue: "SJT604",
    ctype: "TH",
  },
  {
    crcode: "MEE1001",
    cname: "MEE1001 - Engineering Drawing",
    slot: "L31+L32+L37+L38",
    ename: "CHINMAYA SAHU",
    venue: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE1004",
    cname: "MEE1004 - Fluid Mechanics",
    slot: "L5+L6",
    ename: "ABINASH MOHANTA",
    venue: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1008",
    cname: "MEE1008 - MEMS",
    slot: "B2+TB2",
    ename: "RAGHURAMAN D R S",
    venue: "MB312",
    ctype: "TH",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    ename: "SRIDHAR V G",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1009",
    cname: "MEE1009 - New Product Development",
    slot: "E2",
    ename: "SRIDHAR V G",
    venue: "MB312",
    ctype: "ETH",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L23+L24",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "L5+L6",
    ename: "GAYATHRI V",
    venue: "GDN135",
    ctype: "ELA",
  },
  {
    crcode: "MEE1011",
    cname: "MEE1011 - Renewable Energy Sources",
    slot: "D2+TD2+V6",
    ename: "GAYATHRI V",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE1027",
    cname: "MEE1027 - Instrumentation and Control Engineering",
    slot: "L37+L38",
    ename: "SOVAN SUNDAR DASGUPTA",
    venue: "GDN137",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "L39+L40",
    ename: "VIJESH V JOSHI",
    venue: "GDN131",
    ctype: "ELA",
  },
  {
    crcode: "MEE1032",
    cname: "MEE1032 - Mechanics of Solids and Fluids",
    slot: "G1+TG1",
    ename: "VIJESH V JOSHI",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "L57+L58",
    ename: "GUNDABATTINI EDISON",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE1033",
    cname: "MEE1033 - Thermodynamics and Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "GUNDABATTINI EDISON",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L3+L4",
    ename: "RAMMOHAN A",
    venue: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "L9+L10",
    ename: "RAMMOHAN A",
    venue: "GDN138",
    ctype: "ELA",
  },
  {
    crcode: "MEE1037",
    cname: "MEE1037 - Automotive Electronics",
    slot: "E2+TE2",
    ename: "RAMMOHAN A",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    ename: "SENTHIL KUMAR S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE1045",
    cname: "MEE1045 - Mechatronics Systems Design",
    slot: "G1+TG1",
    ename: "SENTHIL KUMAR S",
    venue: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE1904",
    cname: "MEE1904 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    ename: "ASOKAN M.A",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L1+L2",
    ename: "GAURAV GUPTA",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L15+L16",
    ename: "ASOKAN M.A",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L27+L28",
    ename: "GAURAV GUPTA",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    ename: "SABOOR S",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L31+L32",
    ename: "SENTHIL KUMAR M",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L37+L38",
    ename: "SABOOR S",
    venue: "GDNG11",
    ctype: "ELA",
  },
  {
    crcode: "MEE2003",
    cname: "MEE2003 - Thermal Engineering Systems",
    slot: "L51+L52",
    ename: "SENTHIL KUMAR M",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L19+L20",
    ename: "NAVEEN J",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L27+L28",
    ename: "MOHANKUMAR K V",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L3+L4",
    ename: "NAVEEN J",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L35+L36",
    ename: "SRIDHARAN K",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L39+L40",
    ename: "SRIDHARAN K",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L45+L46",
    ename: "MANIKANDAN M",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L49+L50",
    ename: "MANIKANDAN M",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "L7+L8",
    ename: "MOHANKUMAR K V",
    venue: "ALM 407",
    ctype: "ELA",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    ename: "SRIDHARAN K",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A1+TA1+V1",
    ename: "MANIKANDAN M",
    venue: "GDN122",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    ename: "NAVEEN J",
    venue: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2004",
    cname: "MEE2004 - Mechanics of Machines",
    slot: "A2+TA2+V3",
    ename: "MOHANKUMAR K V",
    venue: "MB225",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L11+L12",
    ename: "TAPANO KUMAR HOTTA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L15+L16",
    ename: "GOVINDHA RASU N",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L3+L4",
    ename: "TAPANO KUMAR HOTTA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L35+L36",
    ename: "ARUNA KUMAR BEHURA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L43+L44",
    ename: "ARUNA KUMAR BEHURA",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L45+L46",
    ename: "NISHANT TIWARI",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L53+L54",
    ename: "NISHANT TIWARI",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "L9+L10",
    ename: "GOVINDHA RASU N",
    venue: "SMVG26",
    ctype: "ELA",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    ename: "GOVINDHA RASU N",
    venue: "GDNG08A",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "D2+TD2+V6",
    ename: "TAPANO KUMAR HOTTA",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "ARUNA KUMAR BEHURA",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2005",
    cname: "MEE2005 - Heat Transfer",
    slot: "E1+TE1+V7",
    ename: "NISHANT TIWARI",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L11+L12",
    ename: "JAMBESWAR SAHU",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L41+L42",
    ename: "VENKATESHWARLU B",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "L53+L54",
    ename: "ANOJ GIRI",
    venue: "GDNG16",
    ctype: "ELA",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    ename: "VENKATESHWARLU B",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G1",
    ename: "ANOJ GIRI",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2006",
    cname: "MEE2006 - Machining Processes and Metrology",
    slot: "G2",
    ename: "JAMBESWAR SAHU",
    venue: "GDN107",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L11+L12+L21+L22",
    ename: "RAJKUMAR E",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "L31+L32+L39+L40",
    ename: "RAJKUMAR E",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B1",
    ename: "RAJKUMAR E",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2007",
    cname: "MEE2007 - CAD/CAM",
    slot: "B2",
    ename: "RAJKUMAR E",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    ename: "GUNJI BALA MURALI",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2008",
    cname: "MEE2008 - Product Design for Manufacturing",
    slot: "E1",
    ename: "GUNJI BALA MURALI",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE2009",
    cname: "MEE2009 - Tribology",
    slot: "E1+TE1+V7",
    ename: "ARULVEL S",
    venue: "GDN129",
    ctype: "TH",
  },
  {
    crcode: "MEE2010",
    cname: "MEE2010 - Design of Composite Materials",
    slot: "D2+TD2+V6",
    ename: "SK ARIFUL RAHAMAN",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    ename: "RIJESH M",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2011",
    cname: "MEE2011 - Welding Engineering",
    slot: "E1",
    ename: "RIJESH M",
    venue: "MB226",
    ctype: "ETH",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    ename: "CHINMAYA PRASAD MOHANTY",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2014",
    cname: "MEE2014 - Metal Casting Technology",
    slot: "B2",
    ename: "CHINMAYA PRASAD MOHANTY",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L15+L16",
    ename: "RAJAMURUGAN G",
    venue: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "L3+L4",
    ename: "RAJAMURUGAN G",
    venue: "GDN08B",
    ctype: "ELA",
  },
  {
    crcode: "MEE2015",
    cname: "MEE2015 - Non Destructive Testing",
    slot: "F2+TF2",
    ename: "RAJAMURUGAN G",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    ename: "RAJA K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2016",
    cname: "MEE2016 - Rapid Manufacturing Technologies",
    slot: "G1",
    ename: "RAJA K",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2020",
    cname: "MEE2020 - Metal Forming Theory and Practice",
    slot: "G2+TG2",
    ename: "DEVASRI FULORIA",
    venue: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE2022",
    cname: "MEE2022 - Power Plant Engineering",
    slot: "B1+TB1",
    ename: "ASOKAN M.A",
    venue: "MB211",
    ctype: "TH",
  },
  {
    crcode: "MEE2023",
    cname: "MEE2023 - Gas Dynamics and Jet Propulsion",
    slot: "E1+TE1+V7",
    ename: "HUMRUTHA G",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    ename: "YAGNA S MUKKAMALA",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2028",
    cname: "MEE2028 - Automotive Aerodynamics",
    slot: "D2+TD2+V6",
    ename: "YAGNA S MUKKAMALA",
    venue: "MB224",
    ctype: "ETH",
  },
  {
    crcode: "MEE2031",
    cname: "MEE2031 - Theory of Metal Cutting and Forming",
    slot: "F1+TF1",
    ename: "PANDIVELAN C",
    venue: "GDN123",
    ctype: "ETH",
  },
  {
    crcode: "MEE2032",
    cname: "MEE2032 - Kinematics and Dynamics of Machinery",
    slot: "D2+TD2+V6",
    ename: "NIRANJANA BEHERA",
    venue: "MB225",
    ctype: "TH",
  },
  {
    crcode: "MEE2038",
    cname: "MEE2038 - Thermal and Heat Transfer",
    slot: "A1+TA1+V1",
    ename: "DEEPAKKUMAR R",
    venue: "MB224",
    ctype: "TH",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    ename: "BASKAR P",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE2039",
    cname: "MEE2039 - Automotive Transmission Systems",
    slot: "E2",
    ename: "BASKAR P",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE2050",
    cname: "MEE2050 - Vehicle Dynamics",
    slot: "E1+TE1+V7",
    ename: "SAKTHIVEL P",
    venue: "MB309",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    ename: "NARENDIRANATH BABU T",
    venue: "MB219",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "A2+TA2+V3",
    ename: "AHANKARI SANDEEP SURESHRAO",
    venue: "GDN120",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "B2+TB2+V4",
    ename: "VINOTH JEBARAJ A",
    venue: "MB214",
    ctype: "TH",
  },
  {
    crcode: "MEE3001",
    cname: "MEE3001 - Design of Machine Elements",
    slot: "D2+TD2+V6",
    ename: "GOKUL KUMAR K",
    venue: "MB229",
    ctype: "TH",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "L33+L34",
    ename: "PADMANABHAN K",
    venue: "MB110",
    ctype: "ELA",
  },
  {
    crcode: "MEE3002",
    cname: "MEE3002 - Finite Element Analysis",
    slot: "E1+TE1+V7",
    ename: "PADMANABHAN K",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3004",
    cname: "MEE3004 - Internal Combustion Engines",
    slot: "B1+TB1",
    ename: "NANTHA GOPAL K",
    venue: "GDNG08",
    ctype: "TH",
  },
  {
    crcode: "MEE3005",
    cname: "MEE3005 - Refrigeration and Airconditioning",
    slot: "D2+TD2+TDD2+V6",
    ename: "RAMESH KUMAR C",
    venue: "MB226",
    ctype: "TH",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L19+L20",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "L9+L10",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "GDNG19A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3006",
    cname: "MEE3006 - Automobile Engineering",
    slot: "B2",
    ename: "BIBHUTI BHUSAN SAHOO",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "L41+L42",
    ename: "DEEPA A",
    venue: "GDN130",
    ctype: "ELA",
  },
  {
    crcode: "MEE3012",
    cname: "MEE3012 - Computer Aided Manufacturing",
    slot: "D1",
    ename: "DEEPA A",
    venue: "MB218",
    ctype: "ETH",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L39+L40",
    ename: "NATARAJ G",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "L41+L42",
    ename: "NATARAJ G",
    venue: "GDN25A",
    ctype: "ELA",
  },
  {
    crcode: "MEE3015",
    cname: "MEE3015 - Automotive Engines",
    slot: "G1+TG1",
    ename: "NATARAJ G",
    venue: "MB211",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    ename: "RENOLD ELSEN S",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    ename: "SRINIVASAN NARAYANAN",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    ename: "VENKATESAN K",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    ename: "OYYARAVELU R",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    ename: "DEVENDIRAN S",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    ename: "CHIRANJEEVI C",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    ename: "RAJA SEKHAR Y",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    ename: "BIKASH ROUTH",
    venue: "MB111",
    ctype: "ELA",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L15+L16",
    ename: "RENOLD ELSEN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L19+L20",
    ename: "SRINIVASAN NARAYANAN",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L3+L4",
    ename: "VENKATESAN K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L31+L32",
    ename: "OYYARAVELU R",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L39+L40",
    ename: "DEVENDIRAN S",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L43+L44",
    ename: "CHIRANJEEVI C",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L51+L52",
    ename: "RAJA SEKHAR Y",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "L7+L8",
    ename: "BIKASH ROUTH",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "OYYARAVELU R",
    venue: "GDN128",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "DEVENDIRAN S",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "CHIRANJEEVI C",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F1",
    ename: "RAJA SEKHAR Y",
    venue: "MB213",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "VENKATESAN K",
    venue: "MB119",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "BIKASH ROUTH",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "RENOLD ELSEN S",
    venue: "GDNG08",
    ctype: "ETH",
  },
  {
    crcode: "MEE3501",
    cname: "MEE3501 - Product Development and Management",
    slot: "F2",
    ename: "SRINIVASAN NARAYANAN",
    venue: "GDN129",
    ctype: "ETH",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    ename: "ANANDAVEL K",
    venue: "MB109",
    ctype: "ELA",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "L11+L12",
    ename: "ANANDAVEL K",
    venue: "NIL",
    ctype: "EPJ",
  },
  {
    crcode: "MEE3502",
    cname: "MEE3502 - Design Process Planning and Management",
    slot: "G2",
    ename: "ANANDAVEL K",
    venue: "MB212",
    ctype: "ETH",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L19+L20",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "L3+L4",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB108",
    ctype: "ELA",
  },
  {
    crcode: "MEE4006",
    cname: "MEE4006 - Computational Fluid Dynamics",
    slot: "D2+TD2+V6",
    ename: "KEYUR BHANUPRASAD JOSHI",
    venue: "MB227",
    ctype: "ETH",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE4099",
    cname: "MEE4099 - Capstone Project",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "MEE6099",
    cname: "MEE6099 - Masters Thesis",
    slot: "NIL",
    ename: "PLACEMENT AND TRAINING",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "SET5003",
    cname: "SET5003 - Science, Engineering and Technology Project - III",
    slot: "NIL",
    ename: "ACADEMICS",
    venue: "NIL",
    ctype: "PJT",
  },
  {
    crcode: "ENG1000",
    cname: "ENG1000 - Foundation English - I",
    slot: "L11+L12+L27+L28",
    ename: "SRINIVASAN R",
    venue: "TT432",
    ctype: "LO",
  },
  {
    crcode: "ENG2000",
    cname: "ENG2000 - Foundation English - II",
    slot: "L33+L34+L47+L48",
    ename: "SANKAR G",
    venue: "SJT721",
    ctype: "LO",
  },
];
// let uniqueData = [...new Map(data.map((item) => [item["id"], item])).values()];
data.forEach((item, i) => {
  item.id = i + 1;
});
export default data;
