import "./App.css";
import SocialGroup from "./components/SocialGroup";
import TimeTable from "./components/table/TimeTable";
import Footer from "./components/footer/Footer";
import About from "./components/AboutApp/About";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Download from "./components/download/Download";
import { useRef } from "react";
import { useEffect } from "react";
import ReactGa from "react-ga";
import AdSense from 'react-adsense';

function App() {
  // let hello = () => {
  //   console.log("hello");
  // };

  useEffect(() => {
    ReactGa.initialize("UA-155744139-4");

    ReactGa.pageview("/timetable");
  }, []);

  const higherRef = useRef();

  return (
    <div className="App">
      <SocialGroup />
      <div className="mobileText">
        <h2 className="neonText">VITrendz FFCS Planner</h2>

        <h3>
          This web application is compatible only for screens with width higher
          than "666px". Please use a PC or notebook for best experience.
        </h3>
      </div>
      <div className="mobileVersion">
        <Router>
          <Route exact path="/downloadTimetable">
            <Download higherRef={higherRef} />
          </Route>
          <Route exact path="/">
            <TimeTable higherRef={higherRef} />
          </Route>
        </Router>
        <div id="dontPrint">
          <About />
          <AdSense.Google
            client="ca-pub-3524193275174890"
            slot="4162029724"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            format="auto"
            responsive="true"
            // layoutKey="-gw-1+2a-9x+5c"
          />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
