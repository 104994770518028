import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styles from "./wayTable.module.css";
import { makeStyles } from "@material-ui/core/styles";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// import { useStateValue } from "../../context/StateProvider";

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    color: "#ffffff",
    "&:hover": {},
  },
}));

function WayTable({ data }) {
  const classes = useStyles();
    let totalCredits= 0;

  return (
    <div>
      <div id="courseListTable" className={styles.courseList}>
        <div className={"table-responsive"}>
          <table className={styles.wayTable}>
            <thead className={styles.header}    >
              <tr >
                <th>Slot</th>
                <th>Code</th>
                <th>Course</th>
                <th>Faculty</th>
                <th>Venue</th>
                {/* <th>Credits</th> */}
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {data?.map((item) => {
                  totalCredits+=Number(item.C);
                return (
                  <tr>
                    <td>{item.slot}</td>
                    <td>{item.crcode}</td>
                    <td>{item.cname}</td>
                    <td>{item.ename}</td>
                    <td>{item.venue}</td>
                    {/* <td>{item.C}</td> */}
                  </tr>
                );
              })}
              
              {/* <tr>&nbsp;</tr>
              <tr>&nbsp;</tr> */}

              {/* <tr>
                <td colspan="7" className={styles.creditsTD}>
                  <strong>
                    Total Credits:
                    <span id="totalCredits" className={styles.credits}>
                      {totalCredits}
                    </span>
                  </strong>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default WayTable;
