import React from 'react'


function Download({higherRef}){
   
    return (
        <div></div>
        
    )
}

export default Download