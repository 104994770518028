import React from 'react';
import styles from './footer.module.css';

function Footer() {
    return (
        <div className={styles.mainFooter}>
        <div className={styles.container}>
          <div className={styles.row}>
            {/* Column1 */}
            <div className={styles.column1}>
              <h2 >Help and Support</h2>
              <ui style={{listStyleType:"none"}}>
                <li><a href="https://www.vitrendz.com/" target="_blank" rel="noreferrer" className={styles.helpText}>💻 &nbsp; Website</a></li>
                <li><a href="https://www.instagram.com/vitrendztech/?hl=en/" target="_blank" rel="noreferrer" className={styles.helpText}>📄 &nbsp; Technical Page  </a></li>
                <li><a href="mailto:vitrendstechnical@gmail.com" target="_blank" rel="noreferrer" className={styles.helpText}>📩 &nbsp; Email Us</a></li>
              </ui>
            </div>

            <div className={styles.column1}>
              <h2 >Release Notes</h2>
              <ui style={{listStyleType:"none"}}>
                <li className={styles.helpText}>v.2022.07.08 (version 4.0)</li>
                <li className={styles.helpText}>Release date : 08th July 2022, 11:25</li>
                <li className={styles.helpText}>Developers: VITrendz Tech</li>
              </ui>
            </div>
            {/* Column2 */}
            {/*<div className={styles.column2}>
              <h2 className={styles.neonText}>Contributors</h2>
              <ul style={{listStyleType:"none", display:"flex", width:"100%", marginTop:"-40px"}} >
                <div style={{display:'flex', flexDirection:"column",  justifyContent:"center"}}>
                  <h4 style={{margin:"none"}}>Tech</h4>
                  <li><a href="https://www.linkedin.com/in/aman-kuvera/" target="_blank" rel="noreferrer" className={styles.anchortags}>Aman Kuvera - </a></li>
                  <li><a href="https://www.linkedin.com/in/subhanu-sankar-roy/" target="_blank" rel="noreferrer" className={styles.anchortags}>Subhanu S Roy - </a></li>
                </div>
                <div   style={{display:'flex', flexDirection:"column", justifyContent:"center",marginLeft:"2rem"}}>
                  <h4 >Design</h4>
                  <li ><a href="https://www.linkedin.com/in/vansh-bajaj-a63474203/" target="_blank" rel="noreferrer" className={styles.anchortags}>Vansh Bajaj - </a></li>
                  <li><a href="https://www.linkedin.com/in/rohan-gupta-51b16615a/" target="_blank" rel="noreferrer" className={styles.anchortags}>Rohan Gupta - </a></li>
                  <li><a href="https://www.linkedin.com/in/harshagarwal5901/" target="_blank" rel="noreferrer" className={styles.anchortags}>Harsh Agarwal - </a></li>
                </div>
              </ul>
    </div>*/}
          </div>
          <div className={styles.row} style={{textAlign: "center", width:"100%",alignItems: "center"}}>
            <p style={{textAlign: "center", width:"100%"}}>
              &copy;{new Date().getFullYear()} Made with 💙 by VITrendz
            </p>
          </div>
        </div>
      </div>
    )
}

export default Footer
