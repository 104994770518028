import React, { useEffect, useRef, useState } from "react";

import Toast from "../toast/Toast";
import SuggestionsRanks from "../suggestionRanks/SuggestionsRanks";

import Cell from "../cell/Cell";
import CourseTable from "../coursetable/CourseTable";

import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";

import { useStateValue } from "../../context/StateProvider";

import { Link } from "react-router-dom";

import {
  IconButton,
  Tooltip,
  Button,
  Drawer,
  TextField,
  Box,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { QueueIcon, DeleteIcon } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import QueueIcon from "@material-ui/icons/Queue";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { FiFilter } from "react-icons/fi";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import AddCircleIcon from "@material-ui/icons/AddCircle";

// style={{ overflowX: "scroll" }}
import styles from "./timetable.module.css";
import winter22Data from "../../data/Max_data_Fall23";

import Popup from "../popup/Popup";
import AutoSlotForm from "../autoSlot/AutoSlotForm";
import { getList } from "../../services/list";
import axios from "axios";

import Input from "../../components/input/Input";
import { TabPanel } from "@material-ui/lab";

import { Pagination } from "@material-ui/lab";
import usePagination from "../Pagination/Pagination.js";
import wayTable from "../autoSlot/wayTable/WayTable";
import WayTable from "../autoSlot/wayTable/WayTable";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone,
// } from "react-bootstrap-table2-paginator";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  newTT: {
    color: "#ffffff",
    "&:hover": {},
  },
  filterSlot: {
    backgroundColor: "#7b1494",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#7b1494",
      color: "#fff",
      boxShadow: "0 8px 32px 0 rgba(0, 116, 227, 0.5)",
    },
  },
  addCourse: {
    backgroundColor: "rgba(0, 116, 227, 1)",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "rgba(0, 116, 227, 1)",
      color: "#ffffff",
      boxShadow: "0 8px 32px 0 rgba(0, 116, 227, 0.5)",
    },
  },
  autoSlotPickerBtn: {
    backgroundColor: "rgba(0, 116, 227, 1)",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "rgba(0, 116, 227, 1)",
      color: "#ffffff",
      boxShadow: "0 8px 32px 0 rgba(0, 116, 227, 0.5)",
    },
  },
  QuickVisualiseBtn: {
    backgroundColor: "rgb(56,142,60)",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "rgb(56,142,60)",
      color: "#ffffff",
      boxShadow: "0 8px 32px 0 rgb(56,142,60,0.5)",
    },
  },
  ToggleOtherFieldsBtn: {
    backgroundColor: "#ff5252",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#ff5252",
      color: "#ffffff",
      boxShadow: "0 8px 32px 0 rgb(255,82,82,0.5)",
    },
  },

  addCourseDrawerDiv: {
    width: "38rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    background: "rgba( 255, 255, 255, 0.05 )",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "center",
  },
  customCourseDrawerDiv: {
    width: "32rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    padding: "1rem",
    background: "rgba( 255, 255, 255, 0.05 )",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
  },
  addCustomCourseBtn: {
    backgroundColor: "#f7c028",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#f7c028",
      color: "#ffffff",
      // boxShadow: "0 8px 32px 0 ",
    },
  },
  autoDrawer: {
    height: "50%",
  },
}));

//dummy data for auto slot picker
const autoDummyData = [
  [
    {
      crcode: "ITE1002",
      cname: "Web Technologies",
      ctype: "ELA",
      C: "1",
      venue: "SJT216",
      slot: "L57+L58",
      "ERP ID": "10567",
      ename: "VIJAYAN R",
      id: "2803",
    },
    {
      crcode: "MAT2001",
      cname: "Statistics for Engineers",
      ctype: "ELA",
      C: "1",
      venue: "SJT620A",
      slot: "L39+L40",
      "ERP ID": "13721",
      ename: "EASWARAMOORTHY",
      id: "375",
    },
    {
      crcode: "ITE1003",
      cname: "Database Management Systems",
      ctype: "ELA",
      C: "1",
      venue: "SJT216",
      slot: "L55+L56",
      "ERP ID": "10134",
      ename: "BIMAL KUMAR RAY",
      id: "2813",
    },
    {
      crcode: "CSE1002",
      cname: "Problem Solving and Object Oriented P",
      ctype: "LO",
      C: "3",
      venue: "GDN153",
      slot: "L41+L42+L43+L44+L53+L54",
      "ERP ID": "16337",
      ename: "MAHENTHIRAN S",
      id: "1190",
    },
    {
      crcode: "ITE1002",
      cname: "Web Technologies",
      ctype: "ETH",
      C: "2",
      venue: "SJT201",
      slot: "A1",
      "ERP ID": "10567",
      ename: "VIJAYAN R",
      id: "2802",
    },
    {
      crcode: "STS2021",
      cname: "Fundamentals of Aptitude",
      ctype: "SS",
      C: "1",
      venue: "SJT114",
      slot: "E1+TE1",
      "ERP ID": "ETHNUS",
      ename: "ETHNUS (APT)",
      id: "79",
    },
    {
      crcode: "MAT2001",
      cname: "Statistics for Engineers",
      ctype: "ETH",
      C: "3",
      venue: "CDMM301",
      slot: "F1+TF1",
      "ERP ID": "13721",
      ename: "EASWARAMOORTHY",
      id: "347",
    },
    {
      crcode: "ITE1003",
      cname: "Database Management Systems",
      ctype: "ETH",
      C: "2",
      venue: "SJT205",
      slot: "G1",
      "ERP ID": "10134",
      ename: "BIMAL KUMAR RAY",
      id: "2812",
    },
  ],
  [
    {
      crcode: "ITE1002",
      cname: "Web Technologies",
      ctype: "ELA",
      C: "1",
      venue: "SJT216",
      slot: "L57+L58",
      "ERP ID": "10567",
      ename: "VIJAYAN R",
      id: "2803",
    },
    {
      crcode: "MAT2001",
      cname: "Statistics for Engineers",
      ctype: "ELA",
      C: "1",
      venue: "SJT620A",
      slot: "L39+L40",
      "ERP ID": "13721",
      ename: "EASWARAMOORTHY",
      id: "375",
    },
    {
      crcode: "ITE1003",
      cname: "Database Management Systems",
      ctype: "ELA",
      C: "1",
      venue: "SJT216",
      slot: "L55+L56",
      "ERP ID": "10134",
      ename: "BIMAL KUMAR RAY",
      id: "2813",
    },
    {
      crcode: "CSE1002",
      cname: "Problem Solving and Object Oriented P",
      ctype: "LO",
      C: "3",
      venue: "SMV110",
      slot: "L41+L42+L43+L44+L53+L54",
      "ERP ID": "14831",
      ename: "MONASH P",
      id: "1191",
    },
    {
      crcode: "ITE1002",
      cname: "Web Technologies",
      ctype: "ETH",
      C: "2",
      venue: "SJT201",
      slot: "A1",
      "ERP ID": "10567",
      ename: "VIJAYAN R",
      id: "2802",
    },
    {
      crcode: "STS2021",
      cname: "Fundamentals of Aptitude",
      ctype: "SS",
      C: "1",
      venue: "SJT114",
      slot: "E1+TE1",
      "ERP ID": "ETHNUS",
      ename: "ETHNUS (APT)",
      id: "79",
    },
    {
      crcode: "MAT2001",
      cname: "Statistics for Engineers",
      ctype: "ETH",
      C: "3",
      venue: "CDMM301",
      slot: "F1+TF1",
      "ERP ID": "13721",
      ename: "EASWARAMOORTHY",
      id: "347",
    },
    {
      crcode: "ITE1003",
      cname: "Database Management Systems",
      ctype: "ETH",
      C: "2",
      venue: "SJT205",
      slot: "G1",
      "ERP ID": "10134",
      ename: "BIMAL KUMAR RAY",
      id: "2812",
    },
  ],
  [
    {
      crcode: "ITE1002",
      cname: "Web Technologies",
      ctype: "ELA",
      C: "1",
      venue: "SJT216",
      slot: "L57+L58",
      "ERP ID": "10567",
      ename: "VIJAYAN R",
      id: "2803",
    },
    {
      crcode: "MAT2001",
      cname: "Statistics for Engineers",
      ctype: "ELA",
      C: "1",
      venue: "SJT620A",
      slot: "L39+L40",
      "ERP ID": "13721",
      ename: "EASWARAMOORTHY",
      id: "375",
    },
    {
      crcode: "ITE1003",
      cname: "Database Management Systems",
      ctype: "ELA",
      C: "1",
      venue: "SJT216",
      slot: "L55+L56",
      "ERP ID": "10134",
      ename: "BIMAL KUMAR RAY",
      id: "2813",
    },
    {
      crcode: "CSE1002",
      cname: "Problem Solving and Object Oriented P",
      ctype: "LO",
      C: "3",
      venue: "SJT517",
      slot: "L31+L32+L41+L42+L45+L46",
      "ERP ID": "13298",
      ename: "JABANJALIN HILDA J",
      id: "1194",
    },
    {
      crcode: "ITE1002",
      cname: "Web Technologies",
      ctype: "ETH",
      C: "2",
      venue: "SJT201",
      slot: "A1",
      "ERP ID": "10567",
      ename: "VIJAYAN R",
      id: "2802",
    },
    {
      crcode: "STS2021",
      cname: "Fundamentals of Aptitude",
      ctype: "SS",
      C: "1",
      venue: "SJT114",
      slot: "E1+TE1",
      "ERP ID": "ETHNUS",
      ename: "ETHNUS (APT)",
      id: "79",
    },
    {
      crcode: "MAT2001",
      cname: "Statistics for Engineers",
      ctype: "ETH",
      C: "3",
      venue: "CDMM301",
      slot: "F1+TF1",
      "ERP ID": "13721",
      ename: "EASWARAMOORTHY",
      id: "347",
    },
    {
      crcode: "ITE1003",
      cname: "Database Management Systems",
      ctype: "ETH",
      C: "2",
      venue: "SJT205",
      slot: "G1",
      "ERP ID": "10134",
      ename: "BIMAL KUMAR RAY",
      id: "2812",
    },
  ],
];

const columns = [
  { field: "slot", headerName: "Slot", width: 170 },
  { field: "cname", headerName: "Course", width: 200 },
  { field: "ename", headerName: "Faculty Name", width: 230 },
  {
    field: "venue",
    headerName: "Venue",
    width: 120,
  },
];
const filterBySlotColumns = [
  { field: "slot", headerName: "Slot", width: 160 },
  { field: "cname", headerName: "Course", width: 320 },
  { field: "crcode", headerName: "Code", width: 110 },
  { field: "ename", headerName: "Faculty Name", width: 200 },
];
// const [filteredData, setFilteredData] = useState([]);
function TimeTable({ higherRef }) {
  const downloadTTRef = useRef();

  const classes = useStyles();
  // "container-fluid text-center noselect"

  const [{ rowID, monday, tuesday, wednesday, thursday, friday }, dispatch] =
    useStateValue();

  const [credits, setCredits] = useState(0);
  const [customCode, setCustomCode] = useState("");
  const [customFaculty, setCustomFaculty] = useState("");
  const [customSlot, setCustomSlot] = useState("");
  const [customCredits, setCustomCredits] = useState("");
  const [customId, setCustomId] = useState(10000);
  const [customCourse, setCustomCourse] = useState("");
  const [customObj, setCustomObj] = useState({});
  const [customVenue, setCustomVenue] = useState();

  const [courseAdded, setCourseAdded] = useState(false);
  const [alreadyAdded, setAlreadyAdded] = useState(false);

  const [newArray1, setNewArray1] = useState(monday);
  const [newArray2, setNewArray2] = useState(tuesday);
  const [newArray3, setNewArray3] = useState(wednesday);
  const [newArray4, setNewArray4] = useState(thursday);
  const [newArray5, setNewArray5] = useState(friday);

  //this ia the state for updating the response object from backend
  const [possibleWays, setpossibleWays] = useState(autoDummyData);

  //this is if the object is empty
  const [emptyObj, setEmptyObj] = useState();

  useEffect(() => {
    setNewArray1(monday);
    setNewArray2(tuesday);
    setNewArray3(wednesday);
    setNewArray4(thursday);
    setNewArray5(friday);
  }, [monday, tuesday, wednesday, thursday, friday]);

  const separateSlots = (element) => {
    let arraySlots = [],
      i;
    arraySlots = element?.split("+");
    return arraySlots;
  };

  const getClashedSlot = (slotArr) => {
    let i,
      j,
      found = false;
    for (i = 0; i < currentSlot.length; i++) {
      for (j = 0; j < slotArr.length; j++) {
        if (currentSlot[i].row.slot.includes(slotArr[j])) {
          setClashedWith(currentSlot[i].row);
          found = true;
          break;
        }
      }
      if (found === true) {
        break;
      }
    }
  };

  const updateRowSelected = (id) => {
    if (currentSlot.length !== 0) {
      let i,
        j,
        flag = false;

      let newSeparated = separateSlots(id.row.slot);
      for (i = 0; i < currentSlot.length; i++) {
        if (currentSlot[i].row.id === id.row.id) {
          setAlreadyAdded(true);
          // window.alert("You have already added this course");
          // console.log("clashed")
          setaddCourseDrawerState(false);
          flag = true;
          setSuggestionsToast(false);
          break;
        }

        let currentSeparated = separateSlots(currentSlot[i].row.slot);

        // console.log(currentSeparated);
        // console.log(newSeparated);

        for (j = 0; j < newSeparated?.length; j++) {
          if (newSeparated[j] !== "NIL") {
            if (currentSeparated?.includes(newSeparated[j])) {
              setClashed(true);
              setClashedWith(currentSlot[i].row);
              setaddCourseDrawerState(false);
              flag = true;
              setSuggestionsToast(false);
              break;
            }
          }
        }
      }

      if (flag === false) {
        newArray1?.map((a) => {
          let k = 0;
          for (k = 0; k < newSeparated?.length; k++) {
            if (a.splitSlots?.includes(newSeparated[k])) {
              if (a.selected === true) {
                setClashed(true);
                getClashedSlot(a.splitSlots);
                setaddCourseDrawerState(false);
                flag = true;
                setSuggestionsToast(false);
              }
            }
          }
        });
      }
      if (flag === false) {
        newArray2?.map((a) => {
          let k = 0;
          for (k = 0; k < newSeparated?.length; k++) {
            if (a.splitSlots?.includes(newSeparated[k])) {
              if (a.selected === true) {
                setClashed(true);
                getClashedSlot(a.splitSlots);
                setaddCourseDrawerState(false);
                flag = true;
                setSuggestionsToast(false);
              }
            }
          }
        });
      }

      if (flag === false) {
        newArray3?.map((a) => {
          let k = 0;
          for (k = 0; k < newSeparated?.length; k++) {
            if (a.splitSlots?.includes(newSeparated[k])) {
              if (a.selected === true) {
                setClashed(true);
                getClashedSlot(a.splitSlots);
                setaddCourseDrawerState(false);
                flag = true;
                setSuggestionsToast(false);
              }
            }
          }
        });
      }

      if (flag === false) {
        newArray4?.map((a) => {
          let k = 0;
          for (k = 0; k < newSeparated?.length; k++) {
            if (a.splitSlots?.includes(newSeparated[k])) {
              if (a.selected === true) {
                setClashed(true);
                getClashedSlot(a.splitSlots);
                setaddCourseDrawerState(false);
                flag = true;
                setSuggestionsToast(false);
              }
            }
          }
        });
      }

      if (flag === false) {
        newArray5?.map((a) => {
          let k = 0;
          for (k = 0; k < newSeparated?.length; k++) {
            if (a.splitSlots?.includes(newSeparated[k])) {
              if (a.selected === true) {
                setClashed(true);
                getClashedSlot(a.splitSlots);
                setaddCourseDrawerState(false);
                flag = true;
                setSuggestionsToast(false);
              }
            }
          }
        });
      }

      if (flag === false) {
        setCurrentSlot([...currentSlot, id]);
        setaddCourseDrawerState(false);
        setCredits(credits + parseInt(id.row.C, 10));
        setCourseAdded(true);
        setSuggestionsToast(false);
        // console.log("added")
      }
    } else {
      setCurrentSlot([...currentSlot, id]);
      setaddCourseDrawerState(false);
      // console.log("added")
      setCredits(credits + parseInt(id.row.C, 10));
      setCourseAdded(true);
      setSuggestionsToast(false);
    }
  };

  const addCustom = () => {
    if (
      !customCode |
      !customCourse |
      !customSlot |
      !customFaculty |
      !customCredits
    ) {
      window.alert("Please enter valid details");
    } else {
      let arraySlots = [],
        i,
        trimmedSlot;
      arraySlots = customSlot?.split("+");
      trimmedSlot = arraySlots[0].trim();

      for (i = 1; i < arraySlots.length; i++) {
        trimmedSlot = trimmedSlot.concat("+", arraySlots[i].trim()); //L1+ L2+ L3 +L4
        // console.log(arraySlots[i].trim());
      }

      // console.log(trimmedSlot);

      updateRowSelected({
        courseType: "custom",
        row: {
          courseowner: "VIT",
          crcode: customCode,
          cname: customCourse,
          ctype: "ETH",
          slot: trimmedSlot,
          ename: customFaculty,
          id: customId.toString(),
          C: customCredits,
          venue: customVenue,
        },
      });
      setCustomId(customId + 1);
      setLeftState(false);
    }
    // console.log(customObj);
  };

  const valueRef = useRef(""); //creating a refernce for TextField Component

  // to get the data from api and store in a addCourseDrawerState variable
  const [list, setList] = useState([]);

  //cuurent slot is an empty array
  const [currentSlot, setCurrentSlot] = useState([]);
  // const [isChosen, setIsChosen] = useState(false);

  //below hook for the drawer option of add course
  const [addCourseDrawerState, setaddCourseDrawerState] = useState(false);

  const [leftState, setLeftState] = useState(false);

  const [autoDrawerState, setautoDrawerState] = useState(false);

  const [findBySlotState, setFindBySlotState] = useState(false);

  const [crcodeInput, setcrcodeInput] = useState("");

  const [openPopup, setOpenPopup] = useState(false);

  const [crCode, setCrCode] = useState("");

  //resetTimeTable
  const mondayTemp = [
    { slot: "A1/L1", splitSlots: ["A1", "L1"], selected: false },
    { slot: "F1/L2", splitSlots: ["F1", "L2"], selected: false },
    { slot: "D1/L3", splitSlots: ["D1", "L3"], selected: false },
    { slot: "TB1/L4", splitSlots: ["TB1", "L4"], selected: false },
    { slot: "TG1/L5", splitSlots: ["TG1", "L5"], selected: false },
    { slot: "L6", splitSlots: ["L6"], selected: false },
    { slot: "A2/L31", splitSlots: ["A2", "L31"], selected: false },
    { slot: "F2/L32", splitSlots: ["F2", "L32"], selected: false },
    { slot: "D2/L33", splitSlots: ["D2", "L33"], selected: false },
    { slot: "TG2/L35", splitSlots: ["TG2", "L35"], selected: false },
    { slot: "V3/L36", splitSlots: ["V3", "L36"], selected: false },
  ];

  const tuesdayTemp = [
    { slot: "B1/L7", splitSlots: ["B1", "L7"], selected: false },
    { slot: "G1/L8", splitSlots: ["G1", "L8"], selected: false },
    { slot: "E1/L9", splitSlots: ["E1", "L9"], selected: false },
    { slot: "TC1/L10", splitSlots: ["TC1", "L10"], selected: false },
    { slot: "TAA1/L11", splitSlots: ["TAA1", "L11"], selected: false },
    { slot: "L12", splitSlots: ["L12"], selected: false },
    { slot: "B2/L37", splitSlots: ["B2", "L37"], selected: false },
    { slot: "G2/L38", splitSlots: ["G2", "L38"], selected: false },
    { slot: "E2/L39", splitSlots: ["E2", "L39"], selected: false },
    { slot: "TC2/L40", splitSlots: ["TC2", "L40"], selected: false },
    { slot: "TAA2/L41", splitSlots: ["TAA2", "L41"], selected: false },
    { slot: "V4/L42", splitSlots: ["V4", "L42"], selected: false },
  ];

  const wednesdayTemp = [
    { slot: "C1/L13", splitSlots: ["C1", "L13"], selected: false },
    { slot: "A1/L14", splitSlots: ["A1", "L14"], selected: false },
    { slot: "F1/L15", splitSlots: ["F1", "L15"], selected: false },
    { slot: "V1/L16", splitSlots: ["V1", "L16"], selected: false },
    { slot: "V2/L17", splitSlots: ["V2", "L17"], selected: false },
    { slot: "L18", splitSlots: ["L18"], selected: false },
    { slot: "C2/L43", splitSlots: ["C2", "L43"], selected: false },
    { slot: "A2/L44", splitSlots: ["A2", "L44"], selected: false },
    { slot: "F2/L45", splitSlots: ["F2", "L45"], selected: false },
    { slot: "TD2/L46", splitSlots: ["TD2", "L46"], selected: false },
    { slot: "TBB2/L47", splitSlots: ["TBB2", "L47"], selected: false },
    { slot: "V5/L48", splitSlots: ["V5", "L48"], selected: false },
  ];

  const thursdayTemp = [
    { slot: "D1/L19", splitSlots: ["D1", "L19"], selected: false },
    { slot: "B1/L20", splitSlots: ["B1", "L20"], selected: false },
    { slot: "G1/L21", splitSlots: ["G1", "L21"], selected: false },
    { slot: "TE1/L22", splitSlots: ["TE1", "L22"], selected: false },
    { slot: "TCC1/L23", splitSlots: ["TCC1", "L23"], selected: false },
    { slot: "L24", splitSlots: ["L24"], selected: false },
    { slot: "D2/L49", splitSlots: ["D2", "L49"], selected: false },
    { slot: "B2/L50", splitSlots: ["B2", "L50"], selected: false },
    { slot: "G2/L51", splitSlots: ["G2", "L51"], selected: false },
    { slot: "TE2/L52", splitSlots: ["TE2", "L52"], selected: false },
    { slot: "TCC2/L53", splitSlots: ["TCC2", "L53"], selected: false },
    { slot: "V6/L54", splitSlots: ["V6", "L54"], selected: false },
  ];

  const fridayTemp = [
    { slot: "E1/L25", splitSlots: ["E1", "L25"], selected: false },
    { slot: "C1/L26", splitSlots: ["C1", "L26"], selected: false },
    { slot: "TA1/L27", splitSlots: ["TA1", "L27"], selected: false },
    { slot: "TF1/L28", splitSlots: ["TF1", "L28"], selected: false },
    { slot: "TD1/L29", splitSlots: ["TD1", "L29"], selected: false },
    { slot: "L30", splitSlots: ["L30"], selected: false },
    { slot: "E2/L55", splitSlots: ["E2", "L55"], selected: false },
    { slot: "C2/L56", splitSlots: ["C2", "L56"], selected: false },
    { slot: "TA2/L57", splitSlots: ["TA2", "L57"], selected: false },
    { slot: "TF2/L58", splitSlots: ["TF2", "L58"], selected: false },
    { slot: "TDD2/L59", splitSlots: ["TDD2", "L59"], selected: false },
    { slot: "V7/L60", splitSlots: ["V7", "L60"], selected: false },
  ];
  // useEffect(() => {
  //   console.log(monday)
  // },[currentSlot])

  const [clashed, setClashed] = useState(false);
  const [clashedWith, setClashedWith] = useState({});

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const onToastMessageReceive = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };

  useEffect(() => {
    // clashed &&
    //   window.alert(
    //     `Selected course slots have clashed with ${clashedWith.slot} (${clashedWith.cname}). Please remove the clashed course from your list to add this one!`
    //   );
    // setClashed(false);
    // setClashedWith({});
    if (courseAdded | clashed | alreadyAdded | showToast) resetToast();
  }, [courseAdded, clashed, alreadyAdded, showToast]);

  const resetToast = () => {
    setTimeout(() => {
      setClashed(false);
      setAlreadyAdded(false);
      setCourseAdded(false);
      setClashedWith({});
      setShowToast(false);
    }, 5000);
  };

  const toggleaddCourseDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setaddCourseDrawerState(open);
  };
  const toggleLeftDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLeftState(open);
  };
  const toggleFindBySlotDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setFindBySlotState(open);
  };
  const toggleAutoDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setautoDrawerState(open);
  };

  const [inputDropDownData, setInputDropDownData] = useState([]);

  const [filterBySlotDropdownData, setFilterBySlotDropdownData] = useState([]);

  const generateDropDownData = () => {
    let i, j;

    let arr = [];
    arr.push(list[0]);

    for (i = 1; i < list.length; i++) {
      let flag = 1;
      for (j = 0; j < arr.length; j++) {
        if (arr[j].crcode === list[i].crcode) {
          flag++;
        }
      }
      if (flag === 1) {
        arr.push(list[i]);
      }
    }
    setInputDropDownData(arr);
    // console.log(inputDropDownData)
  };
  const generateFilterBySlotDropDownData = () => {
    let i, j;

    let arr = [];
    arr.push(list[0]);

    for (i = 1; i < list.length; i++) {
      let flag = 1;
      for (j = 0; j < arr.length; j++) {
        if (arr[j].slot === list[i].slot) {
          flag++;
        }
      }
      if (flag === 1) {
        arr.push(list[i]);
      }
    }
    let uniqueSlotArr = [...new Set(arr)];
    setFilterBySlotDropdownData(uniqueSlotArr);
    // console.log(inputDropDownData)
  };

  useEffect(() => {
    generateDropDownData();
    generateFilterBySlotDropDownData();
  }, [list]);

  // const pullData = async () => {
  //   await axios
  //     .get("https://sheetdb.io/api/v1/s7muy5xe7j3qj")
  //     .then((response) => {
  //       // setList(response.data);
  //       // setList(dummyData);
  //     })
  //     .catch((err) => console.log(err));
  // };
  //usefeect hook to set the list of data
  useEffect(() => {
    // let mounted = true;
    // getList().then((items) => {
    //   if (mounted) {
    //     setList(items);
    //   }
    // });
    // return () => (mounted = false);
    // console.log(winter22Data)
    setList(winter22Data);
    // pullData();
  }, []);

  const [facultyData, setFacultyData] = useState();
  const [suggestions, setSuggestions] = useState();
  const [suggestionsToast, setSuggestionsToast] = useState(false);

  const getFacultyData = async () => {
    await axios
      .get(process.env.REACT_APP_FACULLTYREVIEW)
      .then((response) => {
        setFacultyData(response.data);
      })
      .catch((err) => {
        setFacultyData([]);
        console.log(err);
      });
  };

  useEffect(() => {
    getFacultyData();
  }, []);

  useEffect(() => {
    if (crCode !== "") {
      getSuggestions();
      setSuggestionsToast(true);
    }
  }, [crCode]);

  useEffect(() => {
    if (!addCourseDrawerState) setSuggestionsToast(false);
    setCrCode("");
  }, [addCourseDrawerState]);

  const getSuggestions = () => {
    let availableFaculty = [],
      i,
      j;
    let selectedFaculty = [];

    for (i = 0; i < list.length; i++) {
      if (list[i].crcode === crCode) {
        availableFaculty.push(list[i].ename);
      }
    }

    // console.log(availableFaculty);
    if (facultyData) {
      for (i = 0; i < availableFaculty.length; i++) {
        // console.log(crCode);
        for (j = 0; j < facultyData.length; j++) {
          if (
            facultyData[j].coursecode === crCode &&
            facultyData[j].facultyname === availableFaculty[i]
          ) {
            // console.log(facultyData[j]);
            selectedFaculty.push(facultyData[j]);
          }
        }
      }
    }

    setSuggestions(selectedFaculty);
    // console.log(selectedFaculty);
  };

  const [filterRows, setFilterRows] = useState(list);
  const [filterBySlotRows, setFilterBySlotRows] = useState(list);

  //state for updating preffered slot
  const [prefSlot, setPrefSlot] = useState("");
  const [prefCourse, setPrefCourse] = useState("");
  const [prefFaculty, setPrefFaculty] = useState("");

  //crCodeMatch is for searching through rows
  const updateRows = (crcodeMatch) => {
    const newRows = list?.filter((item) => item.crcode.includes(crcodeMatch));
    setFilterRows(newRows);

    // return filterRows;
  };

  useEffect(() => {
    updateRows(crCode);
  }, [crCode]);

  //function for updating filter by slot rows
  const updateFilterBySlotRows = () => {
    if (prefSlot === "" && prefCourse === "" && prefFaculty === "") {
      setFilterBySlotRows([]);
    } else {
      let temp = list?.filter(
        (item) =>
          item.slot.includes(prefSlot) &&
          item.crcode.includes(prefCourse) &&
          item.ename.includes(prefFaculty)
      );
      setFilterBySlotRows([...temp]);
    }

    // let crCodes = [];
    // temp?.filter((item) => {
    //   // var crCodes = [];
    //   crCodes.push(item.crcode);
    //   // console.log(crCodes);
    // });
    // let uniqueCrCodes = [...new Set(crCodes)];
    // console.log(uniqueCrCodes);

    // const filterBySlotArr = temp.filter((item) => {
    //   let tempcode = item.crcode;
    //   uniqueCrCodes.map((code) => {
    //     if (code == tempcode) {
    //       return item;
    //     }
    //   });
    // });
    // console.log(filterBySlotArr);
  };

  useEffect(() => {
    updateFilterBySlotRows();
  }, [prefSlot, prefCourse, prefFaculty]);

  const getFaculty = (event) => {
    updateRows(crcodeInput);
    event.preventDefault(); //will stop the REFRESH
  };

  const resetTable = () => {
    dispatch({
      type: "RESET_ALL",
      reset1: mondayTemp,
      reset2: tuesdayTemp,
      reset3: wednesdayTemp,
      reset4: thursdayTemp,
      reset5: fridayTemp,
    });

    setCurrentSlot([]);
    setCredits(0);
  };

  // functioning of the tabs in autoslot
  // const [tabValue, setTabValue] = useState(0);
  // const handleTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ p: 3 }}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }

  // // TabPanel.propTypes = {
  // //   children: PropTypes.node,
  // //   index: PropTypes.number.isRequired,
  // //   value: PropTypes.number.isRequired,
  // // };
  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     "aria-controls": `simple-tabpanel-${index}`,
  //   };
  // }

  //
  //dummy data for auto slot

  const [waysPage, setWaysPage] = useState(1);

  const PER_PAGE = 24;

  const count = Math.ceil(possibleWays.length / PER_PAGE);
  const _DATA = usePagination(possibleWays, PER_PAGE);

  const handleWayChange = (e, p) => {
    setWaysPage(p);
    _DATA.jump(p);
  };

  //hook to check if data has been sent to backend
  const [dataSent, setDataSent] = useState(false);
  const setDataState = () => {
    setDataSent(true);
  };

  const printTable = () => {
    window.print();
  };

  return (
    <>
      <div ref={higherRef} id="snapTable">
        <h2 className={styles.neonText}>VITrendz FFCS Planner</h2>
        <div className={styles.buttonDiv}>
          <Tooltip title="Find courses for desired slot" placement="top" arrow>
            <Button
              variant="contained"
              startIcon={<FiFilter />}
              className={classes.filterSlot}
              onClick={toggleFindBySlotDrawer(true)}
            >
              Filter By Slot / Faculty
            </Button>
          </Tooltip>

          <Drawer
            anchor={"left"}
            open={findBySlotState}
            onClose={toggleFindBySlotDrawer(false)}
            className={styles.leftDrawer}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                overflowX: "hidden",
                height: "100%",
                width: "60rem",
                flexDirection: "column",
                // backgroundColor: "rgb(0,0,0,0.2)",
                // paddingLeft: "20px",
                // paddingRight: "20px",
              }}
              className={styles.innerRightDrawer}
            >
              <h2 style={{ textAlign: "center" }}>😎 My Custom Filters</h2>
              <div
                className={classes.filterSlotDrawerDiv}
                style={{
                  // overflowY: "hidden",
                  width: "55rem",
                  // height:"80rem"
                }}
              >
                <ul style={{ listStyleType: "none", marginLeft: "-20px" }}>
                  <li style={{ marginBottom: "4px" }}>
                    😖 &nbsp; Have a free slot? Need a course?
                  </li>

                  <li style={{ marginBottom: "4px" }}>
                    🤯 &nbsp; Want to know all the management courses offered in
                    a slot?
                  </li>

                  <li style={{ marginBottom: "4px" }}>
                    👩‍🏫 &nbsp; Do you want to know all courses your favorite
                    faculty is taking this sem?
                  </li>

                  <li>
                    💥 &nbsp; Enter your custom filters and know it all in one
                    go!!
                  </li>
                </ul>
                <div style={{ width: "100%" }}>
                  <Tooltip title="ex. A1+TA1+TAA1" placement="top" arrow>
                    <input
                      className={styles.customCourseInput}
                      // list={list}
                      value={prefSlot}
                      onChange={(e) =>
                        setPrefSlot(e.target.value.toUpperCase())
                      }
                      placeholder="Enter Slot in + format"
                      required
                    />
                  </Tooltip>
                  <Tooltip title="ex. MGT, HUM,..." placement="top" arrow>
                    <input
                      className={styles.customCourseInput}
                      // list={list}
                      value={prefCourse}
                      onChange={(e) =>
                        setPrefCourse(e.target.value.toUpperCase())
                      }
                      placeholder="First three letters of code"
                      required
                    />
                  </Tooltip>
                  <Tooltip title="ex. SMART, FACE,..." placement="top" arrow>
                    <input
                      className={styles.customCourseInput}
                      // list={list}
                      value={prefFaculty}
                      onChange={(e) =>
                        setPrefFaculty(e.target.value.toUpperCase())
                      }
                      placeholder="Your favourite faculty name"
                      required
                    />
                  </Tooltip>
                </div>
                {/* <pre>{JSON.stringify(crcodeInput)}</pre>
              <button onClick={getFaculty}>GO</button> */}
                <div style={{ height: 360, width: "99%", margin: "4px" }}>
                  <DataGrid
                    rows={filterBySlotRows}
                    getRowId={(list) => list.id}
                    columns={filterBySlotColumns}
                    pageSize={8}
                    checkboxSelection={false}
                    // onRowClick={(row) => updateRowSelected(row)}
                  />
                </div>
              </div>
            </div>
          </Drawer>
          <div className={styles.tinyBtnDiv}>
            {/* <Tooltip title="Add New Timetable" placement="top" arrow>
              <IconButton>
                <QueueIcon className={classes.newTT} />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Delete Timetable" placement="left" arrow>
              <IconButton onClick={resetTable}>
                <DeleteIcon className={classes.newTT} />
              </IconButton>
            </Tooltip>

            <div id="dontPrintBtn">
              <Tooltip
                title="Screenshot Timetable (enable background graphics)"
                placement="top"
                arrow
              >
                {/* <Link to="/downloadTimetable"> */}
                <IconButton onClick={printTable}>
                  <AspectRatioIcon className={classes.newTT} />
                </IconButton>
                {/* </Link> */}
              </Tooltip>
            </div>
          </div>

          <Button
            variant="contained"
            startIcon={<ControlPointIcon />}
            className={classes.addCourse}
            onClick={toggleaddCourseDrawer(true)}
          >
            Add Course
          </Button>
          <Drawer
            anchor={"right"}
            open={addCourseDrawerState}
            onClose={toggleaddCourseDrawer(false)}
            className={styles.rightDrawer}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                overflowX: "hidden",
                height: "100%",
                width: "100%",
                flexDirection: "column",
                // backgroundColor: "rgb(0,0,0,0.2)",
                // paddingLeft: "20px",
                // paddingRight: "20px",
              }}
              className={styles.innerRightDrawer}
            >
              <h2 style={{ textAlign: "center" }}>Add Course</h2>
              <div
                className={classes.addCourseDrawerDiv}
                style={
                  {
                    // overflowY: "hidden",
                  }
                }
              >
                <ul style={{ listStyleType: "none", marginLeft: "-20px" }}>
                  <li style={{ marginBottom: "4px" }}>
                    🔢 &nbsp; Enter course code you want to add
                  </li>

                  <li>👨‍🏫 &nbsp; Select faculty-slot combo you want</li>
                </ul>
                <div style={{ width: "90%" }}>
                  <Input
                    className={classes.inputFieldCrCode}
                    list={inputDropDownData}
                    codeSelected={crCode}
                    setCodeSelected={setCrCode}
                    placeholder="Enter course code"
                    style={{
                      outline: "none !important",
                      border: "none !important",
                    }}
                  />
                  {/* <Input
                    className={classes.inputFieldCrCode}
                    list={filterBySlotDropdownData}
                    codeSelected={prefSlot}
                    setCodeSelected={setPrefSlot}
                    placeholder="Enter preffered slot ex.A1"
                    style={{
                      outline: "none !important",
                      border: "none !important",
                    }}
                  /> */}
                </div>
                {/* <pre>{JSON.stringify(crcodeInput)}</pre>
              <button onClick={getFaculty}>GO</button> */}
                <div style={{ height: 360, width: "99%", margin: "4px" }}>
                  <DataGrid
                    rows={filterRows}
                    getRowId={(list) => list.id}
                    columns={columns}
                    pageSize={5}
                    checkboxSelection={false}
                    onRowClick={(row) => updateRowSelected(row)}
                  />
                </div>
                {/* <Tooltip title="Add this course" placement="left" arrow>
                <IconButton style={{ float: "right" }}>
                  <AddCircleIcon style={{ colo: "#ffffff" }} />
                </IconButton>
              </Tooltip> */}
              </div>
            </div>
          </Drawer>
          {/* <button className={styles.buttonLook}>
            <DeleteIcon className={classes.newTT} />
            Add Course
          </button> */}
        </div>

        <div className={styles.container}>
          <div id="timetable" className={styles.timetableDiv}>
            <table className={styles.mainTable}>
              <tr className={styles.headerRow}>
                <td className={styles.firstColumn}>
                  Theory <br />
                  Hours
                </td>
                <td className={styles.theoryHours}>
                  08:00 AM
                  <br />
                  08:50 AM
                </td>
                <td className={styles.theoryHours}>
                  09:00 AM
                  <br />
                  09:50 AM
                </td>
                <td className={styles.theoryHours}>
                  10:00 AM
                  <br />
                  10:50 AM
                </td>
                <td className={styles.theoryHours}>
                  11:00 AM
                  <br />
                  11:50 AM
                </td>
                <td className={styles.theoryHours}>
                  12:00 PM
                  <br />
                  12:50 PM
                </td>
                <td className={styles.theoryHours}>
                  -----
                  <br />
                  -----
                </td>
                <td width="8px" rowspan="9" classname={styles.lunch}>
                  <strong style={{ color: "#ffffff" }}>
                    L <br />U <br />N <br />C <br />H
                  </strong>
                </td>
                <td className={styles.theoryHours}>
                  02:00 PM
                  <br />
                  02:50 PM
                </td>
                <td className={styles.theoryHours}>
                  03:00 PM
                  <br />
                  03:50 PM
                </td>
                <td className={styles.theoryHours}>
                  04:00 PM
                  <br />
                  04:50 PM
                </td>
                <td className={styles.theoryHours}>
                  05:00 PM
                  <br />
                  05:50 PM
                </td>
                <td className={styles.theoryHours}>
                  06:00 PM
                  <br />
                  06:50 PM
                </td>
                <td className={styles.theoryHours}>
                  07:00 PM
                  <br />
                  07:50 PM
                </td>
              </tr>
              <tr className={styles.headerRow}>
                <td className={styles.firstColumn}>
                  Lab <br />
                  Hours
                </td>
                <td className={styles.labHours}>
                  08:00 AM
                  <br />
                  08:45 AM
                </td>
                <td className={styles.labHours}>
                  08:46 AM
                  <br />
                  09:30 AM
                </td>
                <td className={styles.labHours}>
                  10:00 AM
                  <br />
                  10:45 AM
                </td>
                <td className={styles.labHours}>
                  10:46 AM
                  <br />
                  11:30 AM
                </td>
                <td className={styles.labHours}>
                  11:31 AM
                  <br />
                  12:15 PM
                </td>
                <td className={styles.labHours}>
                  12:16 PM
                  <br />
                  01:00 PM
                </td>
                <td className={styles.labHours}>
                  02:00 PM
                  <br />
                  02:45 PM
                </td>
                <td className={styles.labHours}>
                  02:46 PM
                  <br />
                  03:30 PM
                </td>
                <td className={styles.labHours}>
                  04:00 PM
                  <br />
                  04:45 PM
                </td>
                <td className={styles.labHours}>
                  04:46 PM
                  <br />
                  05:30 PM
                </td>
                <td className={styles.labHours}>
                  05:31 PM
                  <br />
                  06:15 PM
                </td>
                <td className={styles.labHours}>
                  06:16 PM
                  <br />
                  07:00 PM
                </td>
              </tr>
              <tr className={styles.dataRow}>
                <td className={styles.firstColumn}>MON</td>

                {monday?.map((x) => (
                  <Cell
                    slot={x.slot}
                    currentSlot={currentSlot}
                    selected={x.selected}
                    day={monday}
                    setClashed={setClashed}
                  />
                ))}
              </tr>
              <tr className={styles.dataRow}>
                <td className={styles.firstColumn}>TUE</td>

                {tuesday?.map((x) => (
                  <Cell
                    slot={x.slot}
                    currentSlot={currentSlot}
                    selected={x.selected}
                    day={tuesday}
                    setClashed={setClashed}
                  />
                ))}
              </tr>
              <tr className={styles.dataRow}>
                <td className={styles.firstColumn}>WED</td>

                {wednesday?.map((x) => (
                  <Cell
                    slot={x.slot}
                    currentSlot={currentSlot}
                    selected={x.selected}
                    day={wednesday}
                    setClashed={setClashed}
                  />
                ))}
              </tr>
              <tr className={styles.dataRow}>
                <td className={styles.firstColumn}>THU</td>

                {thursday?.map((x) => (
                  <Cell
                    slot={x.slot}
                    currentSlot={currentSlot}
                    selected={x.selected}
                    day={thursday}
                    setClashed={setClashed}
                  />
                ))}
              </tr>
              <tr className={styles.dataRow}>
                <td className={styles.firstColumn}>FRI</td>

                {friday?.map((x) => (
                  <Cell
                    slot={x.slot}
                    currentSlot={currentSlot}
                    selected={x.selected}
                    day={friday}
                    setClashed={setClashed}
                  />
                ))}
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className={styles.buttonDiv}>
        <Tooltip title="Add courses with custom fields">
          <Button
            variant="outlined"
            color="secondary"
            className={classes.ToggleOtherFieldsBtn}
            onClick={toggleLeftDrawer(true)}
          >
            Customized Course Addition
          </Button>
        </Tooltip>

        <Drawer
          anchor={"left"}
          open={leftState}
          onClose={toggleLeftDrawer(false)}
          className={styles.leftDrawer}
        >
          <div
            style={{
              display: "flex",
              // alignItems: "center",
              justifyContent: "left",
              overflowX: "hidden",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              // paddingLeft: "20px",
              // paddingRight: "20px",
            }}
          >
            <h2
              style={{
                marginTop: "2.5rem",
                marginBottom: "2.5rem",
                textAlign: "center",
                width: "100%",
              }}
            >
              Add Custom Faculty and Slots
            </h2>
            <div className={classes.customCourseDrawerDiv}>
              <ul
                style={{
                  listStyleType: "none",
                  marginLeft: "-4px",
                }}
              >
                <li style={{ marginBottom: "4px" }}>
                  ⌨ &nbsp; Enter course code you want to add
                </li>
                <li style={{ marginBottom: "4px" }}>
                  👩‍💻 &nbsp; Enter your slot, faculty, venue, credits
                </li>
                <li>💥&nbsp; Add this custom course!</li>
              </ul>

              <div style={{ marginLeft: "30px", marginTop: "30px" }}>
                <Tooltip title="ex. CSE1001" placement="top" arrow>
                  <input
                    className={styles.customCourseInput}
                    // list={list}
                    value={customCode}
                    onChange={(e) =>
                      setCustomCode(e.target.value.toUpperCase())
                    }
                    placeholder="Enter course code"
                    required
                  />
                </Tooltip>
                <Tooltip
                  title="ex. Data Structure and Algorithms"
                  placement="top"
                  arrow
                >
                  <input
                    className={styles.customCourseInput}
                    // list={list}
                    value={customCourse}
                    onChange={(e) => setCustomCourse(e.target.value)}
                    placeholder="Enter course name"
                    required
                  />
                </Tooltip>
                <Tooltip title="ex. R Kumar" placement="top" arrow>
                  <input
                    className={styles.customCourseInput}
                    // list={list}
                    value={customFaculty}
                    onChange={(e) => setCustomFaculty(e.target.value)}
                    placeholder="Enter Faculty Name"
                    required
                  />
                </Tooltip>
                <Tooltip title="ex. A1+TA1+TAA1" placement="top" arrow>
                  <input
                    className={styles.customCourseInput}
                    // list={list}
                    value={customSlot}
                    onChange={(e) =>
                      setCustomSlot(e.target.value.toUpperCase())
                    }
                    placeholder="Enter Slot in + format"
                    required
                  />
                </Tooltip>
                <Tooltip title="ex. 4" placement="top" arrow>
                  <input
                    className={styles.customCourseInput}
                    // list={list}
                    value={customCredits}
                    onChange={(e) => setCustomCredits(e.target.value)}
                    placeholder="Enter Credits for this course"
                    required
                  />
                </Tooltip>
                <Tooltip title="ex. SJT001" placement="top" arrow>
                  <input
                    className={styles.customCourseInput}
                    // list={list}
                    value={customVenue}
                    onChange={(e) => setCustomVenue(e.target.value)}
                    placeholder="Enter Venue (optional)"
                    // required
                  />
                </Tooltip>
              </div>
              <br />

              <Button
                variant="contained"
                startIcon={<ControlPointIcon />}
                className={classes.addCustomCourseBtn}
                onClick={addCustom}
                style={{
                  float: "right",
                  marginRight: "1rem",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                Add Course
              </Button>
              {/* <div
                onClick={addCustom}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "200px",
                  float: "right",
                  cursor: "pointer",
                }}
              >
                <h3>Add This Course</h3>
                <IconButton style={{ float: "right" }}>
                  <AddCircleIcon style={{ color: "#000000" }} />
                </IconButton>
              </div> */}
            </div>
          </div>
        </Drawer>

        {/* for auto slot button and top drawer */}
        <Tooltip title="Auto Generate Timetables" placement="bottom" arrow>
          <Button
            className={classes.autoSlotPickerBtn}
            onClick={toggleAutoDrawer(true)}
          >
            Auto Slot Picker
          </Button>
        </Tooltip>

        <Drawer
          anchor={"bottom"}
          open={autoDrawerState}
          onClose={toggleAutoDrawer(false)}
          className={styles.autoDrawer}
        >
          {dataSent == true ? (
            <Box p="5" className={styles.waysBox}>
              <div className={styles.waysTitle}>Timetable {waysPage}</div>
              <div>
                These are the possible ways you could make your timetable
              </div>
              <WayTable data={possibleWays[waysPage - 1]} />
              <Pagination
                count={possibleWays.length}
                size="large"
                page={waysPage}
                // variant="outlined"
                color="primary"
                shape="circular"
                onChange={handleWayChange}
              />
              <p className={styles.warningText}>
                *Might need to refresh to work properly
              </p>
              <Button
                className={styles.changeCoursesBtn}
                onClick={() => setDataSent(false)}
              >
                Change courses
              </Button>
              <a
                href="https://forms.gle/CqVS8yoEFmuJjqVw9"
                target="_blank"
                rel="noreferrer"
              >
                <Button className={styles.feedbackBtn}>Feedback</Button>
              </a>
            </Box>
          ) : (
            <AutoSlotForm
              // setMorningTheory={setMorningTheory}
              // morningTheory={morningTheory}
              list={inputDropDownData}
              ttDatabase={list}
              receivedData={setpossibleWays}
              setDataState={setDataState}
              dataSentVar={dataSent}
              onToastMessageReceive={onToastMessageReceive}
            />
          )}
        </Drawer>
        {}
        <Tooltip title="See and give own reviews of all faculties">
          <a
            href="https://faculty.vitrendz.com/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button color="primary" className={classes.QuickVisualiseBtn}>
              Faculty Reviews
            </Button>
          </a>
        </Tooltip>
      </div>
      <br />
      <hr />
      <br />
      <CourseTable
        currentSlot={currentSlot}
        setCurrentSlot={setCurrentSlot}
        credits={credits}
        setCredits={setCredits}
      />
      
      {/* this below popup is for the auto slot picker */}
      {/* <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Auto Timetable Generator"
      >
        <AutoSlotForm
          // setMorningTheory={setMorningTheory}
          // morningTheory={morningTheory}
          list={inputDropDownData}
          ttDatabase={list}
        />
      </Popup> */}
      {courseAdded | clashed | alreadyAdded ? (
        <Toast type={courseAdded ? "green" : alreadyAdded ? "yellow" : "red"}>
          <h1 style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            {courseAdded
              ? "Course added successfully!"
              : clashed
              ? `Selected course slots have clashed with ${clashedWith.slot} (${clashedWith.cname})`
              : "You have already added this course!"}
          </h1>
        </Toast>
      ) : null}
      {showToast ? (
        <Toast type={"red"}>
          <h1 style={{ paddingTop: "5px", paddingBottom: "5px" }}>
            {toastMessage}
          </h1>
        </Toast>
      ) : null}

      {/* {suggestionsToast ? <SuggestionsRanks suggestions={suggestions} /> : null} */}
    </>
  );
}

export default TimeTable;
